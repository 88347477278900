import {getBanners} from "@/api";

export default {
  namespaced: true,
  state: {
    banners: []
  },
  actions: {
    getBanners({ commit }) {
      getBanners().then(response => commit('SET_BANNERS', response.data))
    }
  },
  mutations: {
    SET_BANNERS(state, banners) {
      state.banners = banners
    }
  }
}