<template>
  <div class="my-referrals">
    <div class="my-referrals__title">Мои реферралы</div>

    <Preloader v-if="balanceHistory?.length === 0"/>
    <div class="my-referrals__content my-referrals__content_mt-12" v-if="balanceHistory?.length">
      <Referral
          v-for="(referral, idx) in balanceHistory"
          :key="idx"
          :me="referral.splice(0, 1)[0]"
          :referral="referral.splice(0)"
      />
    </div>
    <NoData
        class="my-referrals__icon"
        name="У вас все еще нету ни одного реферрала"
        v-if="balanceHistory === null"
    />
  </div>
</template>

<script>
import Referral from "@/components/Views/Partners/Referral.vue";
import NoData from "@/components/Operations/NoData.vue";
import {mapActions, mapState} from "vuex";
import Preloader from "@/components/Utils/Preloader.vue";

export default {
  name: "MyReferrals",
  components: {
    Preloader,
    NoData,
    Referral
  },
  methods: {
    ...mapActions('balance', ['getBalanceHistory']),
  },
  computed: {
    ...mapState('balance', ['balanceHistory'])
  },
  mounted() {
    this.getBalanceHistory()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/Views/partners/myReferrals";
</style>