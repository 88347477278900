<template>
  <div class="income-information">
    <div class="income-information__content">
      <div class="income-information__item purchases">
        <div class="income-information__item-title">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M1.66602 7.08333H12.0827" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 13.75H6.66667" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.75 13.75H12.0833" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.3327 11.6917V13.425C18.3327 16.35 17.591 17.0833 14.6327 17.0833H5.36602C2.40768 17.0833 1.66602 16.35 1.66602 13.425V6.57501C1.66602 3.65001 2.40768 2.91667 5.36602 2.91667H12.0827" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
            <g opacity="0.4">
              <path d="M16.666 2.91667V7.91667L18.3327 6.25001" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.6667 7.91667L15 6.25" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
          Мои покупки
        </div>
        <div class="income-information__item-value income-information__item-value_mt-10">
          {{ getPrice(statistics.purchase?.total, 0) }} ₽
        </div>
        <div class="income-information__item-info income-information__item-info_mt-4">
          <div class="income-information__item-info-value">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                :style="statistics.income?.difference_in_percent < 0 ? 'transform: rotateZ(-180deg);' : ''"
            >
              <path d="M2.77139 0.837362C2.65077 0.957983 2.57688 1.1256 2.57712 1.30969L2.57712 1.39071C2.57712 1.75935 2.87555 2.05778 3.24371 2.05731L6.88605 2.05731L0.724645 8.21871C0.464429 8.47893 0.464429 8.9013 0.724645 9.16152C0.98486 9.42173 1.40724 9.42173 1.66745 9.16152L7.82886 3.00011V6.64245C7.82886 7.01109 8.12729 7.30952 8.49545 7.30905L8.57648 7.30905C8.94511 7.30905 9.24354 7.01062 9.24307 6.64245L9.24307 1.30969C9.24307 0.94105 8.94464 0.642621 8.57648 0.643092L3.24371 0.643092C3.05939 0.643092 2.89201 0.716742 2.77139 0.837362Z" fill="#4F9F2A"/>
            </svg>
            <template v-if="statistics.purchase?.difference_in_percent > 0">+</template>{{ statistics.purchase?.difference_in_percent }}%
          </div>
          {{ statistics.purchase?.difference_in_amount }} vs {{ getFormattedDate(statistics.from_date) }}
        </div>

      </div>
      <div class="income-information__item partnership-turnover">
        <div class="income-information__item-title">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14.534 12.65C14.609 13.55 15.434 14.2083 16.334 14.2083H17.9173V15.2C17.9173 16.925 16.509 18.3333 14.784 18.3333H6.35899C6.61732 18.1167 6.84232 17.85 7.01732 17.55C7.32565 17.05 7.50065 16.4583 7.50065 15.8333C7.50065 13.9917 6.00898 12.5 4.16732 12.5C3.38398 12.5 2.65898 12.775 2.08398 13.2333V9.59167C2.08398 7.86667 3.49231 6.45833 5.21731 6.45833H14.784C16.509 6.45833 17.9173 7.86667 17.9173 9.59167V10.7917H16.234C15.7673 10.7917 15.3423 10.975 15.034 11.2917C14.684 11.6333 14.484 12.125 14.534 12.65Z" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
            <path opacity="0.4" d="M2.08398 10.3415V6.53322C2.08398 5.54155 2.69231 4.65819 3.61731 4.30819L10.234 1.80819C11.2673 1.41652 12.3757 2.18321 12.3757 3.29154V6.4582" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.8016 13.3585C18.8016 13.8168 18.4349 14.1918 17.9683 14.2085H16.3349C15.435 14.2085 14.6099 13.5501 14.5349 12.6501C14.4849 12.1251 14.6849 11.6335 15.0349 11.2918C15.3433 10.9751 15.7683 10.7918 16.2349 10.7918H17.9683C18.4349 10.8085 18.8016 11.1835 18.8016 11.6418V13.3585Z" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
            <path opacity="0.4" d="M5.83398 10H11.6673" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.01732 17.55C6.84232 17.85 6.61732 18.1167 6.35899 18.3333C5.77566 18.8583 5.00898 19.1667 4.16732 19.1667C2.95065 19.1667 1.89232 18.5167 1.31732 17.55C1.00899 17.05 0.833984 16.4583 0.833984 15.8333C0.833984 14.7833 1.31732 13.8417 2.08398 13.2333C2.65898 12.775 3.38398 12.5 4.16732 12.5C6.00898 12.5 7.50065 13.9917 7.50065 15.8333C7.50065 16.4583 7.32565 17.05 7.01732 17.55Z" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.86719 15.8333L3.69218 16.6583L5.46718 15.0167" stroke="#262626" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Партнерский оборот
        </div>
        <div class="income-information__item-value income-information__item-value_mt-10">
          {{ getPrice(statistics.income?.total, 0) }} ₽
        </div>
        <div class="income-information__item-info income-information__item-info_mt-4">
          <div class="income-information__item-info-value">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                :style="statistics.income?.difference_in_percent > 0 ? 'transform: rotateZ(-180deg);' : ''"
            >
              <path d="M7.22861 9.16264C7.34923 9.04202 7.42312 8.8744 7.42288 8.69031L7.42288 8.60929C7.42288 8.24065 7.12445 7.94222 6.75629 7.94269L3.11395 7.94269L9.27536 1.78129C9.53557 1.52107 9.53557 1.0987 9.27536 0.838481C9.01514 0.578265 8.59276 0.578265 8.33255 0.838481L2.17114 6.99988L2.17114 3.35755C2.17114 2.98891 1.87271 2.69048 1.50455 2.69095L1.42352 2.69095C1.05489 2.69095 0.756458 2.98938 0.756929 3.35755L0.756928 8.69031C0.756929 9.05895 1.05536 9.35738 1.42352 9.35691L6.75629 9.35691C6.94061 9.35691 7.10799 9.28326 7.22861 9.16264Z" fill="#FF6100"/>
            </svg>
            <template v-if="statistics.income?.difference_in_percent > 0">+</template>{{ statistics.income?.difference_in_percent }}%
          </div>
          {{ statistics.income?.difference_in_amount }} vs {{ getFormattedDate(statistics.from_date) }}
        </div>
      </div>
      <div class="income-information__item count-of-partners">
        <div class="income-information__item-title">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.5C10 5.433 8.433 8 6.5 8C4.567 8 3 5.433 3 3.5C3 1.567 4.567 0 6.5 0C8.433 0 10 1.567 10 3.5ZM8 3.5C8 3.98965 7.77591 4.71476 7.34949 5.30948C6.90082 5.93525 6.55228 6 6.5 6C6.44772 6 6.09918 5.93525 5.65051 5.30948C5.22409 4.71476 5 3.98965 5 3.5C5 2.67157 5.67157 2 6.5 2C7.32843 2 8 2.67157 8 3.5Z" fill="#4B535A"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 12.5C13 14.433 10.0899 16 6.5 16C2.91015 16 0 14.433 0 12.5C0 10.567 2.91015 9 6.5 9C10.0899 9 13 10.567 13 12.5ZM10.148 11.7861C10.8061 12.1405 10.9582 12.4182 10.9918 12.5C10.9582 12.5818 10.8061 12.8595 10.148 13.2139C9.31638 13.6617 8.0302 14 6.5 14C4.9698 14 3.68362 13.6617 2.85201 13.2139C2.19386 12.8595 2.04184 12.5818 2.00825 12.5C2.04184 12.4182 2.19386 12.1405 2.85201 11.7861C3.68362 11.3383 4.9698 11 6.5 11C8.0302 11 9.31638 11.3383 10.148 11.7861Z" fill="#4B535A"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 8C15.3807 8 16.5 5.88071 16.5 4.5C16.5 3.11929 15.3807 2 14 2C12.6193 2 11.5 3.11929 11.5 4.5C11.5 5.88071 12.6193 8 14 8ZM14.5067 5.95554C14.8331 5.4488 15 4.85155 15 4.5C15 3.94772 14.5523 3.5 14 3.5C13.4477 3.5 13 3.94772 13 4.5C13 4.85155 13.1669 5.4488 13.4933 5.95554C13.7499 6.35401 13.9411 6.46122 14 6.48982C14.0589 6.46122 14.2501 6.35401 14.5067 5.95554Z" fill="#4B535A"/>
            <path d="M14 9C13.5858 9 13.25 9.33579 13.25 9.75C13.25 10.1642 13.5858 10.5 14 10.5C15.0763 10.5 16.0134 10.7326 16.6543 11.0719C17.3287 11.4289 17.5 11.7934 17.5 12C17.5 12.1845 17.369 12.493 16.8408 12.8212C16.3307 13.1383 15.5678 13.3882 14.6527 13.4709C14.2402 13.5081 13.936 13.8728 13.9733 14.2853C14.0105 14.6979 14.3752 15.0021 14.7877 14.9648C15.8768 14.8664 16.8788 14.5637 17.6326 14.0953C18.3682 13.638 19 12.9281 19 12C19 10.964 18.2199 10.2035 17.3561 9.74617C16.4588 9.27112 15.271 9 14 9Z" fill="#4B535A"/>
          </svg>
          Количество партнеров
        </div>
        <div class="income-information__item-value income-information__item-value_mt-10">
          {{ statistics.partners_count }}
        </div>
        <div class="income-information__item-info income-information__item-info_mt-4">
          <div class="income-information__item-info-value">{{ getPrice(statistics.income?.total, 0) }} ₽</div>
          Партнерский доход
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "IncomeInformation",
  methods: {
    ...mapActions('balance', ['getStatistics']),
  },
  computed: {
    ...mapState('balance', ['statistics']),
  },
  mounted() {
    this.getStatistics()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/Views/cabinet/_incomeInformation.scss";
</style>