import * as API from '@/api';
import {getOperations, getTokenForGoogleAndFacebookAuth, updateAvatar} from "@/api";
import router from "@/router";

const TOKEN_KEY = 'token'

export default {
  namespaced: true,
  state() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      token: localStorage.getItem('token'),
      operations: [],
      shortOperations: [],
      showTwoFactorAuthModal: false,
    }
  },
  actions: {
    register({commit}, user) {
      return API.register(user).then(response => {
        commit('SET_USER', response.data.user)
        commit('SET_TOKEN', response.data.token)
        return response
      })
    },
    login({commit}, user) {
      return API.login(user).then(response => {
        commit('SET_USER', response.data.user)
        commit('SET_TOKEN', response.data.token)
        return response
      })
    },
    logout({commit}) {
      API.logout()
      commit('SET_USER', null)
      commit('SET_TOKEN', null)
    },
    sendEmail({commit}, email) {
      API.sendEmail(email)
    },
    changeUserData({commit}, user) {
      API.changeUserData(user).then(response => {
        commit('CHANGE_USER_DATA', user)
      })
    },
    updatePassword({commit}, password) {
      API.updatePassword(password)
    },
    getTokenForGoogleAndFacebookAuth({commit}, token) {
      API.getTokenForGoogleAndFacebookAuth(token).then(response => {
        commit('SET_USER', response.data.user)
        commit('SET_TOKEN', response.data.token)
        if (response.status === 401) router.push('/')
      })
    },
    updateAvatar({ commit }, avatar) {
      API.updateAvatar(avatar).then(response => commit('CHANGE_USER_DATA', response.data))
    },
    getOperations({ commit }, filter = { limit: -1, q: '', status: '' }) {
      API.getOperations(filter).then(response => {
        commit('SET_OPERATIONS', response.data?.length ? response.data : null)
      })
    },
    getShortOperations({ commit }, filter = { limit: 4, q: '', status: '' }) {
      API.getOperations(filter).then(response => {
          commit('SET_SHORT_OPERATIONS', response.data?.length ? response.data : null)
      })
    }
  },
  mutations: {
    SET_USER(state, user) {
      if (user) {
        state.user = user
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        state.user = null
        localStorage.removeItem('user')
      }
    },
    CHANGE_USER_DATA(state, user) {
      const newUser = state.user = {...state.user, ...user}
      localStorage.setItem('user', JSON.stringify(newUser))
      state.user = newUser
    },
    SET_TOKEN(state, token) {
      if (token) {
        state.token = token
        localStorage.setItem('token', token)
      } else {
        state.token = null
        localStorage.removeItem('token')
      }
    },
    SET_IS2FA(state, boolean) {
      state.user.is_2fa = boolean
    },
    SET_OPERATIONS(state, operations) {
      state.operations = operations
    },
    SET_SHORT_OPERATIONS(state, operations) {
        state.shortOperations = operations
    },
    SET_SHOW_TWO_FACTOR_AUTH_MODAL(state, boolean) {
      state.showTwoFactorAuthModal = boolean
    }
  },
  getters: {
    token(state) {
      return state.token
    },
    isAuthenticated(_, getters) {
      return !!getters.token
    },
  }
}