<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      class="social-media-icon only-stroke"
      @click="windowOpen('https://api.whatsapp.com/send/?phone=79196260079&text&type=phone_number&app_absent=0')"
  >
    <path d="M1.5 16.5L2.875 13.3333C1.82305 11.84 1.35222 10.0142 1.55087 8.1984C1.74952 6.38262 2.60399 4.70176 3.95392 3.47124C5.30386 2.24073 7.05646 1.54515 8.88282 1.51506C10.7092 1.48496 12.4837 2.12241 13.8735 3.30778C15.2632 4.49316 16.1726 6.14496 16.431 7.9532C16.6893 9.76144 16.2789 11.6018 15.2768 13.1289C14.2746 14.6561 12.7495 15.765 10.9878 16.2476C9.22612 16.7301 7.34885 16.5532 5.70833 15.75L1.5 16.5Z" stroke="#FF6100" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.5 7.33331C6.5 7.44382 6.5439 7.5498 6.62204 7.62794C6.70018 7.70608 6.80616 7.74998 6.91667 7.74998C7.02717 7.74998 7.13315 7.70608 7.21129 7.62794C7.28943 7.5498 7.33333 7.44382 7.33333 7.33331V6.49998C7.33333 6.38947 7.28943 6.28349 7.21129 6.20535C7.13315 6.12721 7.02717 6.08331 6.91667 6.08331C6.80616 6.08331 6.70018 6.12721 6.62204 6.20535C6.5439 6.28349 6.5 6.38947 6.5 6.49998V7.33331ZM6.5 7.33331C6.5 8.43838 6.93899 9.49819 7.72039 10.2796C8.50179 11.061 9.5616 11.5 10.6667 11.5M10.6667 11.5H11.5C11.6105 11.5 11.7165 11.4561 11.7946 11.3779C11.8728 11.2998 11.9167 11.1938 11.9167 11.0833C11.9167 10.9728 11.8728 10.8668 11.7946 10.7887C11.7165 10.7105 11.6105 10.6666 11.5 10.6666H10.6667C10.5562 10.6666 10.4502 10.7105 10.372 10.7887C10.2939 10.8668 10.25 10.9728 10.25 11.0833C10.25 11.1938 10.2939 11.2998 10.372 11.3779C10.4502 11.4561 10.5562 11.5 10.6667 11.5Z" stroke="#FF6100" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "WhatsAppIcon"
}
</script>

<style scoped>

</style>