<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="113" height="62" viewBox="0 0 113 62" fill="none">
    <path d="M69.972 13.0775C70.3689 13.0775 70.6876 13.2195 70.9282 13.5035C71.1807 13.7746 71.307 14.1296 71.307 14.5685V25.4315C71.307 25.8834 71.1807 26.2513 70.9282 26.5353C70.6876 26.8064 70.3689 26.9419 69.972 26.9419C69.539 26.9419 69.1902 26.7741 68.9256 26.4384L62.8816 18.1314V25.4315C62.8816 25.8834 62.7614 26.2513 62.5208 26.5353C62.2923 26.8064 61.9796 26.9419 61.5827 26.9419C61.1857 26.9419 60.867 26.8064 60.6265 26.5353C60.3859 26.2513 60.2656 25.8834 60.2656 25.4315V14.5685C60.2656 14.1296 60.3859 13.7746 60.6265 13.5035C60.879 13.2195 61.2038 13.0775 61.6007 13.0775C62.0457 13.0775 62.3885 13.2453 62.6291 13.5809L68.673 21.8299V14.5685C68.673 14.1296 68.7933 13.7746 69.0338 13.5035C69.2744 13.2195 69.5871 13.0775 69.972 13.0775Z" fill="#24C8BC"/>
    <path d="M85.6853 24.9862C85.7815 25.2185 85.8296 25.4251 85.8296 25.6058C85.8296 25.9673 85.6913 26.2771 85.4147 26.5353C85.15 26.7805 84.8433 26.9032 84.4945 26.9032C84.266 26.9032 84.0495 26.8386 83.845 26.7095C83.6406 26.5675 83.4842 26.361 83.376 26.0899L82.4198 23.805H76.3037L75.3475 26.0899C75.2392 26.361 75.0829 26.5675 74.8784 26.7095C74.686 26.8386 74.4695 26.9032 74.2289 26.9032C73.8801 26.9032 73.5674 26.7805 73.2908 26.5353C73.0141 26.2771 72.8758 25.9673 72.8758 25.6058C72.8758 25.4251 72.9239 25.2185 73.0201 24.9862L77.8913 14.0069C78.0236 13.6971 78.2221 13.4647 78.4867 13.3098C78.7513 13.142 79.034 13.0581 79.3347 13.0581C79.6474 13.0581 79.936 13.142 80.2007 13.3098C80.4653 13.4776 80.6697 13.71 80.8141 14.0069L85.6853 24.9862ZM77.2599 21.5201H81.4636L79.3707 16.4855L77.2599 21.5201Z" fill="#24C8BC"/>
    <path d="M97.0871 13.0775C97.484 13.0775 97.8027 13.2195 98.0433 13.5035C98.2959 13.7746 98.4222 14.1296 98.4222 14.5685V25.4315C98.4222 25.8834 98.2959 26.2513 98.0433 26.5353C97.8027 26.8064 97.484 26.9419 97.0871 26.9419C96.6541 26.9419 96.3053 26.7741 96.0407 26.4384L89.9968 18.1314V25.4315C89.9968 25.8834 89.8765 26.2513 89.636 26.5353C89.4074 26.8064 89.0947 26.9419 88.6978 26.9419C88.3009 26.9419 87.9821 26.8064 87.7416 26.5353C87.501 26.2513 87.3808 25.8834 87.3808 25.4315V14.5685C87.3808 14.1296 87.501 13.7746 87.7416 13.5035C87.9942 13.2195 88.3189 13.0775 88.7158 13.0775C89.1609 13.0775 89.5036 13.2453 89.7442 13.5809L95.7881 21.8299V14.5685C95.7881 14.1296 95.9084 13.7746 96.1489 13.5035C96.3895 13.2195 96.7022 13.0775 97.0871 13.0775Z" fill="#24C8BC"/>
    <path d="M106.793 27C105.53 27 104.429 26.716 103.491 26.148C102.565 25.5671 101.849 24.7538 101.344 23.7082C100.839 22.6496 100.586 21.4103 100.586 19.9903C100.586 18.5703 100.839 17.3375 101.344 16.2918C101.849 15.2333 102.565 14.42 103.491 13.852C104.429 13.284 105.53 13 106.793 13C108.055 13 109.15 13.284 110.076 13.852C111.014 14.42 111.736 15.2333 112.241 16.2918C112.746 17.3375 112.999 18.5703 112.999 19.9903C112.999 21.4103 112.746 22.6496 112.241 23.7082C111.736 24.7538 111.014 25.5671 110.076 26.148C109.15 26.716 108.055 27 106.793 27ZM106.793 24.657C107.851 24.657 108.681 24.2568 109.282 23.4564C109.896 22.6561 110.202 21.5007 110.202 19.9903C110.202 18.4799 109.902 17.331 109.3 16.5436C108.699 15.7432 107.863 15.343 106.793 15.343C105.722 15.343 104.886 15.7432 104.285 16.5436C103.695 17.331 103.401 18.4799 103.401 19.9903C103.401 21.5007 103.701 22.6561 104.303 23.4564C104.904 24.2568 105.734 24.657 106.793 24.657Z" fill="#24C8BC"/>
    <path d="M67.6125 37.2752C68.272 37.491 68.7802 37.8686 69.137 38.408C69.5046 38.9355 69.6884 39.5887 69.6884 40.3679C69.6884 41.4348 69.3316 42.2799 68.618 42.9032C67.9152 43.5265 66.9421 43.8382 65.6987 43.8382H61.4496C61.0711 43.8382 60.7792 43.7243 60.5738 43.4965C60.3683 43.2688 60.2656 42.9451 60.2656 42.5256V32.4744C60.2656 32.0549 60.3683 31.7312 60.5738 31.5035C60.7792 31.2757 61.0711 31.1618 61.4496 31.1618H65.5366C66.7259 31.1618 67.6666 31.4555 68.3585 32.0429C69.0613 32.6302 69.4127 33.4334 69.4127 34.4523C69.4127 35.1116 69.2505 35.6929 68.9262 36.1964C68.6126 36.6879 68.1747 37.0475 67.6125 37.2752ZM62.7308 36.3223H65.1149C65.7636 36.3223 66.2393 36.2024 66.5421 35.9627C66.8556 35.7109 67.0124 35.3333 67.0124 34.8299C67.0124 34.3024 66.8556 33.9189 66.5421 33.6791C66.2393 33.4274 65.7636 33.3015 65.1149 33.3015H62.7308V36.3223ZM65.4068 41.6985C66.0772 41.6985 66.5637 41.5726 66.8665 41.3209C67.1692 41.0692 67.3206 40.6616 67.3206 40.0982C67.3206 39.5348 67.1692 39.1213 66.8665 38.8575C66.5637 38.5938 66.0772 38.462 65.4068 38.462H62.7308V41.6985H65.4068Z" fill="#24C8BC"/>
    <path d="M76.7021 44C75.5668 44 74.5775 43.7363 73.7341 43.2089C72.9016 42.6694 72.2583 41.9142 71.8042 40.9433C71.35 39.9603 71.123 38.8096 71.123 37.491C71.123 36.1724 71.35 35.0277 71.8042 34.0567C72.2583 33.0738 72.9016 32.3186 73.7341 31.7911C74.5775 31.2637 75.5668 31 76.7021 31C77.8373 31 78.8212 31.2637 79.6538 31.7911C80.4971 32.3186 81.1459 33.0738 81.6 34.0567C82.0541 35.0277 82.2811 36.1724 82.2811 37.491C82.2811 38.8096 82.0541 39.9603 81.6 40.9433C81.1459 41.9142 80.4971 42.6694 79.6538 43.2089C78.8212 43.7363 77.8373 44 76.7021 44ZM76.7021 41.8243C77.6535 41.8243 78.3996 41.4527 78.9402 40.7095C79.4916 39.9663 79.7673 38.8935 79.7673 37.491C79.7673 36.0885 79.497 35.0217 78.9564 34.2905C78.4158 33.5473 77.6643 33.1757 76.7021 33.1757C75.7398 33.1757 74.9883 33.5473 74.4477 34.2905C73.9179 35.0217 73.653 36.0885 73.653 37.491C73.653 38.8935 73.9233 39.9663 74.4639 40.7095C75.0046 41.4527 75.7506 41.8243 76.7021 41.8243Z" fill="#24C8BC"/>
    <path d="M92.9158 41.8243C93.1212 42.0881 93.2239 42.3638 93.2239 42.6515C93.2239 42.9991 93.0996 43.3047 92.8509 43.5685C92.613 43.8202 92.3373 43.9461 92.0238 43.9461C91.6994 43.9461 91.4237 43.7962 91.1966 43.4965L88.1476 39.361L85.0661 43.4965C84.8283 43.7962 84.5525 43.9461 84.239 43.9461C83.9362 43.9461 83.6605 43.8202 83.4119 43.5685C83.174 43.3047 83.0551 42.9991 83.0551 42.6515C83.0551 42.3518 83.1524 42.0761 83.347 41.8243L86.6717 37.4371L83.4605 33.1757C83.2551 32.9119 83.1524 32.6362 83.1524 32.3485C83.1524 32.0009 83.2713 31.7012 83.5092 31.4495C83.7578 31.1858 84.0336 31.0539 84.3363 31.0539C84.6607 31.0539 84.9418 31.2098 85.1796 31.5214L88.1476 35.4952L91.0831 31.5214C91.321 31.2098 91.6021 31.0539 91.9264 31.0539C92.2292 31.0539 92.4995 31.1858 92.7374 31.4495C92.986 31.7012 93.1104 32.0009 93.1104 32.3485C93.1104 32.6482 93.0131 32.9239 92.8184 33.1757L89.6072 37.4371L92.9158 41.8243Z" fill="#24C8BC"/>
    <path d="M101.833 40.4078C102.229 40.5113 102.534 40.7055 102.749 40.9903C102.97 41.2751 103.08 41.6311 103.08 42.0583C103.08 42.6602 102.882 43.123 102.486 43.4466C102.089 43.7638 101.527 43.9223 100.799 43.9223H98.343C98.2195 43.9223 98.1188 43.8867 98.0408 43.8155C97.9693 43.7443 97.9336 43.6505 97.9336 43.534V37.466C97.9336 37.3495 97.9693 37.2557 98.0408 37.1845C98.1188 37.1133 98.2195 37.0777 98.343 37.0777H100.692C101.4 37.0777 101.95 37.233 102.339 37.5437C102.729 37.8544 102.924 38.2945 102.924 38.8641C102.924 39.233 102.827 39.5502 102.632 39.8155C102.443 40.0809 102.177 40.2783 101.833 40.4078ZM98.7231 40.1165H100.575C101.089 40.1165 101.475 40.0129 101.735 39.8058C102.002 39.5987 102.135 39.2977 102.135 38.9029C102.135 38.1262 101.615 37.7379 100.575 37.7379H98.7231V40.1165ZM100.721 43.2621C101.261 43.2621 101.657 43.165 101.911 42.9709C102.171 42.7702 102.3 42.4595 102.3 42.0388C102.3 41.1974 101.774 40.7767 100.721 40.7767H98.7231V43.2621H100.721Z" fill="black"/>
    <path d="M104.901 43.9709C104.778 43.9709 104.68 43.9353 104.609 43.8641C104.537 43.7864 104.502 43.6861 104.502 43.5631V37.4369C104.502 37.3139 104.537 37.2168 104.609 37.1456C104.68 37.068 104.778 37.0291 104.901 37.0291C105.025 37.0291 105.122 37.068 105.194 37.1456C105.265 37.2168 105.301 37.3139 105.301 37.4369V43.5631C105.301 43.6861 105.265 43.7864 105.194 43.8641C105.122 43.9353 105.025 43.9709 104.901 43.9709Z" fill="black"/>
    <path d="M109.871 44C109.234 44 108.679 43.8576 108.204 43.5728C107.737 43.288 107.376 42.8835 107.122 42.3592C106.869 41.835 106.742 41.2168 106.742 40.5049C106.742 39.7864 106.866 39.165 107.113 38.6408C107.366 38.11 107.727 37.7055 108.195 37.4272C108.669 37.1424 109.228 37 109.871 37C110.515 37 111.07 37.1424 111.538 37.4272C112.012 37.7055 112.373 38.11 112.62 38.6408C112.874 39.165 113 39.7832 113 40.4951C113 41.2071 112.874 41.8285 112.62 42.3592C112.367 42.8835 112.003 43.288 111.528 43.5728C111.06 43.8576 110.508 44 109.871 44ZM109.871 43.3204C110.599 43.3204 111.164 43.0777 111.567 42.5922C111.977 42.1003 112.181 41.4013 112.181 40.4951C112.181 39.589 111.98 38.8932 111.577 38.4078C111.174 37.9223 110.606 37.6796 109.871 37.6796C109.143 37.6796 108.575 37.9256 108.165 38.4175C107.763 38.9029 107.561 39.5955 107.561 40.4951C107.561 41.3948 107.763 42.0906 108.165 42.5825C108.575 43.0744 109.143 43.3204 109.871 43.3204Z" fill="black"/>
    <path d="M0.196577 42.4873V14.2024L23.9831 0.217656L48.709 14.0791V42.1713L23.8562 55.7841L0.196577 42.4873Z" fill="white" stroke="#ADE3E3" stroke-width="0.377528"/>
    <circle cx="38.5537" cy="18.5916" r="0.975619" fill="url(#paint0_linear_201_4713)"/>
    <circle cx="34.3741" cy="16.6404" r="0.975619" fill="url(#paint1_linear_201_4713)"/>
    <circle cx="36.3233" cy="11.4835" r="0.975619" fill="url(#paint2_linear_201_4713)"/>
    <circle cx="41.9014" cy="13.7135" r="0.975619" fill="url(#paint3_linear_201_4713)"/>
    <circle cx="49.7061" cy="13.9923" r="0.975619" fill="url(#paint4_linear_201_4713)"/>
    <circle cx="29.0772" cy="14.271" r="0.975619" fill="url(#paint5_linear_201_4713)"/>
    <circle cx="26.7061" cy="12.3197" r="0.975619" fill="url(#paint6_linear_201_4713)"/>
    <circle cx="32.7022" cy="13.2954" r="0.975619" fill="url(#paint7_linear_201_4713)"/>
    <circle cx="42.5967" cy="10.926" r="0.975619" fill="url(#paint8_linear_201_4713)"/>
    <circle cx="47.0577" cy="17.1979" r="0.975619" fill="url(#paint9_linear_201_4713)"/>
    <circle cx="40.5069" cy="25.5603" r="0.975619" fill="url(#paint10_linear_201_4713)"/>
    <circle cx="44.8272" cy="24.5847" r="0.975619" fill="url(#paint11_linear_201_4713)"/>
    <circle cx="42.0381" cy="36.0134" r="0.975619" fill="url(#paint12_linear_201_4713)"/>
    <circle cx="39.5303" cy="32.8078" r="0.975619" fill="url(#paint13_linear_201_4713)"/>
    <circle cx="39.253" cy="36.0134" r="0.975619" fill="url(#paint14_linear_201_4713)"/>
    <circle cx="35.7686" cy="38.8009" r="0.975619" fill="url(#paint15_linear_201_4713)"/>
    <circle cx="37.4405" cy="37.2677" r="0.975619" fill="url(#paint16_linear_201_4713)"/>
    <circle cx="35.0694" cy="44.3759" r="0.975619" fill="url(#paint17_linear_201_4713)"/>
    <circle cx="39.8077" cy="42.564" r="0.975619" fill="url(#paint18_linear_201_4713)"/>
    <circle cx="34.9327" cy="41.3096" r="0.975619" fill="url(#paint19_linear_201_4713)"/>
    <circle cx="37.4405" cy="41.8671" r="0.975619" fill="url(#paint20_linear_201_4713)"/>
    <circle cx="35.3506" cy="48.8358" r="0.975619" fill="url(#paint21_linear_201_4713)"/>
    <circle cx="31.4483" cy="50.0902" r="0.975619" fill="url(#paint22_linear_201_4713)"/>
    <circle cx="32.8389" cy="42.7033" r="0.975619" fill="url(#paint23_linear_201_4713)"/>
    <circle cx="32.9795" cy="47.5815" r="0.975619" fill="url(#paint24_linear_201_4713)"/>
    <circle cx="30.4717" cy="46.3271" r="0.975619" fill="url(#paint25_linear_201_4713)"/>
    <circle cx="26.2881" cy="44.2365" r="0.975619" fill="url(#paint26_linear_201_4713)"/>
    <circle cx="27.6827" cy="46.4664" r="0.975619" fill="url(#paint27_linear_201_4713)"/>
    <circle cx="54.4444" cy="27.2328" r="0.975619" fill="url(#paint28_linear_201_4713)"/>
    <circle cx="34.3753" cy="8.9748" r="0.836245" fill="url(#paint29_linear_201_4713)"/>
    <circle cx="28.3792" cy="9.25354" r="0.836245" fill="url(#paint30_linear_201_4713)"/>
    <circle cx="37.5784" cy="14.271" r="0.836245" fill="url(#paint31_linear_201_4713)"/>
    <circle cx="31.1683" cy="16.3616" r="0.836245" fill="url(#paint32_linear_201_4713)"/>
    <circle cx="42.18" cy="21.1003" r="0.836245" fill="url(#paint33_linear_201_4713)"/>
    <circle cx="30.0511" cy="11.3441" r="0.836245" fill="url(#paint34_linear_201_4713)"/>
    <circle cx="46.5003" cy="22.3547" r="0.836245" fill="url(#paint35_linear_201_4713)"/>
    <circle cx="48.7294" cy="19.1491" r="0.557497" fill="url(#paint36_linear_201_4713)"/>
    <circle cx="51.4462" cy="20.3338" r="0.766558" fill="url(#paint37_linear_201_4713)"/>
    <circle cx="54.0947" cy="31.205" r="0.766558" fill="url(#paint38_linear_201_4713)"/>
    <circle cx="47.9619" cy="25.63" r="0.766558" fill="url(#paint39_linear_201_4713)"/>
    <circle cx="44.4775" cy="20.055" r="0.766558" fill="url(#paint40_linear_201_4713)"/>
    <circle cx="57.3712" cy="25.0028" r="0.418123" fill="url(#paint41_linear_201_4713)"/>
    <circle cx="57.508" cy="26.6753" r="0.418123" fill="url(#paint42_linear_201_4713)"/>
    <circle cx="58.3478" cy="29.7415" r="0.418123" fill="url(#paint43_linear_201_4713)"/>
    <circle cx="51.797" cy="27.3722" r="0.418123" fill="url(#paint44_linear_201_4713)"/>
    <circle cx="47.8947" cy="27.3722" r="0.418123" fill="url(#paint45_linear_201_4713)"/>
    <circle cx="46.7775" cy="32.1109" r="0.418123" fill="url(#paint46_linear_201_4713)"/>
    <circle cx="37.5783" cy="44.6546" r="0.418123" fill="url(#paint47_linear_201_4713)"/>
    <circle cx="38.6955" cy="48.9752" r="0.418123" fill="url(#paint48_linear_201_4713)"/>
    <circle cx="38.6955" cy="52.3202" r="0.418123" fill="url(#paint49_linear_201_4713)"/>
    <circle cx="26.0119" cy="48.8358" r="0.418123" fill="url(#paint50_linear_201_4713)"/>
    <circle cx="25.0353" cy="46.7452" r="0.418123" fill="url(#paint51_linear_201_4713)"/>
    <circle cx="40.6447" cy="51.2052" r="0.418123" fill="url(#paint52_linear_201_4713)"/>
    <circle cx="42.7345" cy="48.6964" r="0.418123" fill="url(#paint53_linear_201_4713)"/>
    <circle cx="27.6837" cy="49.5327" r="0.418123" fill="url(#paint54_linear_201_4713)"/>
    <circle cx="49.7033" cy="26.6753" r="0.418123" fill="url(#paint55_linear_201_4713)"/>
    <circle cx="50.2619" cy="25.1422" r="0.418123" fill="url(#paint56_linear_201_4713)"/>
    <circle cx="57.2306" cy="22.4941" r="0.418123" fill="url(#paint57_linear_201_4713)"/>
    <circle cx="57.2306" cy="16.501" r="0.418123" fill="url(#paint58_linear_201_4713)"/>
    <circle cx="59.0431" cy="17.7553" r="0.418123" fill="url(#paint59_linear_201_4713)"/>
    <circle cx="53.4689" cy="16.3616" r="0.418123" fill="url(#paint60_linear_201_4713)"/>
    <circle cx="51.9337" cy="16.9191" r="0.418123" fill="url(#paint61_linear_201_4713)"/>
    <circle cx="44.547" cy="15.9435" r="0.418123" fill="url(#paint62_linear_201_4713)"/>
    <circle cx="39.3908" cy="11.6229" r="0.418123" fill="url(#paint63_linear_201_4713)"/>
    <circle cx="39.1134" cy="9.25354" r="0.418123" fill="url(#paint64_linear_201_4713)"/>
    <circle cx="39.8087" cy="7.30228" r="0.418123" fill="url(#paint65_linear_201_4713)"/>
    <circle cx="42.0392" cy="8.13852" r="0.418123" fill="url(#paint66_linear_201_4713)"/>
    <circle cx="44.1291" cy="7.85977" r="0.418123" fill="url(#paint67_linear_201_4713)"/>
    <circle cx="45.5236" cy="8.97479" r="0.418123" fill="url(#paint68_linear_201_4713)"/>
    <circle cx="44.5497" cy="9.81104" r="0.557497" fill="url(#paint69_linear_201_4713)"/>
    <circle cx="46.5698" cy="14.6194" r="0.48781" fill="url(#paint70_linear_201_4713)"/>
    <circle cx="54.3036" cy="20.4035" r="0.557497" fill="url(#paint71_linear_201_4713)"/>
    <circle cx="42.4559" cy="18.3128" r="0.557497" fill="url(#paint72_linear_201_4713)"/>
    <circle cx="38.275" cy="21.1003" r="0.696871" fill="url(#paint73_linear_201_4713)"/>
    <circle cx="38.275" cy="23.7484" r="0.696871" fill="url(#paint74_linear_201_4713)"/>
    <circle cx="42.0406" cy="23.7484" r="0.696871" fill="url(#paint75_linear_201_4713)"/>
    <circle cx="43.5719" cy="28.4872" r="0.696871" fill="url(#paint76_linear_201_4713)"/>
    <circle cx="39.6695" cy="27.9297" r="0.696871" fill="url(#paint77_linear_201_4713)"/>
    <circle cx="37.9977" cy="27.9297" r="0.696871" fill="url(#paint78_linear_201_4713)"/>
    <circle cx="37.857" cy="25.5603" r="0.696871" fill="url(#paint79_linear_201_4713)"/>
    <circle cx="36.3258" cy="19.9854" r="0.696871" fill="url(#paint80_linear_201_4713)"/>
    <circle cx="41.7594" cy="27.7903" r="0.696871" fill="url(#paint81_linear_201_4713)"/>
    <circle cx="40.3661" cy="19.846" r="0.557497" fill="url(#paint82_linear_201_4713)"/>
    <circle cx="50.1214" cy="22.6335" r="0.836245" fill="url(#paint83_linear_201_4713)"/>
    <circle cx="48.7308" cy="30.9959" r="0.836245" fill="url(#paint84_linear_201_4713)"/>
    <circle cx="38.8323" cy="30.4384" r="0.836245" fill="url(#paint85_linear_201_4713)"/>
    <circle cx="46.2191" cy="28.069" r="0.836245" fill="url(#paint86_linear_201_4713)"/>
    <circle cx="41.6214" cy="31.5534" r="0.836245" fill="url(#paint87_linear_201_4713)"/>
    <circle cx="44.4066" cy="37.5465" r="0.836245" fill="url(#paint88_linear_201_4713)"/>
    <circle cx="42.8753" cy="40.0552" r="0.836245" fill="url(#paint89_linear_201_4713)"/>
    <circle cx="44.4066" cy="32.3897" r="0.836245" fill="url(#paint90_linear_201_4713)"/>
    <circle cx="38.8323" cy="39.7765" r="0.836245" fill="url(#paint91_linear_201_4713)"/>
    <circle cx="43.2933" cy="45.3514" r="0.836245" fill="url(#paint92_linear_201_4713)"/>
    <circle cx="38.7639" cy="46.3967" r="0.627184" fill="url(#paint93_linear_201_4713)"/>
    <circle cx="32.6311" cy="44.8636" r="0.627184" fill="url(#paint94_linear_201_4713)"/>
    <circle cx="30.678" cy="43.6092" r="0.627184" fill="url(#paint95_linear_201_4713)"/>
    <circle cx="28.7984" cy="44.2364" r="0.696871" fill="url(#paint96_linear_201_4713)"/>
    <circle cx="29.357" cy="48.4176" r="0.696871" fill="url(#paint97_linear_201_4713)"/>
    <circle cx="27.8219" cy="52.3202" r="0.696871" fill="url(#paint98_linear_201_4713)"/>
    <circle cx="29.2873" cy="50.4386" r="0.627184" fill="url(#paint99_linear_201_4713)"/>
    <circle cx="40.2269" cy="44.9333" r="0.836245" fill="url(#paint100_linear_201_4713)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.20032 25.0665C6.25751 25.0346 6.32712 25.0347 6.38426 25.0666L12.2359 28.3385C12.2954 28.3718 12.3323 28.4344 12.3326 28.5025C12.3329 28.5706 12.2964 28.6336 12.2373 28.6673L6.3856 32.0021C6.32965 32.034 6.26129 32.0352 6.20429 32.0052L0.100919 28.7962C0.039578 28.764 0.000842419 28.7007 1.3564e-05 28.6314C-0.000815291 28.5621 0.0363959 28.4979 0.0969479 28.4642L6.20032 25.0665ZM0.585363 28.6244L6.28848 31.623L11.7598 28.5049L6.29193 25.4476L0.585363 28.6244Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22783 31.6494C6.33208 31.6494 6.41659 31.7339 6.41659 31.8381V38.8362L30.5429 51.996C30.6345 52.046 30.6682 52.1606 30.6183 52.2521C30.5683 52.3437 30.4537 52.3774 30.3622 52.3275L6.13744 39.114C6.07679 39.0809 6.03906 39.0173 6.03906 38.9483V31.8381C6.03906 31.7339 6.12358 31.6494 6.22783 31.6494Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.4866 36.7706C39.5444 36.7378 39.6152 36.7379 39.673 36.7707L48.8595 41.9932C48.9501 42.0447 48.9818 42.1599 48.9303 42.2506C48.8788 42.3412 48.7635 42.3729 48.6729 42.3214L39.5795 37.1518L15.1334 51.0046C15.0427 51.056 14.9275 51.0242 14.8761 50.9335C14.8247 50.8428 14.8566 50.7276 14.9473 50.6762L39.4866 36.7706Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.5794 8.74603C39.6836 8.74603 39.7682 8.83055 39.7682 8.9348V36.9348C39.7682 37.0391 39.6836 37.1236 39.5794 37.1236C39.4751 37.1236 39.3906 37.0391 39.3906 36.9348V8.9348C39.3906 8.83055 39.4751 8.74603 39.5794 8.74603Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.45637 9.15955C8.50598 9.06786 8.62053 9.03375 8.71222 9.08336L21.7369 16.1305C21.8286 16.1802 21.8627 16.2947 21.8131 16.3864C21.7635 16.4781 21.649 16.5122 21.5573 16.4626L8.53257 9.4154C8.44088 9.36579 8.40676 9.25124 8.45637 9.15955Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0236024 14.1288C0.0740451 14.0376 0.188898 14.0045 0.280133 14.055L13.3678 21.2909C13.459 21.3414 13.4921 21.4562 13.4416 21.5475C13.3912 21.6387 13.2763 21.6718 13.1851 21.6213L0.0974642 14.3854C0.00622874 14.3349 -0.0268403 14.2201 0.0236024 14.1288Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.7452 8.90531C21.7963 8.99621 21.764 9.11128 21.6731 9.16232L6.41659 17.7285V25.2314C6.41659 25.3357 6.33208 25.4202 6.22783 25.4202C6.12358 25.4202 6.03906 25.3357 6.03906 25.2314V17.618C6.03906 17.5497 6.0759 17.4868 6.13541 17.4534L21.4882 8.83313C21.5791 8.78209 21.6942 8.81441 21.7452 8.90531Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.9462 5.06413C32.9989 5.15408 32.9687 5.26972 32.8787 5.32241L24.0698 10.482C24.0121 10.5157 23.9409 10.5165 23.8825 10.484L15.0735 5.57611C14.9824 5.52537 14.9497 5.41041 15.0005 5.31934C15.0512 5.22827 15.1662 5.19558 15.2572 5.24632L23.972 10.1017L32.6879 4.99665C32.7779 4.94396 32.8935 4.97417 32.9462 5.06413Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1667 5.22241C15.2709 5.2227 15.3552 5.30745 15.3549 5.4117L15.2291 50.8409C15.2288 50.9452 15.1441 51.0295 15.0398 51.0292C14.9356 51.0289 14.8513 50.9441 14.8516 50.8399L14.9774 5.41065C14.9777 5.3064 15.0624 5.22212 15.1667 5.22241Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9732 0.0628675C24.0775 0.0624828 24.1623 0.146683 24.1627 0.250934L24.2256 17.3026C24.226 17.4069 24.1418 17.4917 24.0375 17.4921C23.9333 17.4925 23.8485 17.4083 23.8481 17.304L23.7852 0.252327C23.7848 0.148076 23.869 0.0632522 23.9732 0.0628675Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.7818 5.09659C32.8861 5.0962 32.9709 5.1804 32.9713 5.28466L33.0342 22.3363C33.0346 22.4406 32.9504 22.5254 32.8461 22.5258C32.7419 22.5262 32.6571 22.442 32.6567 22.3377L32.5938 5.28605C32.5934 5.1818 32.6776 5.09697 32.7818 5.09659Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1936 29.4802C23.2449 29.571 23.2128 29.6861 23.1221 29.7374L0.28162 42.6363C0.190844 42.6875 0.0756973 42.6555 0.0244325 42.5647C-0.0268322 42.4739 0.00519808 42.3588 0.0959742 42.3075L22.9364 29.4086C23.0272 29.3574 23.1424 29.3894 23.1936 29.4802Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.4983 34.1363C31.5496 34.2271 31.5175 34.3423 31.4268 34.3935L8.58631 47.2924C8.49553 47.3437 8.38038 47.3117 8.32912 47.2209C8.27786 47.1301 8.30989 47.015 8.40066 46.9637L31.2411 34.0648C31.3319 34.0135 31.447 34.0456 31.4983 34.1363Z" fill="#ADE3E3"/>
    <g filter="url(#filter0_f_201_4713)">
      <path d="M27.9191 14.8494L13.7266 23.5368V40.1981L27.8446 48.3236L42.6704 39.2604V24.0623L27.9191 14.8494Z" fill="#24C8BC"/>
    </g>
    <g filter="url(#filter1_dd_201_4713)">
      <path d="M24.1859 14.9124L12.2148 22.1954V36.1634L24.1231 42.9753L36.6283 35.3772V22.636L24.1859 14.9124Z" fill="url(#paint101_linear_201_4713)"/>
    </g>
    <path d="M24.1699 28.6921V42.9753L12.2148 36.3056V22.2112L24.1699 28.6921Z" fill="url(#paint102_linear_201_4713)"/>
    <path d="M36.5643 22.463L36.6902 35.4248L24.043 42.9124V28.7551L36.5643 22.463Z" fill="url(#paint103_linear_201_4713)"/>
    <defs>
      <filter id="filter0_f_201_4713" x="0.63892" y="1.76175" width="55.1206" height="59.6494" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="6.54382" result="effect1_foregroundBlur_201_4713"/>
      </filter>
      <filter id="filter1_dd_201_4713" x="7.93619" y="13.2764" width="34.104" height="36.6202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1.88764" dy="2.51685"/>
        <feGaussianBlur stdDeviation="1.7618"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.631373 0 0 0 0 0.631373 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_201_4713"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2.6427"/>
        <feGaussianBlur stdDeviation="2.13933"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_201_4713" result="effect2_dropShadow_201_4713"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_201_4713" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_201_4713" x1="37.9962" y1="17.616" x2="39.1809" y2="19.3581" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint1_linear_201_4713" x1="33.8166" y1="15.6648" x2="35.0012" y2="17.4069" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint2_linear_201_4713" x1="35.7658" y1="10.5079" x2="36.9505" y2="12.2501" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint3_linear_201_4713" x1="41.3439" y1="12.7379" x2="42.5286" y2="14.4801" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint4_linear_201_4713" x1="49.1486" y1="13.0166" x2="50.3333" y2="14.7588" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint5_linear_201_4713" x1="28.5197" y1="13.2954" x2="29.7044" y2="15.0376" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint6_linear_201_4713" x1="26.1486" y1="11.3441" x2="27.3333" y2="13.0863" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint7_linear_201_4713" x1="32.1447" y1="12.3198" x2="33.3294" y2="14.0619" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint8_linear_201_4713" x1="42.0392" y1="9.95041" x2="43.2239" y2="11.6926" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint9_linear_201_4713" x1="46.5002" y1="16.2223" x2="47.6848" y2="17.9644" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint10_linear_201_4713" x1="39.9494" y1="24.5847" x2="41.1341" y2="26.3269" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint11_linear_201_4713" x1="44.2697" y1="23.6091" x2="45.4544" y2="25.3512" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint12_linear_201_4713" x1="41.4806" y1="35.0378" x2="42.6653" y2="36.7799" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint13_linear_201_4713" x1="38.9728" y1="31.8322" x2="40.1575" y2="33.5744" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint14_linear_201_4713" x1="38.6955" y1="35.0378" x2="39.8801" y2="36.7799" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint15_linear_201_4713" x1="35.2111" y1="37.8253" x2="36.3958" y2="39.5675" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint16_linear_201_4713" x1="36.883" y1="36.2921" x2="38.0676" y2="38.0343" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint17_linear_201_4713" x1="34.5119" y1="43.4002" x2="35.6966" y2="45.1424" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint18_linear_201_4713" x1="39.2502" y1="41.5883" x2="40.4348" y2="43.3305" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint19_linear_201_4713" x1="34.3752" y1="40.334" x2="35.5598" y2="42.0762" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint20_linear_201_4713" x1="36.883" y1="40.8915" x2="38.0676" y2="42.6337" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint21_linear_201_4713" x1="34.7931" y1="47.8601" x2="35.9778" y2="49.6023" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint22_linear_201_4713" x1="30.8908" y1="49.1145" x2="32.0755" y2="50.8567" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint23_linear_201_4713" x1="32.2814" y1="41.7277" x2="33.4661" y2="43.4699" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint24_linear_201_4713" x1="32.422" y1="46.6058" x2="33.6067" y2="48.348" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint25_linear_201_4713" x1="29.9142" y1="45.3514" x2="31.0989" y2="47.0936" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint26_linear_201_4713" x1="25.7306" y1="43.2609" x2="26.9153" y2="45.003" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint27_linear_201_4713" x1="27.1252" y1="45.4908" x2="28.3098" y2="47.233" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint28_linear_201_4713" x1="53.8869" y1="26.2572" x2="55.0716" y2="27.9994" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint29_linear_201_4713" x1="33.8975" y1="8.13855" x2="34.9129" y2="9.63185" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint30_linear_201_4713" x1="27.9014" y1="8.4173" x2="28.9168" y2="9.91059" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint31_linear_201_4713" x1="37.1006" y1="13.4348" x2="38.116" y2="14.928" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint32_linear_201_4713" x1="30.6904" y1="15.5253" x2="31.7059" y2="17.0186" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint33_linear_201_4713" x1="41.7021" y1="20.2641" x2="42.7176" y2="21.7574" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint34_linear_201_4713" x1="29.5732" y1="10.5079" x2="30.5887" y2="12.0012" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint35_linear_201_4713" x1="46.0225" y1="21.5184" x2="47.0379" y2="23.0117" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint36_linear_201_4713" x1="48.4108" y1="18.5916" x2="49.0878" y2="19.5871" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint37_linear_201_4713" x1="51.0082" y1="19.5672" x2="51.939" y2="20.9361" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint38_linear_201_4713" x1="53.6566" y1="30.4384" x2="54.5875" y2="31.8073" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint39_linear_201_4713" x1="47.5238" y1="24.8634" x2="48.4547" y2="26.2323" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint40_linear_201_4713" x1="44.0395" y1="19.2885" x2="44.9703" y2="20.6573" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint41_linear_201_4713" x1="57.1323" y1="24.5847" x2="57.64" y2="25.3313" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint42_linear_201_4713" x1="57.269" y1="26.2572" x2="57.7768" y2="27.0038" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint43_linear_201_4713" x1="58.1089" y1="29.3234" x2="58.6166" y2="30.0701" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint44_linear_201_4713" x1="51.5581" y1="26.954" x2="52.0658" y2="27.7007" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint45_linear_201_4713" x1="47.6558" y1="26.954" x2="48.1635" y2="27.7007" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint46_linear_201_4713" x1="46.5386" y1="31.6928" x2="47.0463" y2="32.4395" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint47_linear_201_4713" x1="37.3394" y1="44.2365" x2="37.8471" y2="44.9831" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint48_linear_201_4713" x1="38.4565" y1="48.5571" x2="38.9643" y2="49.3037" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint49_linear_201_4713" x1="38.4565" y1="51.902" x2="38.9643" y2="52.6487" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint50_linear_201_4713" x1="25.7729" y1="48.4177" x2="26.2807" y2="49.1644" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint51_linear_201_4713" x1="24.7964" y1="46.3271" x2="25.3041" y2="47.0737" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint52_linear_201_4713" x1="40.4058" y1="50.787" x2="40.9135" y2="51.5337" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint53_linear_201_4713" x1="42.4956" y1="48.2783" x2="43.0033" y2="49.0249" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint54_linear_201_4713" x1="27.4448" y1="49.1145" x2="27.9525" y2="49.8612" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint55_linear_201_4713" x1="49.4644" y1="26.2572" x2="49.9721" y2="27.0038" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint56_linear_201_4713" x1="50.0229" y1="24.7241" x2="50.5307" y2="25.4707" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint57_linear_201_4713" x1="56.9917" y1="22.0759" x2="57.4994" y2="22.8226" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint58_linear_201_4713" x1="56.9917" y1="16.0829" x2="57.4994" y2="16.8295" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint59_linear_201_4713" x1="58.8042" y1="17.3372" x2="59.3119" y2="18.0839" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint60_linear_201_4713" x1="53.23" y1="15.9435" x2="53.7377" y2="16.6901" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint61_linear_201_4713" x1="51.6948" y1="16.501" x2="52.2025" y2="17.2477" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint62_linear_201_4713" x1="44.3081" y1="15.5253" x2="44.8158" y2="16.272" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint63_linear_201_4713" x1="39.1519" y1="11.2047" x2="39.6596" y2="11.9514" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint64_linear_201_4713" x1="38.8745" y1="8.83542" x2="39.3822" y2="9.58207" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint65_linear_201_4713" x1="39.5698" y1="6.88416" x2="40.0775" y2="7.6308" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint66_linear_201_4713" x1="41.8003" y1="7.7204" x2="42.308" y2="8.46705" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint67_linear_201_4713" x1="43.8901" y1="7.44165" x2="44.3979" y2="8.1883" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint68_linear_201_4713" x1="45.2847" y1="8.55667" x2="45.7924" y2="9.30332" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint69_linear_201_4713" x1="44.2311" y1="9.25354" x2="44.9081" y2="10.2491" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint70_linear_201_4713" x1="46.2911" y1="14.1316" x2="46.8834" y2="15.0027" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint71_linear_201_4713" x1="53.985" y1="19.846" x2="54.662" y2="20.8415" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint72_linear_201_4713" x1="42.1374" y1="17.7553" x2="42.8143" y2="18.7509" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint73_linear_201_4713" x1="37.8768" y1="20.4034" x2="38.723" y2="21.6478" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint74_linear_201_4713" x1="37.8768" y1="23.0516" x2="38.723" y2="24.296" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint75_linear_201_4713" x1="41.6424" y1="23.0516" x2="42.4886" y2="24.296" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint76_linear_201_4713" x1="43.1737" y1="27.7903" x2="44.0199" y2="29.0347" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint77_linear_201_4713" x1="39.2713" y1="27.2328" x2="40.1175" y2="28.4772" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint78_linear_201_4713" x1="37.5994" y1="27.2328" x2="38.4456" y2="28.4772" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint79_linear_201_4713" x1="37.4588" y1="24.8634" x2="38.305" y2="26.1078" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint80_linear_201_4713" x1="35.9276" y1="19.2885" x2="36.7738" y2="20.5329" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint81_linear_201_4713" x1="41.3612" y1="27.0934" x2="42.2074" y2="28.3378" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint82_linear_201_4713" x1="40.0475" y1="19.2885" x2="40.7245" y2="20.284" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint83_linear_201_4713" x1="49.6435" y1="21.7972" x2="50.659" y2="23.2905" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint84_linear_201_4713" x1="48.2529" y1="30.1597" x2="49.2684" y2="31.653" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint85_linear_201_4713" x1="38.3545" y1="29.6022" x2="39.3699" y2="31.0955" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint86_linear_201_4713" x1="45.7412" y1="27.2328" x2="46.7566" y2="28.7261" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint87_linear_201_4713" x1="41.1435" y1="30.7172" x2="42.159" y2="32.2105" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint88_linear_201_4713" x1="43.9287" y1="36.7103" x2="44.9441" y2="38.2036" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint89_linear_201_4713" x1="42.3975" y1="39.219" x2="43.4129" y2="40.7123" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint90_linear_201_4713" x1="43.9287" y1="31.5534" x2="44.9441" y2="33.0467" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint91_linear_201_4713" x1="38.3545" y1="38.9402" x2="39.3699" y2="40.4335" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint92_linear_201_4713" x1="42.8154" y1="44.5152" x2="43.8309" y2="46.0085" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint93_linear_201_4713" x1="38.4055" y1="45.7696" x2="39.1671" y2="46.8895" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint94_linear_201_4713" x1="32.2727" y1="44.2365" x2="33.0343" y2="45.3564" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint95_linear_201_4713" x1="30.3196" y1="42.9821" x2="31.0812" y2="44.102" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint96_linear_201_4713" x1="28.4002" y1="43.5396" x2="29.2464" y2="44.784" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint97_linear_201_4713" x1="28.9588" y1="47.7208" x2="29.805" y2="48.9652" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint98_linear_201_4713" x1="27.4237" y1="51.6233" x2="28.2699" y2="52.8677" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint99_linear_201_4713" x1="28.9289" y1="49.8114" x2="29.6905" y2="50.9314" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint100_linear_201_4713" x1="39.749" y1="44.0971" x2="40.7645" y2="45.5904" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint101_linear_201_4713" x1="36.6283" y1="35.1731" x2="12.4665" y2="22.2113" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F3F1F1"/>
        <stop offset="1" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint102_linear_201_4713" x1="12.6553" y1="22.1483" x2="24.1699" y2="43.5416" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
        <stop offset="1" stop-color="#FBFBFB" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint103_linear_201_4713" x1="24.043" y1="28.818" x2="35.8053" y2="36.2536" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0.7"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "HeaderLogo"
}
</script>

<style scoped>

</style>