<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="54" viewBox="0 0 52 54" fill="none">
    <path d="M0.17426 37.4748V13.1191L20.6564 1.07707L41.9475 13.0129V37.2027L20.5472 48.9245L0.17426 37.4748Z" fill="white" stroke="#ADE3E3" stroke-width="0.325083"/>
    <ellipse cx="33.1995" cy="16.8984" rx="0.84009" ry="0.84009" fill="url(#paint0_linear_349_9547)"/>
    <ellipse cx="29.6018" cy="15.2183" rx="0.84009" ry="0.84009" fill="url(#paint1_linear_349_9547)"/>
    <ellipse cx="31.2776" cy="10.7778" rx="0.84009" ry="0.84009" fill="url(#paint2_linear_349_9547)"/>
    <ellipse cx="36.0784" cy="12.698" rx="0.84009" ry="0.84009" fill="url(#paint3_linear_349_9547)"/>
    <ellipse cx="42.801" cy="12.938" rx="0.84009" ry="0.84009" fill="url(#paint4_linear_349_9547)"/>
    <ellipse cx="25.0393" cy="13.178" rx="0.84009" ry="0.84009" fill="url(#paint5_linear_349_9547)"/>
    <ellipse cx="22.9963" cy="11.4979" rx="0.84009" ry="0.84009" fill="url(#paint6_linear_349_9547)"/>
    <ellipse cx="28.1604" cy="12.3379" rx="0.84009" ry="0.84009" fill="url(#paint7_linear_349_9547)"/>
    <ellipse cx="36.6799" cy="10.2977" rx="0.84009" ry="0.84009" fill="url(#paint8_linear_349_9547)"/>
    <ellipse cx="40.5237" cy="15.6984" rx="0.84009" ry="0.84009" fill="url(#paint9_linear_349_9547)"/>
    <ellipse cx="34.8831" cy="22.8991" rx="0.84009" ry="0.84009" fill="url(#paint10_linear_349_9547)"/>
    <ellipse cx="38.5979" cy="22.059" rx="0.84009" ry="0.84009" fill="url(#paint11_linear_349_9547)"/>
    <ellipse cx="36.1995" cy="31.9001" rx="0.84009" ry="0.84009" fill="url(#paint12_linear_349_9547)"/>
    <ellipse cx="34.0393" cy="29.1398" rx="0.84009" ry="0.84009" fill="url(#paint13_linear_349_9547)"/>
    <ellipse cx="33.801" cy="31.9001" rx="0.84009" ry="0.84009" fill="url(#paint14_linear_349_9547)"/>
    <ellipse cx="30.801" cy="34.3004" rx="0.84009" ry="0.84009" fill="url(#paint15_linear_349_9547)"/>
    <ellipse cx="32.2385" cy="32.9802" rx="0.84009" ry="0.84009" fill="url(#paint16_linear_349_9547)"/>
    <ellipse cx="30.2034" cy="39.1009" rx="0.84009" ry="0.84009" fill="url(#paint17_linear_349_9547)"/>
    <ellipse cx="34.2815" cy="37.5407" rx="0.84009" ry="0.84009" fill="url(#paint18_linear_349_9547)"/>
    <ellipse cx="30.0823" cy="36.4606" rx="0.84009" ry="0.84009" fill="url(#paint19_linear_349_9547)"/>
    <ellipse cx="32.2385" cy="36.9406" rx="0.84009" ry="0.84009" fill="url(#paint20_linear_349_9547)"/>
    <ellipse cx="30.4417" cy="42.9412" rx="0.84009" ry="0.84009" fill="url(#paint21_linear_349_9547)"/>
    <ellipse cx="27.0823" cy="44.0214" rx="0.84009" ry="0.84009" fill="url(#paint22_linear_349_9547)"/>
    <ellipse cx="28.2776" cy="37.6607" rx="0.84009" ry="0.84009" fill="url(#paint23_linear_349_9547)"/>
    <ellipse cx="28.3987" cy="41.8611" rx="0.84009" ry="0.84009" fill="url(#paint24_linear_349_9547)"/>
    <ellipse cx="26.2424" cy="40.781" rx="0.84009" ry="0.84009" fill="url(#paint25_linear_349_9547)"/>
    <ellipse cx="22.637" cy="38.9808" rx="0.84009" ry="0.84009" fill="url(#paint26_linear_349_9547)"/>
    <ellipse cx="23.8401" cy="40.901" rx="0.84009" ry="0.84009" fill="url(#paint27_linear_349_9547)"/>
    <ellipse cx="46.8831" cy="24.3393" rx="0.84009" ry="0.84009" fill="url(#paint28_linear_349_9547)"/>
    <ellipse cx="29.599" cy="8.61754" rx="0.720077" ry="0.720077" fill="url(#paint29_linear_349_9547)"/>
    <ellipse cx="24.4388" cy="8.85759" rx="0.720077" ry="0.720077" fill="url(#paint30_linear_349_9547)"/>
    <ellipse cx="32.3607" cy="13.178" rx="0.720077" ry="0.720077" fill="url(#paint31_linear_349_9547)"/>
    <ellipse cx="26.8412" cy="14.9783" rx="0.720077" ry="0.720077" fill="url(#paint32_linear_349_9547)"/>
    <ellipse cx="36.3216" cy="19.0587" rx="0.720077" ry="0.720077" fill="url(#paint33_linear_349_9547)"/>
    <ellipse cx="25.8802" cy="10.6578" rx="0.720077" ry="0.720077" fill="url(#paint34_linear_349_9547)"/>
    <ellipse cx="40.0404" cy="20.1387" rx="0.720077" ry="0.720077" fill="url(#paint35_linear_349_9547)"/>
    <ellipse cx="41.9644" cy="17.3785" rx="0.480052" ry="0.480051" fill="url(#paint36_linear_349_9547)"/>
    <ellipse cx="44.3007" cy="18.3986" rx="0.660071" ry="0.660071" fill="url(#paint37_linear_349_9547)"/>
    <ellipse cx="46.5819" cy="27.7596" rx="0.660071" ry="0.660071" fill="url(#paint38_linear_349_9547)"/>
    <ellipse cx="41.3046" cy="22.9591" rx="0.660071" ry="0.660071" fill="url(#paint39_linear_349_9547)"/>
    <ellipse cx="38.2968" cy="18.1586" rx="0.660071" ry="0.660071" fill="url(#paint40_linear_349_9547)"/>
    <ellipse cx="49.403" cy="22.4191" rx="0.360039" ry="0.360039" fill="url(#paint41_linear_349_9547)"/>
    <ellipse cx="49.5202" cy="23.8592" rx="0.360039" ry="0.360039" fill="url(#paint42_linear_349_9547)"/>
    <ellipse cx="50.2468" cy="26.4994" rx="0.360039" ry="0.360039" fill="url(#paint43_linear_349_9547)"/>
    <ellipse cx="44.6022" cy="24.4592" rx="0.360039" ry="0.360039" fill="url(#paint44_linear_349_9547)"/>
    <ellipse cx="41.2429" cy="24.4592" rx="0.360039" ry="0.360039" fill="url(#paint45_linear_349_9547)"/>
    <ellipse cx="40.2819" cy="28.5397" rx="0.360039" ry="0.360039" fill="url(#paint46_linear_349_9547)"/>
    <ellipse cx="32.36" cy="39.3408" rx="0.360039" ry="0.360039" fill="url(#paint47_linear_349_9547)"/>
    <ellipse cx="33.321" cy="43.0613" rx="0.360039" ry="0.360039" fill="url(#paint48_linear_349_9547)"/>
    <ellipse cx="33.321" cy="45.9416" rx="0.360039" ry="0.360039" fill="url(#paint49_linear_349_9547)"/>
    <ellipse cx="22.3991" cy="42.9413" rx="0.360039" ry="0.360039" fill="url(#paint50_linear_349_9547)"/>
    <ellipse cx="21.5593" cy="41.141" rx="0.360039" ry="0.360039" fill="url(#paint51_linear_349_9547)"/>
    <ellipse cx="35.0007" cy="44.9814" rx="0.360039" ry="0.360039" fill="url(#paint52_linear_349_9547)"/>
    <ellipse cx="36.8014" cy="42.8212" rx="0.360039" ry="0.360039" fill="url(#paint53_linear_349_9547)"/>
    <ellipse cx="23.8405" cy="43.5413" rx="0.360039" ry="0.360039" fill="url(#paint54_linear_349_9547)"/>
    <ellipse cx="42.8014" cy="23.8592" rx="0.360039" ry="0.360039" fill="url(#paint55_linear_349_9547)"/>
    <ellipse cx="43.2819" cy="22.5391" rx="0.360039" ry="0.360039" fill="url(#paint56_linear_349_9547)"/>
    <ellipse cx="49.278" cy="20.2588" rx="0.360039" ry="0.360039" fill="url(#paint57_linear_349_9547)"/>
    <ellipse cx="49.278" cy="15.0983" rx="0.360039" ry="0.360039" fill="url(#paint58_linear_349_9547)"/>
    <ellipse cx="50.8405" cy="16.1783" rx="0.360039" ry="0.360039" fill="url(#paint59_linear_349_9547)"/>
    <ellipse cx="46.0436" cy="14.9782" rx="0.360039" ry="0.360039" fill="url(#paint60_linear_349_9547)"/>
    <ellipse cx="44.7194" cy="15.4583" rx="0.360039" ry="0.360039" fill="url(#paint61_linear_349_9547)"/>
    <ellipse cx="38.3639" cy="14.6182" rx="0.360039" ry="0.360039" fill="url(#paint62_linear_349_9547)"/>
    <ellipse cx="33.9225" cy="10.8978" rx="0.360039" ry="0.360039" fill="url(#paint63_linear_349_9547)"/>
    <ellipse cx="33.6804" cy="8.8576" rx="0.360039" ry="0.360039" fill="url(#paint64_linear_349_9547)"/>
    <ellipse cx="34.2819" cy="7.17736" rx="0.360039" ry="0.360039" fill="url(#paint65_linear_349_9547)"/>
    <ellipse cx="36.1999" cy="7.89745" rx="0.360039" ry="0.360039" fill="url(#paint66_linear_349_9547)"/>
    <ellipse cx="37.9968" cy="7.65752" rx="0.360039" ry="0.360039" fill="url(#paint67_linear_349_9547)"/>
    <ellipse cx="39.2038" cy="8.61755" rx="0.360039" ry="0.360039" fill="url(#paint68_linear_349_9547)"/>
    <ellipse cx="38.3629" cy="9.33766" rx="0.480052" ry="0.480051" fill="url(#paint69_linear_349_9547)"/>
    <ellipse cx="40.1036" cy="13.4781" rx="0.420045" ry="0.420045" fill="url(#paint70_linear_349_9547)"/>
    <ellipse cx="46.7613" cy="18.4586" rx="0.480052" ry="0.480051" fill="url(#paint71_linear_349_9547)"/>
    <ellipse cx="36.5582" cy="16.6584" rx="0.480052" ry="0.480051" fill="url(#paint72_linear_349_9547)"/>
    <ellipse cx="32.9594" cy="19.0586" rx="0.600064" ry="0.600064" fill="url(#paint73_linear_349_9547)"/>
    <ellipse cx="32.9594" cy="21.3389" rx="0.600064" ry="0.600064" fill="url(#paint74_linear_349_9547)"/>
    <ellipse cx="36.2016" cy="21.3389" rx="0.600064" ry="0.600064" fill="url(#paint75_linear_349_9547)"/>
    <ellipse cx="37.518" cy="25.4193" rx="0.600064" ry="0.600064" fill="url(#paint76_linear_349_9547)"/>
    <ellipse cx="34.1626" cy="24.9393" rx="0.600064" ry="0.600064" fill="url(#paint77_linear_349_9547)"/>
    <ellipse cx="32.7212" cy="24.9393" rx="0.600064" ry="0.600064" fill="url(#paint78_linear_349_9547)"/>
    <ellipse cx="32.6001" cy="22.8991" rx="0.600064" ry="0.600064" fill="url(#paint79_linear_349_9547)"/>
    <ellipse cx="31.2798" cy="18.0986" rx="0.600064" ry="0.600064" fill="url(#paint80_linear_349_9547)"/>
    <ellipse cx="35.9594" cy="24.8192" rx="0.600064" ry="0.600064" fill="url(#paint81_linear_349_9547)"/>
    <ellipse cx="34.7613" cy="17.9786" rx="0.480052" ry="0.480051" fill="url(#paint82_linear_349_9547)"/>
    <ellipse cx="43.1615" cy="20.3788" rx="0.720077" ry="0.720077" fill="url(#paint83_linear_349_9547)"/>
    <ellipse cx="41.9623" cy="27.5796" rx="0.720077" ry="0.720077" fill="url(#paint84_linear_349_9547)"/>
    <ellipse cx="33.4388" cy="27.0995" rx="0.720077" ry="0.720077" fill="url(#paint85_linear_349_9547)"/>
    <ellipse cx="39.8021" cy="25.0593" rx="0.720077" ry="0.720077" fill="url(#paint86_linear_349_9547)"/>
    <ellipse cx="35.8412" cy="28.0597" rx="0.720077" ry="0.720077" fill="url(#paint87_linear_349_9547)"/>
    <ellipse cx="38.2396" cy="33.2203" rx="0.720077" ry="0.720077" fill="url(#paint88_linear_349_9547)"/>
    <ellipse cx="36.9232" cy="35.3804" rx="0.720077" ry="0.720077" fill="url(#paint89_linear_349_9547)"/>
    <ellipse cx="38.2396" cy="28.7797" rx="0.720077" ry="0.720077" fill="url(#paint90_linear_349_9547)"/>
    <ellipse cx="33.4388" cy="35.1404" rx="0.720077" ry="0.720077" fill="url(#paint91_linear_349_9547)"/>
    <ellipse cx="37.2826" cy="39.9409" rx="0.720077" ry="0.720077" fill="url(#paint92_linear_349_9547)"/>
    <ellipse cx="33.3799" cy="40.841" rx="0.540058" ry="0.540058" fill="url(#paint93_linear_349_9547)"/>
    <ellipse cx="28.0987" cy="39.5208" rx="0.540058" ry="0.540058" fill="url(#paint94_linear_349_9547)"/>
    <ellipse cx="26.4151" cy="38.4408" rx="0.540058" ry="0.540058" fill="url(#paint95_linear_349_9547)"/>
    <ellipse cx="24.7993" cy="38.9808" rx="0.600064" ry="0.600064" fill="url(#paint96_linear_349_9547)"/>
    <ellipse cx="25.2837" cy="42.5812" rx="0.600064" ry="0.600064" fill="url(#paint97_linear_349_9547)"/>
    <ellipse cx="23.9594" cy="45.9416" rx="0.600064" ry="0.600064" fill="url(#paint98_linear_349_9547)"/>
    <ellipse cx="25.2237" cy="44.3214" rx="0.540058" ry="0.540058" fill="url(#paint99_linear_349_9547)"/>
    <ellipse cx="34.642" cy="39.5809" rx="0.720077" ry="0.720077" fill="url(#paint100_linear_349_9547)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.3429 22.474C5.39215 22.4465 5.45209 22.4466 5.50129 22.4741L10.5401 25.2915C10.5913 25.3201 10.6231 25.3741 10.6233 25.4327C10.6235 25.4913 10.5922 25.5455 10.5412 25.5746L5.50244 28.4461C5.45426 28.4736 5.39541 28.4746 5.34632 28.4488L0.0908059 25.6856C0.0379862 25.6578 0.00463164 25.6033 0.00391793 25.5437C0.00320422 25.484 0.0352461 25.4287 0.0873865 25.3997L5.3429 22.474ZM0.507953 25.5376L5.41882 28.1196L10.1301 25.4347L5.42179 22.8021L0.507953 25.5376Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.36567 28.1425C5.45544 28.1425 5.52821 28.2153 5.52821 28.305V34.3309L26.303 45.6627C26.3818 45.7056 26.4109 45.8044 26.3679 45.8832C26.3249 45.962 26.2262 45.991 26.1473 45.948L5.28783 34.5701C5.23561 34.5416 5.20312 34.4869 5.20312 34.4274V28.305C5.20312 28.2153 5.2759 28.1425 5.36567 28.1425Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.0058 32.5522C34.0556 32.524 34.1165 32.524 34.1663 32.5523L42.0767 37.0493C42.1547 37.0936 42.182 37.1929 42.1376 37.2709C42.0933 37.3489 41.994 37.3762 41.916 37.3319L34.0858 32.8805L13.0357 44.8089C12.9576 44.8532 12.8584 44.8257 12.8141 44.7476C12.7699 44.6695 12.7973 44.5703 12.8754 44.5261L34.0058 32.5522Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.0844 8.42072C34.1742 8.42072 34.247 8.49349 34.247 8.58326V32.6936C34.247 32.7834 34.1742 32.8561 34.0844 32.8561C33.9946 32.8561 33.9219 32.7834 33.9219 32.6936V8.58326C33.9219 8.49349 33.9946 8.42072 34.0844 8.42072Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.28133 8.77681C7.32405 8.69786 7.42269 8.66849 7.50164 8.7112L18.717 14.7794C18.796 14.8221 18.8253 14.9208 18.7826 14.9997C18.7399 15.0787 18.6413 15.1081 18.5623 15.0653L7.34694 8.99712C7.26799 8.9544 7.23862 8.85577 7.28133 8.77681Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0242299 13.0557C0.0676652 12.9772 0.166563 12.9487 0.245125 12.9921L11.5147 19.2229C11.5932 19.2663 11.6217 19.3652 11.5783 19.4438C11.5348 19.5223 11.436 19.5508 11.3574 19.5074L0.0878311 13.2766C0.00926972 13.2332 -0.0192055 13.1343 0.0242299 13.0557Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7275 8.55791C18.7714 8.63618 18.7436 8.73527 18.6653 8.77921L5.52821 16.1554V22.6161C5.52821 22.7058 5.45544 22.7786 5.36567 22.7786C5.2759 22.7786 5.20312 22.7058 5.20312 22.6161V16.0602C5.20312 16.0015 5.23485 15.9473 5.28609 15.9185L18.5061 8.49576C18.5844 8.45181 18.6835 8.47963 18.7275 8.55791Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3718 5.25028C28.4172 5.32774 28.3912 5.42731 28.3137 5.47268L20.7284 9.91549C20.6788 9.94457 20.6174 9.94523 20.5672 9.91722L12.9819 5.69114C12.9035 5.64745 12.8753 5.54846 12.919 5.47004C12.9627 5.39162 13.0617 5.36347 13.1401 5.40716L20.6443 9.58804L28.1494 5.19217C28.2269 5.1468 28.3265 5.17282 28.3718 5.25028Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0643 5.38663C13.1541 5.38688 13.2267 5.45985 13.2264 5.54962L13.1181 44.668C13.1178 44.7577 13.0448 44.8303 12.9551 44.8301C12.8653 44.8298 12.7927 44.7569 12.793 44.6671L12.9013 5.54872C12.9016 5.45895 12.9746 5.38638 13.0643 5.38663Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6463 0.943849C20.7361 0.943518 20.8091 1.01602 20.8095 1.10579L20.8636 15.7887C20.864 15.8785 20.7915 15.9515 20.7017 15.9519C20.6119 15.9522 20.5389 15.8797 20.5386 15.7899L20.4844 1.10699C20.484 1.01722 20.5565 0.94418 20.6463 0.943849Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.2323 5.27832C28.322 5.27799 28.3951 5.35049 28.3954 5.44026L28.4496 20.1232C28.4499 20.213 28.3774 20.286 28.2876 20.2863C28.1979 20.2867 28.1248 20.2142 28.1245 20.1244L28.0703 5.44146C28.07 5.35169 28.1425 5.27865 28.2323 5.27832Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9755 26.2746C20.0197 26.3527 19.9921 26.4519 19.9139 26.496L0.246405 37.603C0.168239 37.6472 0.069088 37.6196 0.0249447 37.5414C-0.0191985 37.4633 0.00838224 37.3641 0.0865481 37.32L19.7541 26.213C19.8323 26.1688 19.9314 26.1964 19.9755 26.2746Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1279 30.2839C27.172 30.3621 27.1445 30.4613 27.0663 30.5054L7.39875 41.6124C7.32058 41.6566 7.22143 41.629 7.17729 41.5508C7.13315 41.4726 7.16073 41.3735 7.23889 41.3293L26.9064 30.2223C26.9846 30.1782 27.0837 30.2058 27.1279 30.2839Z" fill="#ADE3E3"/>
    <g filter="url(#filter0_f_349_9547)">
      <path d="M24.0413 13.6763L11.8203 21.1568V35.5037L23.9771 42.5003L36.7434 34.6962V21.6094L24.0413 13.6763Z" fill="#24C8BC"/>
    </g>
    <g filter="url(#filter1_dd_349_9547)">
      <path d="M20.8276 13.7304L10.5195 20.0017V32.0293L20.7735 37.8949L31.5416 31.3524V20.3811L20.8276 13.7304Z" fill="url(#paint101_linear_349_9547)"/>
    </g>
    <path d="M20.8138 25.5959V37.8948L10.5195 32.1517V20.0153L20.8138 25.5959Z" fill="url(#paint102_linear_349_9547)"/>
    <path d="M31.4851 20.2322L31.5934 31.3934L20.7031 37.8409V25.6502L31.4851 20.2322Z" fill="url(#paint103_linear_349_9547)"/>
    <defs>
      <filter id="filter0_f_349_9547" x="0.550757" y="2.40671" width="47.461" height="51.3632" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="5.63478" result="effect1_foregroundBlur_349_9547"/>
      </filter>
      <filter id="filter1_dd_349_9547" x="6.83525" y="12.3217" width="29.3672" height="31.5331" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1.62542" dy="2.16722"/>
        <feGaussianBlur stdDeviation="1.51706"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.631373 0 0 0 0 0.631373 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_349_9547"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2.27558"/>
        <feGaussianBlur stdDeviation="1.84214"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_349_9547" result="effect2_dropShadow_349_9547"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_349_9547" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_349_9547" x1="32.7194" y1="16.0583" x2="33.7395" y2="17.5585" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint1_linear_349_9547" x1="29.1218" y1="14.3782" x2="30.1419" y2="15.8783" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint2_linear_349_9547" x1="30.7975" y1="9.93768" x2="31.8176" y2="11.4378" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint3_linear_349_9547" x1="35.5983" y1="11.8579" x2="36.6184" y2="13.3581" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint4_linear_349_9547" x1="42.321" y1="12.0979" x2="43.3411" y2="13.5981" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint5_linear_349_9547" x1="24.5593" y1="12.338" x2="25.5794" y2="13.8381" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint6_linear_349_9547" x1="22.5163" y1="10.6578" x2="23.5364" y2="12.1579" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint7_linear_349_9547" x1="27.6804" y1="11.4978" x2="28.7005" y2="12.998" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint8_linear_349_9547" x1="36.1999" y1="9.45764" x2="37.22" y2="10.9578" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint9_linear_349_9547" x1="40.0436" y1="14.8583" x2="41.0637" y2="16.3584" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint10_linear_349_9547" x1="34.403" y1="22.059" x2="35.4231" y2="23.5592" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint11_linear_349_9547" x1="38.1179" y1="21.2189" x2="39.138" y2="22.719" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint12_linear_349_9547" x1="35.7194" y1="31.06" x2="36.7395" y2="32.5602" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint13_linear_349_9547" x1="33.5593" y1="28.2997" x2="34.5794" y2="29.7998" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint14_linear_349_9547" x1="33.321" y1="31.06" x2="34.3411" y2="32.5602" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint15_linear_349_9547" x1="30.321" y1="33.4603" x2="31.3411" y2="34.9604" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint16_linear_349_9547" x1="31.7585" y1="32.1401" x2="32.7786" y2="33.6402" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint17_linear_349_9547" x1="29.7233" y1="38.2608" x2="30.7434" y2="39.761" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint18_linear_349_9547" x1="33.8014" y1="36.7006" x2="34.8216" y2="38.2007" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint19_linear_349_9547" x1="29.6022" y1="35.6205" x2="30.6223" y2="37.1206" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint20_linear_349_9547" x1="31.7585" y1="36.1005" x2="32.7786" y2="37.6007" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint21_linear_349_9547" x1="29.9616" y1="42.1011" x2="30.9817" y2="43.6012" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint22_linear_349_9547" x1="26.6022" y1="43.1813" x2="27.6223" y2="44.6814" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint23_linear_349_9547" x1="27.7975" y1="36.8206" x2="28.8176" y2="38.3208" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint24_linear_349_9547" x1="27.9186" y1="41.021" x2="28.9387" y2="42.5212" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint25_linear_349_9547" x1="25.7624" y1="39.9409" x2="26.7825" y2="41.4411" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint26_linear_349_9547" x1="22.1569" y1="38.1407" x2="23.177" y2="39.6409" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint27_linear_349_9547" x1="23.36" y1="40.0609" x2="24.3801" y2="41.5611" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint28_linear_349_9547" x1="46.403" y1="23.4992" x2="47.4231" y2="24.9994" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint29_linear_349_9547" x1="29.1875" y1="7.89746" x2="30.0619" y2="9.18331" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint30_linear_349_9547" x1="24.0274" y1="8.13751" x2="24.9017" y2="9.42336" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint31_linear_349_9547" x1="31.9492" y1="12.4579" x2="32.8236" y2="13.7438" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint32_linear_349_9547" x1="26.4297" y1="14.2582" x2="27.3041" y2="15.544" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint33_linear_349_9547" x1="35.9102" y1="18.3386" x2="36.7845" y2="19.6245" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint34_linear_349_9547" x1="25.4688" y1="9.93768" x2="26.3431" y2="11.2235" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint35_linear_349_9547" x1="39.6289" y1="19.4186" x2="40.5033" y2="20.7045" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint36_linear_349_9547" x1="41.6901" y1="16.8984" x2="42.273" y2="17.7557" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint37_linear_349_9547" x1="43.9235" y1="17.7385" x2="44.725" y2="18.9172" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint38_linear_349_9547" x1="46.2048" y1="27.0995" x2="47.0063" y2="28.2782" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint39_linear_349_9547" x1="40.9274" y1="22.299" x2="41.7289" y2="23.4777" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint40_linear_349_9547" x1="37.9196" y1="17.4985" x2="38.7211" y2="18.6772" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint41_linear_349_9547" x1="49.1973" y1="22.059" x2="49.6345" y2="22.7019" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint42_linear_349_9547" x1="49.3145" y1="23.4991" x2="49.7516" y2="24.1421" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint43_linear_349_9547" x1="50.041" y1="26.1394" x2="50.4782" y2="26.7823" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint44_linear_349_9547" x1="44.3965" y1="24.0992" x2="44.8337" y2="24.7421" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint45_linear_349_9547" x1="41.0371" y1="24.0992" x2="41.4743" y2="24.7421" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint46_linear_349_9547" x1="40.0762" y1="28.1797" x2="40.5134" y2="28.8226" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint47_linear_349_9547" x1="32.1543" y1="38.9808" x2="32.5915" y2="39.6237" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint48_linear_349_9547" x1="33.1152" y1="42.7012" x2="33.5524" y2="43.3442" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint49_linear_349_9547" x1="33.1152" y1="45.5815" x2="33.5524" y2="46.2245" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint50_linear_349_9547" x1="22.1934" y1="42.5812" x2="22.6306" y2="43.2242" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint51_linear_349_9547" x1="21.3535" y1="40.781" x2="21.7907" y2="41.4239" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint52_linear_349_9547" x1="34.7949" y1="44.6214" x2="35.2321" y2="45.2643" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint53_linear_349_9547" x1="36.5957" y1="42.4612" x2="37.0329" y2="43.1041" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint54_linear_349_9547" x1="23.6348" y1="43.1813" x2="24.072" y2="43.8242" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint55_linear_349_9547" x1="42.5957" y1="23.4991" x2="43.0329" y2="24.1421" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint56_linear_349_9547" x1="43.0762" y1="22.179" x2="43.5134" y2="22.8219" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint57_linear_349_9547" x1="49.0723" y1="19.8987" x2="49.5095" y2="20.5417" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint58_linear_349_9547" x1="49.0723" y1="14.7382" x2="49.5095" y2="15.3811" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint59_linear_349_9547" x1="50.6348" y1="15.8183" x2="51.072" y2="16.4612" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint60_linear_349_9547" x1="45.8379" y1="14.6182" x2="46.2751" y2="15.2611" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint61_linear_349_9547" x1="44.5137" y1="15.0983" x2="44.9509" y2="15.7412" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint62_linear_349_9547" x1="38.1582" y1="14.2582" x2="38.5954" y2="14.9011" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint63_linear_349_9547" x1="33.7168" y1="10.5377" x2="34.154" y2="11.1806" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint64_linear_349_9547" x1="33.4746" y1="8.49756" x2="33.9118" y2="9.14048" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint65_linear_349_9547" x1="34.0762" y1="6.81732" x2="34.5134" y2="7.46025" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint66_linear_349_9547" x1="35.9941" y1="7.53741" x2="36.4313" y2="8.18034" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint67_linear_349_9547" x1="37.791" y1="7.29749" x2="38.2282" y2="7.94041" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint68_linear_349_9547" x1="38.9981" y1="8.25751" x2="39.4352" y2="8.90043" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint69_linear_349_9547" x1="38.0885" y1="8.8576" x2="38.6715" y2="9.71484" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint70_linear_349_9547" x1="39.8636" y1="13.058" x2="40.3737" y2="13.8081" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint71_linear_349_9547" x1="46.487" y1="17.9786" x2="47.0699" y2="18.8358" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint72_linear_349_9547" x1="36.2839" y1="16.1783" x2="36.8668" y2="17.0356" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint73_linear_349_9547" x1="32.6165" y1="18.4586" x2="33.3452" y2="19.5301" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint74_linear_349_9547" x1="32.6165" y1="20.7388" x2="33.3452" y2="21.8104" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint75_linear_349_9547" x1="35.8587" y1="20.7388" x2="36.5874" y2="21.8104" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint76_linear_349_9547" x1="37.1751" y1="24.8193" x2="37.9038" y2="25.8908" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint77_linear_349_9547" x1="33.8197" y1="24.3392" x2="34.5483" y2="25.4108" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint78_linear_349_9547" x1="32.3783" y1="24.3392" x2="33.1069" y2="25.4108" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint79_linear_349_9547" x1="32.2572" y1="22.299" x2="32.9858" y2="23.3706" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint80_linear_349_9547" x1="30.9369" y1="17.4985" x2="31.6655" y2="18.5701" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint81_linear_349_9547" x1="35.6165" y1="24.2192" x2="36.3452" y2="25.2907" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint82_linear_349_9547" x1="34.487" y1="17.4985" x2="35.0699" y2="18.3558" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint83_linear_349_9547" x1="42.75" y1="19.6588" x2="43.6244" y2="20.9446" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint84_linear_349_9547" x1="41.5508" y1="26.8596" x2="42.4252" y2="28.1454" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint85_linear_349_9547" x1="33.0274" y1="26.3795" x2="33.9017" y2="27.6653" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint86_linear_349_9547" x1="39.3906" y1="24.3392" x2="40.265" y2="25.6251" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint87_linear_349_9547" x1="35.4297" y1="27.3396" x2="36.3041" y2="28.6255" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint88_linear_349_9547" x1="37.8281" y1="32.5002" x2="38.7025" y2="33.786" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint89_linear_349_9547" x1="36.5117" y1="34.6603" x2="37.3861" y2="35.9462" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint90_linear_349_9547" x1="37.8281" y1="28.0596" x2="38.7025" y2="29.3455" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint91_linear_349_9547" x1="33.0274" y1="34.4203" x2="33.9017" y2="35.7062" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint92_linear_349_9547" x1="36.8711" y1="39.2208" x2="37.7455" y2="40.5067" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint93_linear_349_9547" x1="33.0713" y1="40.301" x2="33.7271" y2="41.2654" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint94_linear_349_9547" x1="27.79" y1="38.9808" x2="28.4458" y2="39.9452" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint95_linear_349_9547" x1="26.1065" y1="37.9007" x2="26.7622" y2="38.8651" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint96_linear_349_9547" x1="24.4564" y1="38.3807" x2="25.185" y2="39.4523" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint97_linear_349_9547" x1="24.9408" y1="41.9811" x2="25.6694" y2="43.0527" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint98_linear_349_9547" x1="23.6165" y1="45.3416" x2="24.3452" y2="46.4131" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint99_linear_349_9547" x1="24.915" y1="43.7814" x2="25.5708" y2="44.7458" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint100_linear_349_9547" x1="34.2305" y1="38.8608" x2="35.1049" y2="40.1466" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint101_linear_349_9547" x1="31.5416" y1="31.1765" x2="10.7363" y2="20.0153" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F3F1F1"/>
        <stop offset="1" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint102_linear_349_9547" x1="10.8988" y1="19.9611" x2="20.8138" y2="38.3825" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
        <stop offset="1" stop-color="#FBFBFB" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint103_linear_349_9547" x1="20.7031" y1="25.7044" x2="30.8315" y2="32.1071" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0.7"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "HeaderMobileLogo"
}
</script>

<style scoped>

</style>