import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { windowOpen } from "@/mixins/windowOpen";
import { getPrice } from "@/mixins/getPrice";
import { getFormattedDate } from "@/mixins/getFormattedDate";
/////
import './assets/scss/main.scss';

const app = createApp(App);

app
  .use(store)
  .use(router)
  .mixin(getPrice)
  .mixin(windowOpen)
  .mixin(getFormattedDate)
  .mount('#app');
