<template>
  <main>
    <div class="delivery-terms">
      <div class="container">
        <div class="delivery-terms__body">
          <h1>Условия доставки</h1>

          <div class="delivery-terms__content delivery-terms__content_mt-20">
            <div>Мы рады предложить вам удобный и надежный способ доставки продукции через курьерскую службу DPD. Пожалуйста, ознакомьтесь с условиями доставки.</div>
            <div>Стоимость доставки:</div>
            <div>Заказ продукции «<a href="#">https://nanobox.bio/</a>» возможен только на сумму более 10000₽. При заказе на сумму 10000₽ и выше доставка осуществляется бесплатно.</div>
            <div>Сроки доставки:</div>
            <div>Обычно заказ обрабатывается и готовится к отправке в течение 1-2 рабочих дней после подтверждения заказа. Срок доставки может варьироваться в зависимости от вашего местоположения. В среднем, срок доставки составляет от 3 до 7 рабочих дней.</div>
            <div>Процесс доставки:</div>
            <div>Ваш заказ будет упакован с заботой и профессионализмом нашими специалистами. После отправки вам будет предоставлен трекинг-номер, который позволит вам отслеживать перемещение вашего заказа на сайте DPD. Курьер DPD свяжется с вами для согласования удобного времени доставки. В день доставки курьер своевременно доставит ваш заказ по указанному адресу или сообщит адрес пункта выдачи.</div>
            <div>Правила и рекомендации:</div>
            <div>При получении заказа, пожалуйста, проверьте целостность упаковки и содержимое перед подписанием курьерской накладной. Если вы обнаружили повреждение упаковки или недостатки в продукции, пожалуйста, свяжитесь с нашей службой поддержки в течение 24 часов после получения заказа.</div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "DeliveryTerms",
}
</script>

<style scoped lang="scss">
.delivery-terms {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    &_mt-20 {
      margin-top: 20px;
    }

    & > div {
      font-size: 17px;
      line-height: 24px;

      & > p {
        font-size: 16px;
      }

      & a {
        font-size: 16px;
        line-height: 24px;
        color: #FF6100;
        text-decoration: underline;
      }
    }
  }
}

main {
  margin: 60px 0 80px;
}
</style>