<template>
  <div class="about-company">
    <div class="about-company__left">
      <h5>Заботимся о клиентах</h5>
      <div class="about-company__title about-company__title_mt-12">
        <h1>О компании</h1>
        <ArrowButton name="Узнать больше" type="right" @click="$router.push('/about-us')"/>
      </div>
      <div class="about-company__text about-company__text_mt-24">
        <p>Компания ООО “ТД Градалис” является российским производителем пищевых добавок и БАДов.</p>
        <p>
          В линейке «Звено Жизни» представлены такие продукты как: <span>Магний коллоидный, Цинк коллоидный, Серебро коллоидное</span>
          <br>
          Вся продукция прошла лабораторные испытания.
          <br>
          Особенность коллоидных минералов в том, что все они находятся в самой биодоступной для организма форме.
          <br>
          Данная форма называется - коллоидная форма.
          <br>
          Минералы в коллоидной форме превосходят по показателям биоусвоения и чистоты состава
        </p>
      </div>
      <div class="about-company__count about-company__count_mt-35">
        <div class="about-company__count-item">
          <div class="about-company__count-num">3 000+</div>
          <div class="about-company__count-text info__count-text_mt-2">Довольных клиентов</div>
        </div>
        <div class="about-company__count-item">
          <div class="about-company__count-num">3 000+</div>
          <div class="about-company__count-text about-company__count-text_mt-2">Проведено консультаций</div>
        </div>
        <div class="about-company__count-item">
          <div class="about-company__count-num">15</div>
          <div class="about-company__count-text about-company__count-text_mt-2">Наименований продукции</div>
        </div>
      </div>
    </div>
    <div class="about-company__right">
      <SpecialistServices/>
    </div>
  </div>
</template>

<script>
import ArrowButton from "@/components/Buttons/ArrowButton.vue";
import SpecialistServices from "@/components/SpecialistServices.vue";

export default {
  name: "AboutCompany",
  components: {SpecialistServices, ArrowButton}
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/Views/home/_aboutCompany.scss";
</style>