<template>
  <div class="last-operations">
    <div class="last-operations__title">Последние операции</div>

    <div class="last-operations__content last-operations__content_mt-12">
      <Preloader v-if="shortOperations?.length === 0"/>
      <template v-for="operation in getFormattedOperations" v-if="shortOperations?.length">
<!--        <OperationWithOrder-->
<!--            :order="operation"-->
<!--            v-if="operation.type === 'order'"-->
<!--        />-->
        <OperationWithBalance :card="operation"/>
      </template>
      <NoData
          class="last-operations__icon"
          name="Вы не совершили ни одной операции"
          v-if="!shortOperations"
      />
    </div>
  </div>
</template>

<script>
import OperationWithOrder from "@/components/Operations/OperationWithOrder.vue";
import OperationWithBalance from "@/components/Operations/OperationWithBalance.vue";
import NoData from "@/components/Operations/NoData.vue";
import {mapActions, mapState} from "vuex";
import Preloader from "@/components/Utils/Preloader.vue";

export default {
  name: "LastOperations",
  components: {
    Preloader,
    NoData,
    OperationWithBalance,
    OperationWithOrder
  },
  data() {
    return {
      translationMethods: {
        cash: 'Наличные',
        card: 'Карта',
        crypto: 'Крипта'
      }
    }
  },
  methods: {
    ...mapActions('auth', ['getShortOperations'])
  },
  computed: {
    ...mapState('auth', ['shortOperations']),

    getFormattedOperations() {
      return this.shortOperations.map(operation => new Object({
        id: operation.id,
        name: 'Пополнение баланса',
        methodName: 'Сумма пополнения',
        status: operation.status,
        price: this.getPrice(operation.invoice.price),
        method: this.translationMethods[operation.invoice.payment_type],
        date: this.getFormattedDate(operation.invoice.closed_at),

        // operations: {
        //   confirmations: '50/1',
        //   coin: 'USDT',
        //   network: 'BNB Smart Chain',
        //   address: 'TA23142155'
        // }
      }))
    }
  },
  mounted() {
    this.getShortOperations()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/Views/cabinet/lastOperations";
</style>