<template>
  <main>
    <div class="offer-agreement">
      <div class="container">
        <div class="offer-agreement__body">
          <h1>Договор публичной оферты о дистанционной продаже товаров на сайте ООО «Торговый Дом «Градалис»</h1>

          <div class="offer-agreement__content offer-agreement__content_mt-20">
            <div>Договор публичной оферты о дистанционной продаже товаров на сайте ООО «Торговый Дом «Градалис»</div>

            <div>В настоящем Договоре-оферте, если контекст не требует иного, нижеприведенные термины имеют следующие значения:</div>


            <div><strong>Оферта</strong> – настоящий Договор-оферта, включая все его приложения;</div>


            <div><strong>Продавец</strong> – ООО «Торговый Дом «Градалис», компания, осуществляющая продажу товаров дистанционно через сайт ООО «Торговый Дом «Градалис»
              <a href="#">https://nanobox.bio/</a>
            </div>


            <div><strong>Покупатель</strong> – физическое лицо, юридическое лицо или индивидуальный предприниматель, заключившее с Продавцом настоящий Договор-оферту на условиях, содержащихся в Договоре-оферте;</div>

            <div><strong>Акцепт</strong> – полное и безоговорочное принятие Покупателем условий настоящего Договора-оферты;</div>

            <div><strong>Товар</strong> – товар, продукция, информация о наименовании, ассортименте и цене которых размещается на сайте ООО «Торговый Дом «Градалис» https://nanobox.bio/</div>

            <div><strong>Интернет-заказ</strong> – отдельные позиции из ассортиментного перечня Товара, указанные Покупателем при оформлении заявки на сайте ООО «Торговый Дом «Градалис»
              <a href="#">https://nanobox.bio/</a>
            </div>

            <div><strong>Nanobox.bio</strong> – открытый для свободного визуального ознакомления, публично доступный, принадлежащий Продавцу ресурс, размещенный в сети Интернет по адресу
              <a href="#">https://nanobox.bio</a>, содержащий информацию об ассортименте товара, цене, условиях доставки товара Покупателю.</div>

            <div><strong>Пункт самовывоза</strong> – партнерский пункт с возможностью для Покупателя самостоятельно получить интернет-заказ.</div>

            <div>Служба доставки, Курьер – ООО «Торговый Дом «Градалис», обособленное подразделение ООО «Торговый Дом «Градалис» (грузоотправитель), обеспечивающий доставку товара Покупателю в пределах городов и регионов обслуживания грузоотправителя в России.</div>

            <div><strong>История покупок</strong> – закрытая зона Интернет-магазина Продавца, доступная только Покупателям, прошедшим процедуру регистрации, и содержащая информацию по всем Заказам Покупателя.</div>


            <div><strong>Личный Кабинет</strong> – интерфейс для хранения и редактирования пользовательских данных Покупателя, закрытая зона сайта ООО «Торговый Дом «Градалис»
              <a href="#">https://nanobox.bio/</a>, доступная только Покупателям, прошедшим процедуру регистрации, позволяющая управлять логическими блоками.</div>


            <div><strong>Персональные данные</strong> – механизм для хранения и редактирования личных данных, пароля, адресов доставки Заказа, контактной информации и регистрационных данных Покупателя в Личном кабинете.</div>



            <h2>Общие положения</h2>
            <div>1.1. В соответствии со ст.ст. 437, 494 Гражданского кодекса Российской Федерации данный документ, адресованный физическим, юридическим лицам и индивидуальным предпринимателям, является безотзывным предложением общества с ограниченной ответственностью «Торговый Дом «Градалис» (ООО «Торговый Дом «Градалис»), в лице генерального директора Урлагова Александра Андреевича, действующего на основании устава, заключить договор публичной оферты на указанных ниже условиях (далее – Договор-оферта).</div>
            <div>1.2. Полным и безоговорочным акцептом настоящего Договора-оферты является принятие Покупателем условий настоящего Договора-оферты при регистрации в «Личном кабинете» на сайте Продавца <a href="#">https://nanobox.bio/</a>.</div>
            <div>1.3. Акцепт Договора-оферты равносилен заключению договора и означает, что Покупатель согласен со всеми положениями настоящего Договора-оферты, и что Покупатель производит оплату и принимает Товар в соответствии с настоящим Договором-офертой.</div>
            <div>1.4. На основании вышеизложенного внимательно ознакомьтесь с текстом настоящего Договора-оферты, и, если вы не согласны с каким-либо пунктом настоящего Договора-оферты, отказаться от его акцепта.</div>
            <div>1.5. Товары, реализуемые Продавцом в рамках настоящего Договора-оферты, не подлежат дальнейшей перепродаже. Покупатель гарантирует, что товары приобретаются им для собственных нужд. Если иное не оговорено другими правовыми основаниями между Покупателем и Продавцом.</div>

            <div>2. Регистрация Покупателя в Личном кабинете на сайте ООО «Торговый Дом «Градалис»</div>
            <div>2.1. При регистрации в «Личном кабинете» на сайте ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a> Покупатель должен указать следующую регистрационную информацию о себе:</div>
            <div>- Фамилия, имя (русскими буквами);</div>
            <div>- Адрес электронной почты (далее используемый как логин);</div>
            <div>- Пароль к Личному кабинету (только латинские буквы и цифры);</div>
            <div>- Контактный телефон.</div>
            <div>- Адрес места жительства, места регистрации и/или фактического места нахождения.</div>

            <div>2.2. При регистрации в «Личном кабинете» на сайте <a href="#">https://nanobox.bio/</a> Покупатель должен ознакомиться с настоящим Договором-офертой и принять условия данного Договора-оферты путем простановки отметки «Согласен».</div>

            <div>2.3. Покупатель имеет право редактировать регистрационную информацию о себе в «Личном кабинете». Продавец не изменяет, и не редактирует регистрационную информацию о Покупателе. Продавец обязуется не сообщать данные Покупателя, указанные при регистрации на сайте ООО «Торговый Дом «Градалис»
              <a href="#">https://nanobox.bio/</a> и при оформлении Заказа, лицам, не имеющим отношения к исполнению Заказа. После завершения процесса регистрации на сайте ООО «Торговый Дом «Градалис»
              <a href="#">https://nanobox.bio/</a> Покупателю присваиваются уникальные имя пользователя и пароль. После оформления Заказа данные о заказе и Покупателе регистрируются в базе данных Продавца.</div>

            <div>2.4. Продавец не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении Заказа.</div>

            <div>2.5. Покупатель несёт ответственность за достоверность предоставленной информации при оформлении Заказа. В случае предоставления Покупателем недостоверной информации об его контактных данных Продавец не несет ответственности за ненадлежащее исполнение Заказа.</div>

            <div>2.6. Предоставленные Покупателем персональные данные обрабатываются на условиях Политики конфиденциальности (<RouterLink to="/privacy-policy">https://nanobox.bio/privacy-policy</RouterLink>) в целях, связанных с предоставлением Покупателю функциональности сервиса, осуществления обратной связи по вопросам, связанным с использованием сервиса, размещения Заказа Покупателем на сайте ООО «Торговый Дом «Градалис»
              <a href="#">https://nanobox.bio/</a>, исполнения Продавцом размещенного Покупателем Заказа, и в иных целях, указанных в Политике конфиденциальности.</div>
            <div>2.7. Покупатель, предоставляя персональные данные Продавцу, дает согласие на обработку Продавцом на условиях Политики конфиденциальности (<RouterLink to="/privacy-policy">https://nanobox.bio/privacy-policy</RouterLink>) в целях, связанных с предоставлением Покупателю функциональности сервиса, осуществления обратной связи по вопросам, связанным с использованием сервиса, размещения Заказа Покупателем на сайте ООО «Торговый Дом «Градалис»
              <a href="#">https://nanobox.bio/</a>, исполнения Продавцом размещенного Покупателем Заказа,  и в иных целях, указанных в Политике конфиденциальности.</div>
            <div>2.8. Размещая контактные данные, а также инициируя вызов по указанным данным, Покупатель выражает свое согласие на запись ООО «Торговый Дом «Градалис» разговора и номера телефона, с которого/на который совершен вызов. Записи могут быть использованы в целях контроля качества работы сервиса, в целях развития и совершенствования сервиса или его отдельных функций, рассмотрения жалоб Покупателя.</div>
            <div>2.9. В целях осуществления контроля качества, а также в рамках выполнения требований законодательства, ООО «Торговый Дом «Градалис» осуществляет хранение истории взаимодействия между Покупателем и ООО «Торговый Дом «Градалис» посредством соответствующих функции сервиса. Доступ ООО «Торговый Дом «Градалис» и привлекаемых им лиц к такой истории возможен в указанных целях, а также по запросу участников взаимодействия и в случаях, установленных в законе.</div>
            <div>3. Оформление Заказа и сроки исполнения Заказа</div>
            <div>3.1. Заказ Товара осуществляется Покупателем через сайт ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a> в следующем порядке:</div>
            <div>Покупателю для отправки заказа необходимо предварительно пройти авторизацию/регистрацию,</div>
            <div>Необходимо заполнить простую анкету, указав все поля, отмеченные звездочкой.</div>
            <div>После этого будет сформирован «Личный кабинет», в котором Покупатель сможет формировать заказы, просматривать историю заказов.</div>
            <div>Затем выбрать товар в каталоге и добавить его в корзину.</div>
            <div>По завершению выбора товаров, нажать кнопку «Оформить заказ».</div>
            <div>Информация, представленная на сайте ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a>, может быть неполной. В случае возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара, перед оформлением Заказа ему необходимо обратиться за консультацией к Продавцу посредством электронной почты,написав по адресу nanococktel@mail.ru.</div>
            <div>В случае изменения даты доставки или состава заказа, а также отмены заказа или изменения цены товара оператор Продавца информирует Покупателя дополнительно письмом или по телефону.</div>
            <div>3.2. Срок, в который Продавец обязуется исполнить Заказ, составляет от одного рабочего дня. Срок исполнения Заказа зависит от наличия заказанных позиций Товара на складе Продавца и времени, необходимого на обработку Заказа. Продавец не может гарантировать доступность Товара на складе, в связи с чем сроки исполнения Заказа могут быть увеличены Продавцом. В случае отсутствия части Заказа на складе Продавца, в том числе по причинам, не зависящим от последнего, Продавец вправе аннулировать указанный Товар из Заказа Покупателя.</div>
            <div>Принимая и оплачивая Товар, Покупатель подтверждает исполнение Заказа.</div>
            <div>3.3. Доставка товара производится по адресу, указанному Покупателем, в личном кабинете «Адреса доставки» или при оформлении заказа.</div>

            <div>3.3.1. Общие условия доставки Заказа Покупателю Службой доставки. Информация об условиях доставки в зависимости от города и региона обслуживания ООО «Торговый Дом «Градалис» в России  размещена на странице сайта ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a>/delivery.</div>
            <div>3.4. Доставленный товар передается Покупателю по указанному им адресу, а при отсутствии Покупателя - любому лицу, предъявившему информацию о номере Заказа, либо иное (в том числе электронное) подтверждение оформления Заказа (п.20 Постановления Правительства РФ от 31.12.2020 № 2463 «Об утверждении Правил продажи товаров по договору розничной купли-продажи, перечня товаров длительного пользования, на которые не распространяется требование потребителя о безвозмездном предоставлении ему товара, обладающего этими же основными потребительскими свойствами, на период ремонта или замены такого товара, и перечня непродовольственных товаров надлежащего качества, не подлежащих обмену, а также о внесении изменений в некоторые акты Правительства Российской Федерации»).</div>
            <div>В случае если доставка товара произведена в установленные настоящим договором сроки, но товар не был передан Покупателю по его вине, последующая доставка производится в новые сроки, согласованные с Продавцом, на условиях, предусмотренных настоящим договором.</div>
            <div>3.5. В случае ненадлежащего исполнения доставки Заказа по вине Продавца повторная доставка Заказа осуществляется бесплатно.</div>


            <div>4. Оплата Заказа</div>
            <div>4.1. По выбору Покупателя различные способы оплаты Заказа:</div>
            <div>
              - банковской картой при получении Заказа в пункте самовывоза.</div>
            <div>
              - онлайн-оплата во время оформления Заказа на сайте ООО «Торговый Дом «Градалис» банковской картой, электронными деньгами или интернет-банкингом.</div>
            <div>
              - оплата Заказа с помощью баллов.</div>
            <div>Более подробная информация о способах оплаты Заказа размещена по адресу: <RouterLink to="/return-procedure">https://nanobox.bio/return-procedure</RouterLink>.</div>
            <div>4.2. Подтверждением оплаты исполненного Заказа является кассовый чек при получении товара, кассовый чек в электронном виде на электронный адрес или по телефону Покупателя при осуществлении Покупателем онлайн-оплаты Заказа.</div>
            <div>В соответствии с Федеральным законом от 22.05.2003 № 54-ФЗ «О применении контрольно-кассовой техники при осуществлении расчетов в Российской Федерации» при осуществлении Покупателем онлайн-оплаты Заказа кассовый чек направляется в электронном виде на электронный адрес или по телефону Покупателя.</div>
            <div>4.3. Цена на каждую позицию Товара отображена на сайте ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a>.</div>
            <div>4.4. Цены на любые позиции Товара, указанные на сайте ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a> могут быть изменены Продавцом в одностороннем порядке без уведомления Покупателя. В случае изменения цены на заказанные позиции Товара Продавец обязуется в кратчайшие сроки проинформировать Покупателя о таком изменении. Покупатель вправе подтвердить либо аннулировать Заказ. В случае отсутствия связи с Покупателем Заказ считается аннулированным в течение 5 календарных дней с момента оформления.</div>
            <div>4.5. Договор розничной купли-продажи товара считается заключенным с момента выдачи Продавцом Покупателю кассового или товарного чека либо иного документа, подтверждающего оплату товара.</div>

            <h2>Возврат Заказа</h2>
            <div>5.1. В соответствии с п. 3 ст. 26.1. Закона РФ № 2300-1 «О Защите прав потребителей» - Покупателю в момент доставки товара должна быть в письменной форме предоставлена информация о товаре, предусмотренная статьей 10 Закона «О Защите прав потребителей», а также предусмотренная пунктом 4 ст. 26.1. Закона «О Защите прав потребителей» информация о порядке и сроках возврата товара. Данная обязанность Продавца, предусмотренная пунктом 3 статьи 26.1 Закона «О защите прав потребителей», признается исполненной также в случае предоставления Покупателю информации с помощью электронных и иных технических средств (абз.2 п.18 Постановления Правительства РФ от 31.12.2020 № 2463).</div>

            <div>Порядок и сроки возврата товара (п. 4 ст. 26.1. Закона РФ № 2300-1 «О Защите прав потребителей»):</div>

            <div>- Покупатель вправе отказаться от товара в любое время до его передачи, а после передачи товара - в течение семи дней.</div>

            <div>- В случае, если информация о порядке и сроках возврата товара надлежащего качества не была предоставлена в письменной форме в момент доставки товара (или в электронном виде с помощь электронных и иных технических средств), потребитель вправе отказаться от товара в течение трех месяцев с момента передачи товара.</div>

            <div>Информация о порядке и сроках возврата Товара надлежащего качества предоставляется Покупателю  в письменной форме в п.п.5.1, 5.2 Договора оферты, а также в момент доставки Товара  в Товарном чеке.</div>

            <div>5.2. В соответствии с п. 4 ст. 26.1. Закона РФ № 2300-1 «О Защите прав потребителей» Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара. Отсутствие у потребителя документа, подтверждающего факт и условия покупки товара, не лишает его возможности ссылаться на другие доказательства приобретения товара у данного продавца.</div>

            <div>В соответствии с п. 4 ст. 26.1. Закона РФ № 2300-1 «О Защите прав потребителей»</div>

            <div>- Покупатель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его потребителем.</div>

            <div>При отказе Покупателя от Товара надлежащего качества Продавец должен возвратить ему денежную сумму, уплаченную Покупателем по договору, за исключением расходов Продавца на доставку от Покупателя возвращенного Товара, не позднее чем через 10 дней с даты предъявления Покупателем соответствующего требования.</div>

            <div>Подробная информация об обмене и возврате товара надлежащего качества размещена на  странице «Гарантии и возврат товара» сайта ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a> в п.1 «Обмен и возврат товара надлежащего качества» Раздела «Обмен и возврат товара».</div>

            <div>5.3. Последствия продажи товара ненадлежащего качества дистанционным способом продажи товара установлены положениями, предусмотренными статьями 18 – 24 Закона РФ № 2300-1 «О Защите прав потребителей».</div>

            <div>Подробная информация об обмене и возврате товара ненадлежащего качества размещена на  странице «Гарантии и возврат товара» сайта ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a> в п.2 «Обмен и возврат товара ненадлежащего качества» Раздела «Обмен и возврат товара».</div>

            <div>В соответствии со ст. 22 Закона РФ № 2300-1 «О Защите прав потребителей», уплаченная Покупателем сумма за Товар ненадлежащего качества подлежит возврату Покупателю в течение 10 календарных дней с момента предъявления соответствующего требования.</div>

            <div>5.4. В зависимости от формы оплаты возвращаемого Товара установлен порядок возврата денежных средств на  странице «Гарантии и возврат товара» сайта ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a>/ в Разделе «Возврат денежных средств при отказе Покупателя от Товар».</div>

            <div>Возврат наличных денежных средств осуществляется через розничную сеть в ближайшем магазине, указанном потребителем.</div>


            <div>6. Авторские права</div>
            <div>6.1. Вся текстовая информация и графические изображения, размещенные на сайте ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a> являются собственностью Продавца и/или его поставщиков и производителей Товара.</div>


            <div>7. Права, обязанности и ответственность сторон</div>
            <div>7.1. Продавец не несет ответственности за ненадлежащее использование товаров Покупателем, заказанных на сайте ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a>.</div>

            <div>7.2. Продавец вправе передавать свои права и обязанности по исполнению Заказов третьим лицам.</div>

            <div>7.3. Покупатель обязуется не использовать Товар, заказанный на сайте ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a>, в предпринимательских целях.</div>

            <div>7.4. Право собственности на Заказ, а также риск его случайной утраты или повреждения переходят к Покупателю с момента получения Товара и кассового чека.</div>

            <div>7.5. Все претензии по ненадлежащему исполнению заказа Покупатель вправе направить на адрес электронной почты nanococktel@mail.ru.</div>

            <div>8. Порядок изменения и расторжения настоящего публичного Договора-оферты</div>
            <div>8.1. Продавец оставляет за собой право в любое время вносить изменения в условия настоящего публичного Договора-оферты, если время вступления изменений в силу специально не оговорено, они начинают свое действие с момента опубликования их на сайте ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a>.</div>
            <div>8.2. Использование Покупателем сайта ООО «Торговый Дом «Градалис» <a href="#">https://nanobox.bio/</a> после внесения изменений в Договор-оферту однозначно понимается Продавцом и Покупателем как согласие с внесенными изменениями.</div>
            <div>8.3. Настоящий Договор-оферта может быть расторгнут в любое время по инициативе Покупателя или Продавца путем направления другой стороне соответствующего уведомления письмом по почте.</div>
            <div>8.4. Продавец вправе расторгнуть настоящий Договор-оферта в любое время без предварительного уведомления в случае нарушений Покупателем порядка оформления заказа, получения и оплаты Товара.</div>
            <div>9. Срок действия Договора-оферты</div>
            <div>Договор вступает в силу с момента его заключения (акцепта оферты) и действует в течение года. Если ни одна из сторон за 1 месяц до окончания срока действия Договора-оферты не обратится с предложением о его расторжении, настоящий Договор-оферта считается пролонгированным на следующий год. Указанный порядок расторжения и пролонгации Договора-оферты применяется на каждый последующий год.</div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "OfferAgreement"
}
</script>

<style scoped lang="scss">
.offer-agreement {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    h2 {
      margin-top: 10px;
    }

    &_mt-20 {
      margin-top: 20px;
    }

    & > div {
      font-size: 17px;
      line-height: 24px;

      & > p {
        font-size: 16px;
      }

      & a {
        font-size: 16px;
        line-height: 24px;
        color: #FF6100;
        text-decoration: underline;
      }
    }
  }
}

main {
  margin: 60px 0 80px;
}
</style>