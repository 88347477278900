<template>
  <main>
    <div class="return-procedure">
      <div class="container">
        <div class="return-procedure__body">
          <h1>Порядок возврата</h1>

          <div class="return-procedure__content return-procedure__content_mt-20">
            <div>Условия возврата</div>
            <div>Возврат денежных средств производится согласно условиям договора.</div>
            <div>Заявление необходимо написать на имя генерального директора ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ТОРГОВЫЙ ДОМ ГРАДАЛИС»</div>
            <div>Урлагова Александра Андреевича, подписать собственноручно и прислать по почте nanococktel@mail.ru.</div>
            <div>В заявлении на возврат в обязательном порядке укажите:</div>
            <div>- ФИО</div>
            <div>- Паспортные данные (серия номер, кем и когда выдан, дата рождения)</div>
            <div>- Адрес</div>
            <div>- Логин аккаунта (если есть)</div>
            <div>- Сумма возврата.</div>
            <div>- Причина возврата</div>
            <div>- Ваш ИНН</div>
            <div>- Реквизиты вашего банка (наименование, адрес, БИК)</div>
            <div>- Номер карты с которой производилась оплата товара.</div>
            <div>- Номер расчётного счёта в указанном банке.</div>
            <div>- Контактный номер телефона и Email.</div>
            <div>Все реквизиты банка и расчётного счёта можно найти в мобильным приложении, личном кабинете вашего банка или получить при личном посещении финансового учреждения.</div>
            <div>Основанием в отказе возврата средств могут являться следующие причины:</div>
            <div>- Недоступный или несуществующий контактный номер</div>
            <div>- Неверные реквизиты или логин</div>
            <div>- Возврат несуществующих денежных средств</div>
            <div>- Неверно заполненное заявление на возврат</div>
            <div>- Заявление в котором указаны не все требуемые параметры.</div>
            <div>- Заявление заполнено/подано третьим лицом без должной формы представительства.</div>
            <div>- Заявление нечитабельно.</div>
            <div>Срок рассмотрения заявления составляет от 14 до 30 дней.</div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "ReturnProcedure"
}
</script>

<style scoped lang="scss">
.return-procedure {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    &_mt-20 {
      margin-top: 20px;
    }

    & > div {
      font-size: 17px;
      line-height: 24px;

      & > p {
        font-size: 16px;
      }

      & a {
        font-size: 16px;
        line-height: 24px;
        color: #FF6100;
        text-decoration: underline;
      }
    }
  }
}

main {
  margin: 60px 0 80px;
}
</style>