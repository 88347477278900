<template>
  <div class="web-banners">
    <div class="web-banners__title">Веб-баннера</div>

    <div class="web-banners__content web-banners__content_mt-16">
      <Preloader v-if="!banners.length"/>
      <div
          class="web-banners__banner"
          v-for="banner in banners"
          :key="banner.id"
          v-if="banners.length"
      >
        <div class="web-banners__title">Баннер {{ banner.id }}</div>
        <img
            class="web-banners__banner-content"
            alt="banner"
            :src="banner.image"
            :style="`max-width: ${banner.width}px; height: ${banner.height}px;`"
            v-if="banner.image"
        >
        <div
            class="web-banners__banner-content web-banners__banner-content_2"
            :style="`max-width: ${banner.width}px; height: ${banner.height}px;`"
            v-else
        />
        <Input
            width="100%"
            title="HTML-код баннера"
            title-weight="700"
            padding="10px 39px 10px 12px"
            :default-value="getBannerHTMLCode(banner)"
            :readonly="true"
            :can-copy="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Utils/Input.vue";
import {mapActions, mapState} from "vuex";
import Preloader from "@/components/Utils/Preloader.vue";

export default {
  name: "WebBanners",
  components: {Preloader, Input},
  data() {
    return {
      // banners: [
      //   {
      //     id: 1,
      //     title: 'Баннер 728-90',
      //   },
      //   {
      //     id: 2,
      //     title: 'Баннер 300-250',
      //   },
      //   {
      //     id: 3,
      //     title: 'Баннер 469-60',
      //   },
      //   {
      //     id: 4,
      //     title: 'Баннер 269-31',
      //   }
      // ]
    }
  },
  methods: {
    ...mapActions('banner', ['getBanners']),

    getBannerHTMLCode(banner) {
      return `<a href=${this.getReferralLink} target="_blank"><img src=${banner.image} alt="nanobox banner" style="width: 100%; max-width: ${banner.width}px; height: ${banner.height}px;"></a>`
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('banner', ['banners']),

    getReferralLink() {
      return `${window.location.origin}/?referral_token=${this.user.referal_token}`
    }
  },
  mounted() {
    this.getBanners()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/Views/promo-materials/_webBanners.scss";
</style>