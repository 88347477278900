import { pay } from "@/api";

export default {
  namespaced: true,
  state: {

  },
  actions: {
    pay({ commit }, { orderId, data }) {
      return pay(orderId, data).then(response => {
        return response
      })
    }
  },
  mutations: {

  }
}