<template>
  <div class="income-chart">
    <div class="income-chart__title">
      <div class="income-chart__time">
        Продажи в моей структуре
        <ChartSelectPeriod @select-period="period => getGraph(period)"/>
      </div>
      <div class="income-chart__income">
        Доход:
        <div class="income-chart__income-value">{{ getIncome[0] }}<span>.{{ getIncome[1] }} ₽</span></div>
      </div>
    </div>
    <div class="income-chart__diagram" :class="{ 'income-chart__diagram_center': !graph?.graph }">
      <Line ref="chartjs" :data="getChartData" :options="chartOptions" v-if="graph?.graph"/>
      <Preloader v-else/>
    </div>
  </div>
</template>

<script>
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'

import {Line} from 'vue-chartjs';
import {chartData, chartOptions} from "@/configs/chartConfig";
import ChartSelectPeriod from "@/components/Views/Cabinet/IncomeChart/ChartSelectPeriod.vue";
import {mapActions, mapState} from "vuex";
import Preloader from "@/components/Utils/Preloader.vue";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
)

export default {
  name: "IncomeChart",
  components: {
    Preloader,
    ChartSelectPeriod,
    Line
  },
  data() {
    return {
      chartData,
      chartOptions,
    }
  },
  computed: {
    ...mapState('balance', ['graph']),

    getChartData() {
      const graphData = this.graph?.graph
      const labels = graphData?.map(item => item.day)
      chartData.datasets[0].data = graphData?.map(item => item.income)

      return {
        ...chartData,
        labels,
      }
    },
    getIncome() {
      return this.getPrice(this.graph.total).split('.')
    }
  },
  methods: {
    ...mapActions('balance', ['getGraph']),
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/Views/cabinet/incomeChart";
</style>