<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="267" height="251" viewBox="0 0 267 251" fill="none">
    <path d="M0.663924 190.681V63.4596L107.662 0.552802L218.875 62.8995V189.273L107.098 250.497L0.663924 190.681Z" fill="white" stroke="#ADE3E3" stroke-width="0.355193"/>
    <circle cx="172.811" cy="83.4631" r="4.36158" fill="url(#paint0_linear_234_4386)"/>
    <circle cx="154.125" cy="74.74" r="4.36158" fill="url(#paint1_linear_234_4386)"/>
    <circle cx="162.848" cy="51.6858" r="4.36158" fill="url(#paint2_linear_234_4386)"/>
    <circle cx="187.768" cy="61.6552" r="4.36158" fill="url(#paint3_linear_234_4386)"/>
    <circle cx="222.66" cy="62.9014" r="4.36158" fill="url(#paint4_linear_234_4386)"/>
    <circle cx="130.444" cy="64.1475" r="4.36158" fill="url(#paint5_linear_234_4386)"/>
    <circle cx="119.854" cy="55.4243" r="4.36158" fill="url(#paint6_linear_234_4386)"/>
    <circle cx="146.641" cy="59.786" r="4.36158" fill="url(#paint7_linear_234_4386)"/>
    <circle cx="190.885" cy="49.1936" r="4.36158" fill="url(#paint8_linear_234_4386)"/>
    <circle cx="210.819" cy="77.2324" r="4.36158" fill="url(#paint9_linear_234_4386)"/>
    <circle cx="181.541" cy="114.617" r="4.36158" fill="url(#paint10_linear_234_4386)"/>
    <circle cx="200.858" cy="110.256" r="4.36158" fill="url(#paint11_linear_234_4386)"/>
    <circle cx="188.397" cy="161.349" r="4.36158" fill="url(#paint12_linear_234_4386)"/>
    <circle cx="177.176" cy="147.018" r="4.36158" fill="url(#paint13_linear_234_4386)"/>
    <circle cx="175.928" cy="161.349" r="4.36158" fill="url(#paint14_linear_234_4386)"/>
    <circle cx="160.352" cy="173.81" r="4.36158" fill="url(#paint15_linear_234_4386)"/>
    <circle cx="167.834" cy="166.956" r="4.36158" fill="url(#paint16_linear_234_4386)"/>
    <circle cx="157.233" cy="198.734" r="4.36158" fill="url(#paint17_linear_234_4386)"/>
    <circle cx="178.424" cy="190.633" r="4.36158" fill="url(#paint18_linear_234_4386)"/>
    <circle cx="156.614" cy="185.026" r="4.36158" fill="url(#paint19_linear_234_4386)"/>
    <circle cx="167.834" cy="187.518" r="4.36158" fill="url(#paint20_linear_234_4386)"/>
    <circle cx="158.483" cy="218.672" r="4.36158" fill="url(#paint21_linear_234_4386)"/>
    <circle cx="141.037" cy="224.28" r="4.36158" fill="url(#paint22_linear_234_4386)"/>
    <circle cx="147.27" cy="191.256" r="4.36158" fill="url(#paint23_linear_234_4386)"/>
    <circle cx="147.891" cy="213.064" r="4.36158" fill="url(#paint24_linear_234_4386)"/>
    <circle cx="136.68" cy="207.457" r="4.36158" fill="url(#paint25_linear_234_4386)"/>
    <circle cx="117.985" cy="198.11" r="4.36158" fill="url(#paint26_linear_234_4386)"/>
    <circle cx="124.209" cy="208.08" r="4.36158" fill="url(#paint27_linear_234_4386)"/>
    <circle cx="243.85" cy="122.094" r="4.36158" fill="url(#paint28_linear_234_4386)"/>
    <circle cx="154.121" cy="40.4705" r="3.7385" fill="url(#paint29_linear_234_4386)"/>
    <circle cx="127.332" cy="41.7166" r="3.7385" fill="url(#paint30_linear_234_4386)"/>
    <circle cx="168.451" cy="64.1476" r="3.7385" fill="url(#paint31_linear_234_4386)"/>
    <circle cx="139.791" cy="73.4936" r="3.7385" fill="url(#paint32_linear_234_4386)"/>
    <circle cx="189.012" cy="94.6786" r="3.7385" fill="url(#paint33_linear_234_4386)"/>
    <circle cx="134.807" cy="51.0627" r="3.7385" fill="url(#paint34_linear_234_4386)"/>
    <circle cx="208.328" cy="100.286" r="3.7385" fill="url(#paint35_linear_234_4386)"/>
    <circle cx="218.301" cy="85.9555" r="2.49233" fill="url(#paint36_linear_234_4386)"/>
    <circle cx="230.448" cy="91.2516" r="3.42696" fill="url(#paint37_linear_234_4386)"/>
    <circle cx="242.288" cy="139.852" r="3.42696" fill="url(#paint38_linear_234_4386)"/>
    <circle cx="214.87" cy="114.929" r="3.42696" fill="url(#paint39_linear_234_4386)"/>
    <circle cx="199.294" cy="90.0054" r="3.42696" fill="url(#paint40_linear_234_4386)"/>
    <circle cx="256.926" cy="112.125" r="1.86925" fill="url(#paint41_linear_234_4386)"/>
    <circle cx="257.555" cy="119.602" r="1.86925" fill="url(#paint42_linear_234_4386)"/>
    <circle cx="261.291" cy="133.31" r="1.86925" fill="url(#paint43_linear_234_4386)"/>
    <circle cx="232.008" cy="122.717" r="1.86925" fill="url(#paint44_linear_234_4386)"/>
    <circle cx="214.561" cy="122.717" r="1.86925" fill="url(#paint45_linear_234_4386)"/>
    <circle cx="209.574" cy="143.902" r="1.86925" fill="url(#paint46_linear_234_4386)"/>
    <circle cx="168.447" cy="199.98" r="1.86925" fill="url(#paint47_linear_234_4386)"/>
    <circle cx="173.436" cy="219.295" r="1.86925" fill="url(#paint48_linear_234_4386)"/>
    <circle cx="173.436" cy="234.249" r="1.86925" fill="url(#paint49_linear_234_4386)"/>
    <circle cx="116.733" cy="218.672" r="1.86925" fill="url(#paint50_linear_234_4386)"/>
    <circle cx="112.375" cy="209.326" r="1.86925" fill="url(#paint51_linear_234_4386)"/>
    <circle cx="182.158" cy="229.265" r="1.86925" fill="url(#paint52_linear_234_4386)"/>
    <circle cx="191.512" cy="218.049" r="1.86925" fill="url(#paint53_linear_234_4386)"/>
    <circle cx="124.215" cy="221.787" r="1.86925" fill="url(#paint54_linear_234_4386)"/>
    <circle cx="222.664" cy="119.602" r="1.86925" fill="url(#paint55_linear_234_4386)"/>
    <circle cx="225.152" cy="112.748" r="1.86925" fill="url(#paint56_linear_234_4386)"/>
    <circle cx="256.307" cy="100.909" r="1.86925" fill="url(#paint57_linear_234_4386)"/>
    <circle cx="256.307" cy="74.117" r="1.86925" fill="url(#paint58_linear_234_4386)"/>
    <circle cx="264.41" cy="79.7246" r="1.86925" fill="url(#paint59_linear_234_4386)"/>
    <circle cx="239.481" cy="73.4936" r="1.86925" fill="url(#paint60_linear_234_4386)"/>
    <circle cx="232.635" cy="75.9862" r="1.86925" fill="url(#paint61_linear_234_4386)"/>
    <circle cx="199.602" cy="71.6244" r="1.86925" fill="url(#paint62_linear_234_4386)"/>
    <circle cx="176.553" cy="52.3089" r="1.86925" fill="url(#paint63_linear_234_4386)"/>
    <circle cx="175.313" cy="41.7166" r="1.86925" fill="url(#paint64_linear_234_4386)"/>
    <circle cx="178.42" cy="32.9934" r="1.86925" fill="url(#paint65_linear_234_4386)"/>
    <circle cx="188.393" cy="36.7318" r="1.86925" fill="url(#paint66_linear_234_4386)"/>
    <circle cx="197.736" cy="35.4857" r="1.86925" fill="url(#paint67_linear_234_4386)"/>
    <circle cx="203.969" cy="40.4705" r="1.86925" fill="url(#paint68_linear_234_4386)"/>
    <circle cx="199.608" cy="44.2089" r="2.49233" fill="url(#paint69_linear_234_4386)"/>
    <circle cx="208.638" cy="65.7053" r="2.18079" fill="url(#paint70_linear_234_4386)"/>
    <circle cx="243.221" cy="91.5634" r="2.49233" fill="url(#paint71_linear_234_4386)"/>
    <circle cx="190.264" cy="82.2169" r="2.49233" fill="url(#paint72_linear_234_4386)"/>
    <circle cx="171.565" cy="94.6785" r="3.11541" fill="url(#paint73_linear_234_4386)"/>
    <circle cx="171.565" cy="106.517" r="3.11541" fill="url(#paint74_linear_234_4386)"/>
    <circle cx="188.389" cy="106.517" r="3.11541" fill="url(#paint75_linear_234_4386)"/>
    <circle cx="195.244" cy="127.702" r="3.11541" fill="url(#paint76_linear_234_4386)"/>
    <circle cx="177.799" cy="125.21" r="3.11541" fill="url(#paint77_linear_234_4386)"/>
    <circle cx="170.324" cy="125.21" r="3.11541" fill="url(#paint78_linear_234_4386)"/>
    <circle cx="169.694" cy="114.617" r="3.11541" fill="url(#paint79_linear_234_4386)"/>
    <circle cx="162.84" cy="89.6938" r="3.11541" fill="url(#paint80_linear_234_4386)"/>
    <circle cx="187.151" cy="124.586" r="3.11541" fill="url(#paint81_linear_234_4386)"/>
    <circle cx="180.912" cy="89.0708" r="2.49233" fill="url(#paint82_linear_234_4386)"/>
    <circle cx="224.533" cy="101.533" r="3.7385" fill="url(#paint83_linear_234_4386)"/>
    <circle cx="218.299" cy="138.918" r="3.7385" fill="url(#paint84_linear_234_4386)"/>
    <circle cx="174.065" cy="136.425" r="3.7385" fill="url(#paint85_linear_234_4386)"/>
    <circle cx="207.088" cy="125.833" r="3.7385" fill="url(#paint86_linear_234_4386)"/>
    <circle cx="186.526" cy="141.41" r="3.7385" fill="url(#paint87_linear_234_4386)"/>
    <circle cx="198.983" cy="168.202" r="3.7385" fill="url(#paint88_linear_234_4386)"/>
    <circle cx="192.129" cy="179.418" r="3.7385" fill="url(#paint89_linear_234_4386)"/>
    <circle cx="198.983" cy="145.148" r="3.7385" fill="url(#paint90_linear_234_4386)"/>
    <circle cx="174.065" cy="178.172" r="3.7385" fill="url(#paint91_linear_234_4386)"/>
    <circle cx="193.998" cy="203.095" r="3.7385" fill="url(#paint92_linear_234_4386)"/>
    <circle cx="173.749" cy="207.768" r="2.80387" fill="url(#paint93_linear_234_4386)"/>
    <circle cx="146.333" cy="200.914" r="2.80387" fill="url(#paint94_linear_234_4386)"/>
    <circle cx="137.611" cy="195.306" r="2.80387" fill="url(#paint95_linear_234_4386)"/>
    <circle cx="129.197" cy="198.11" r="3.11541" fill="url(#paint96_linear_234_4386)"/>
    <circle cx="131.695" cy="216.803" r="3.11541" fill="url(#paint97_linear_234_4386)"/>
    <circle cx="124.84" cy="234.249" r="3.11541" fill="url(#paint98_linear_234_4386)"/>
    <circle cx="131.384" cy="225.837" r="2.80387" fill="url(#paint99_linear_234_4386)"/>
    <circle cx="180.289" cy="201.226" r="3.7385" fill="url(#paint100_linear_234_4386)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1721 112.41C28.4278 112.267 28.739 112.268 28.9944 112.41L55.1548 127.038C55.4205 127.186 55.5856 127.466 55.5869 127.771C55.5881 128.075 55.4253 128.357 55.1608 128.507L29.0004 143.416C28.7503 143.559 28.4447 143.564 28.1899 143.43L0.904291 129.084C0.630061 128.94 0.456891 128.657 0.453186 128.347C0.44948 128.037 0.615835 127.75 0.886538 127.6L28.1721 112.41ZM3.07004 128.316L28.5663 141.721L53.0264 127.781L28.5817 114.113L3.07004 128.316Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3029 141.839C28.7689 141.839 29.1468 142.217 29.1468 142.683V173.968L137.005 232.8C137.415 233.024 137.565 233.536 137.342 233.945C137.119 234.354 136.606 234.505 136.197 234.282L27.8988 175.21C27.6277 175.062 27.459 174.778 27.459 174.469V142.683C27.459 142.217 27.8368 141.839 28.3029 141.839Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M176.971 164.734C177.229 164.587 177.546 164.587 177.804 164.734L218.873 188.082C219.278 188.312 219.42 188.827 219.189 189.232C218.959 189.637 218.444 189.779 218.039 189.549L177.386 166.438L68.098 228.368C67.6925 228.598 67.1775 228.455 66.9477 228.05C66.7179 227.644 66.8604 227.129 67.2659 226.9L176.971 164.734Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M177.387 39.4478C177.853 39.4478 178.231 39.8256 178.231 40.2916V165.468C178.231 165.934 177.853 166.312 177.387 166.312C176.921 166.312 176.543 165.934 176.543 165.468V40.2916C176.543 39.8256 176.921 39.4478 177.387 39.4478Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M38.2483 41.2964C38.4701 40.8865 38.9822 40.734 39.3921 40.9558L97.6201 72.4608C98.03 72.6826 98.1825 73.1947 97.9607 73.6046C97.7389 74.0145 97.2268 74.167 96.8169 73.9452L38.5889 42.4402C38.179 42.2184 38.0265 41.7063 38.2483 41.2964Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.558641 63.512C0.784149 63.1041 1.29761 62.9563 1.70548 63.1818L60.2148 95.5307C60.6227 95.7562 60.7705 96.2697 60.545 96.6775C60.3195 97.0854 59.806 97.2332 59.3981 97.0077L0.888846 64.6589C0.480971 64.4333 0.333133 63.9199 0.558641 63.512Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M97.6746 40.1599C97.9028 40.5662 97.7583 41.0807 97.3519 41.3088L29.1468 79.6044V113.147C29.1468 113.613 28.7689 113.991 28.3029 113.991C27.8368 113.991 27.459 113.613 27.459 113.147V79.1104C27.459 78.8053 27.6237 78.5239 27.8897 78.3745L96.5256 39.8372C96.932 39.609 97.4464 39.7535 97.6746 40.1599Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M147.731 22.9876C147.967 23.3897 147.832 23.9067 147.43 24.1422L108.048 47.2084C107.79 47.3594 107.472 47.3628 107.211 47.2174L67.8298 25.2764C67.4227 25.0496 67.2765 24.5357 67.5033 24.1285C67.7302 23.7214 68.2441 23.5752 68.6512 23.802L107.611 45.5084L146.577 22.6859C146.979 22.4503 147.496 22.5854 147.731 22.9876Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M68.2467 23.6952C68.7128 23.6965 69.0895 24.0753 69.0882 24.5414L68.5257 227.636C68.5244 228.102 68.1455 228.479 67.6794 228.478C67.2134 228.476 66.8366 228.098 66.8379 227.631L67.4005 24.5367C67.4018 24.0706 67.7806 23.6939 68.2467 23.6952Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M107.628 0.629034C108.094 0.627314 108.473 1.00374 108.475 1.4698L108.756 77.7006C108.758 78.1667 108.381 78.5459 107.915 78.5476C107.449 78.5494 107.07 78.1729 107.068 77.7069L106.787 1.47603C106.785 1.00997 107.162 0.630754 107.628 0.629034Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M147.007 23.1327C147.473 23.1309 147.852 23.5074 147.854 23.9734L148.135 100.204C148.137 100.67 147.76 101.05 147.294 101.051C146.828 101.053 146.449 100.677 146.447 100.211L146.166 23.9797C146.164 23.5136 146.541 23.1344 147.007 23.1327Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M104.142 132.141C104.371 132.547 104.228 133.062 103.822 133.291L1.71213 190.957C1.30631 191.186 0.791535 191.043 0.562353 190.637C0.33317 190.231 0.476363 189.716 0.882185 189.487L102.992 131.822C103.398 131.592 103.913 131.736 104.142 132.141Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M141.269 152.957C141.498 153.363 141.355 153.878 140.949 154.107L38.8391 211.772C38.4333 212.001 37.9185 211.858 37.6893 211.452C37.4601 211.047 37.6033 210.532 38.0091 210.303L140.119 152.637C140.525 152.408 141.04 152.551 141.269 152.957Z" fill="#ADE3E3"/>
    <g filter="url(#filter0_f_234_4386)">
      <path d="M125.257 66.7333L61.8086 105.571V180.057L124.924 216.382L191.204 175.865V107.92L125.257 66.7333Z" fill="#24C8BC"/>
    </g>
    <g filter="url(#filter1_dd_234_4386)">
      <path d="M108.576 67.015L55.0586 99.5743V162.019L108.295 192.472L164.201 158.505V101.544L108.576 67.015Z" fill="url(#paint101_linear_234_4386)"/>
    </g>
    <path d="M108.505 128.618V192.472L55.0586 162.655V99.6448L108.505 128.618Z" fill="url(#paint102_linear_234_4386)"/>
    <path d="M163.915 100.77L164.478 158.717L107.938 192.191V128.9L163.915 100.77Z" fill="url(#paint103_linear_234_4386)"/>
    <defs>
      <filter id="filter0_f_234_4386" x="49.4952" y="54.42" width="154.023" height="174.275" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="6.15667" result="effect1_foregroundBlur_234_4386"/>
      </filter>
      <filter id="filter1_dd_234_4386" x="51.0331" y="65.4758" width="118.259" height="133.508" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1.77596" dy="2.36795"/>
        <feGaussianBlur stdDeviation="1.65757"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.631373 0 0 0 0 0.631373 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_234_4386"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2.48635"/>
        <feGaussianBlur stdDeviation="2.01276"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_234_4386" result="effect2_dropShadow_234_4386"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_234_4386" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_234_4386" x1="170.318" y1="79.1015" x2="175.615" y2="86.89" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint1_linear_234_4386" x1="151.633" y1="70.3784" x2="156.929" y2="78.167" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint2_linear_234_4386" x1="160.356" y1="47.3242" x2="165.652" y2="55.1128" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint3_linear_234_4386" x1="185.275" y1="57.2936" x2="190.572" y2="65.0822" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint4_linear_234_4386" x1="220.168" y1="58.5398" x2="225.464" y2="66.3283" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint5_linear_234_4386" x1="127.951" y1="59.7859" x2="133.247" y2="67.5745" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint6_linear_234_4386" x1="117.361" y1="51.0627" x2="122.658" y2="58.8512" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint7_linear_234_4386" x1="144.149" y1="55.4244" x2="149.445" y2="63.2129" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint8_linear_234_4386" x1="188.393" y1="44.832" x2="193.689" y2="52.6206" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint9_linear_234_4386" x1="208.326" y1="72.8708" x2="213.622" y2="80.6593" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint10_linear_234_4386" x1="179.049" y1="110.256" x2="184.345" y2="118.044" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint11_linear_234_4386" x1="198.365" y1="105.894" x2="203.662" y2="113.683" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint12_linear_234_4386" x1="185.904" y1="156.987" x2="191.201" y2="164.776" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint13_linear_234_4386" x1="174.684" y1="142.656" x2="179.98" y2="150.445" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint14_linear_234_4386" x1="173.436" y1="156.987" x2="178.732" y2="164.776" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint15_linear_234_4386" x1="157.859" y1="169.449" x2="163.156" y2="177.237" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint16_linear_234_4386" x1="165.342" y1="162.594" x2="170.638" y2="170.383" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint17_linear_234_4386" x1="154.74" y1="194.372" x2="160.037" y2="202.161" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint18_linear_234_4386" x1="175.932" y1="186.272" x2="181.228" y2="194.06" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint19_linear_234_4386" x1="154.121" y1="180.664" x2="159.417" y2="188.453" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint20_linear_234_4386" x1="165.342" y1="183.156" x2="170.638" y2="190.945" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint21_linear_234_4386" x1="155.99" y1="214.31" x2="161.287" y2="222.099" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint22_linear_234_4386" x1="138.545" y1="219.918" x2="143.841" y2="227.707" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint23_linear_234_4386" x1="144.777" y1="186.895" x2="150.074" y2="194.683" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint24_linear_234_4386" x1="145.399" y1="208.703" x2="150.695" y2="216.491" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint25_linear_234_4386" x1="134.188" y1="203.095" x2="139.484" y2="210.884" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint26_linear_234_4386" x1="115.492" y1="193.749" x2="120.789" y2="201.537" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint27_linear_234_4386" x1="121.717" y1="203.718" x2="127.013" y2="211.507" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint28_linear_234_4386" x1="241.358" y1="117.733" x2="246.654" y2="125.521" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint29_linear_234_4386" x1="151.985" y1="36.732" x2="156.525" y2="43.4079" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint30_linear_234_4386" x1="125.196" y1="37.9781" x2="129.736" y2="44.654" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint31_linear_234_4386" x1="166.315" y1="60.4091" x2="170.855" y2="67.0849" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint32_linear_234_4386" x1="137.655" y1="69.7551" x2="142.195" y2="76.431" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint33_linear_234_4386" x1="186.876" y1="90.9401" x2="191.415" y2="97.616" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint34_linear_234_4386" x1="132.671" y1="47.3242" x2="137.21" y2="54.0001" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint35_linear_234_4386" x1="206.192" y1="96.5477" x2="210.732" y2="103.224" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint36_linear_234_4386" x1="216.877" y1="83.4631" x2="219.903" y2="87.9137" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint37_linear_234_4386" x1="228.49" y1="87.8246" x2="232.651" y2="93.9442" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint38_linear_234_4386" x1="240.33" y1="136.425" x2="244.491" y2="142.545" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint39_linear_234_4386" x1="212.912" y1="111.502" x2="217.073" y2="117.622" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint40_linear_234_4386" x1="197.336" y1="86.5784" x2="201.497" y2="92.698" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint41_linear_234_4386" x1="255.858" y1="110.256" x2="258.128" y2="113.594" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint42_linear_234_4386" x1="256.487" y1="117.733" x2="258.756" y2="121.071" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint43_linear_234_4386" x1="260.223" y1="131.441" x2="262.493" y2="134.778" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint44_linear_234_4386" x1="230.94" y1="120.848" x2="233.21" y2="124.186" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint45_linear_234_4386" x1="213.493" y1="120.848" x2="215.762" y2="124.186" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint46_linear_234_4386" x1="208.506" y1="142.033" x2="210.776" y2="145.371" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint47_linear_234_4386" x1="167.379" y1="198.11" x2="169.649" y2="201.448" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint48_linear_234_4386" x1="172.368" y1="217.426" x2="174.637" y2="220.764" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint49_linear_234_4386" x1="172.368" y1="232.38" x2="174.637" y2="235.718" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint50_linear_234_4386" x1="115.664" y1="216.803" x2="117.934" y2="220.141" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint51_linear_234_4386" x1="111.307" y1="207.457" x2="113.577" y2="210.795" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint52_linear_234_4386" x1="181.09" y1="227.395" x2="183.36" y2="230.733" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint53_linear_234_4386" x1="190.444" y1="216.18" x2="192.713" y2="219.518" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint54_linear_234_4386" x1="123.147" y1="219.918" x2="125.417" y2="223.256" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint55_linear_234_4386" x1="221.596" y1="117.733" x2="223.866" y2="121.071" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint56_linear_234_4386" x1="224.084" y1="110.879" x2="226.354" y2="114.217" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint57_linear_234_4386" x1="255.239" y1="99.0401" x2="257.508" y2="102.378" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint58_linear_234_4386" x1="255.239" y1="72.2477" x2="257.508" y2="75.5857" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint59_linear_234_4386" x1="263.342" y1="77.8553" x2="265.612" y2="81.1933" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint60_linear_234_4386" x1="238.412" y1="71.6244" x2="240.682" y2="74.9623" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint61_linear_234_4386" x1="231.567" y1="74.1169" x2="233.837" y2="77.4549" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint62_linear_234_4386" x1="198.534" y1="69.7551" x2="200.803" y2="73.0931" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint63_linear_234_4386" x1="175.485" y1="50.4396" x2="177.755" y2="53.7776" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint64_linear_234_4386" x1="174.244" y1="39.8474" x2="176.514" y2="43.1853" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint65_linear_234_4386" x1="177.352" y1="31.1241" x2="179.622" y2="34.4621" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint66_linear_234_4386" x1="187.325" y1="34.8626" x2="189.594" y2="38.2005" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint67_linear_234_4386" x1="196.668" y1="33.6164" x2="198.938" y2="36.9544" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint68_linear_234_4386" x1="202.901" y1="38.6012" x2="205.171" y2="41.9392" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint69_linear_234_4386" x1="198.183" y1="41.7166" x2="201.21" y2="46.1672" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint70_linear_234_4386" x1="207.392" y1="63.5245" x2="210.04" y2="67.4187" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint71_linear_234_4386" x1="241.797" y1="89.071" x2="244.823" y2="93.5216" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint72_linear_234_4386" x1="188.84" y1="79.7245" x2="191.866" y2="84.1751" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint73_linear_234_4386" x1="169.784" y1="91.5631" x2="173.567" y2="97.1263" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint74_linear_234_4386" x1="169.784" y1="103.402" x2="173.567" y2="108.965" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint75_linear_234_4386" x1="186.609" y1="103.402" x2="190.392" y2="108.965" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint76_linear_234_4386" x1="193.464" y1="124.587" x2="197.247" y2="130.15" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint77_linear_234_4386" x1="176.019" y1="122.094" x2="179.802" y2="127.657" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint78_linear_234_4386" x1="168.544" y1="122.094" x2="172.327" y2="127.657" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint79_linear_234_4386" x1="167.913" y1="111.502" x2="171.696" y2="117.065" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint80_linear_234_4386" x1="161.06" y1="86.5784" x2="164.843" y2="92.1417" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint81_linear_234_4386" x1="185.37" y1="121.471" x2="189.153" y2="127.034" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint82_linear_234_4386" x1="179.488" y1="86.5784" x2="182.514" y2="91.029" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint83_linear_234_4386" x1="222.397" y1="97.7942" x2="226.937" y2="104.47" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint84_linear_234_4386" x1="216.163" y1="135.179" x2="220.702" y2="141.855" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint85_linear_234_4386" x1="171.928" y1="132.687" x2="176.468" y2="139.363" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint86_linear_234_4386" x1="204.952" y1="122.094" x2="209.491" y2="128.77" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint87_linear_234_4386" x1="184.389" y1="137.671" x2="188.929" y2="144.347" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint88_linear_234_4386" x1="196.846" y1="164.464" x2="201.386" y2="171.14" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint89_linear_234_4386" x1="189.993" y1="175.679" x2="194.532" y2="182.355" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint90_linear_234_4386" x1="196.846" y1="141.41" x2="201.386" y2="148.086" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint91_linear_234_4386" x1="171.928" y1="174.433" x2="176.468" y2="181.109" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint92_linear_234_4386" x1="191.862" y1="199.357" x2="196.402" y2="206.032" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint93_linear_234_4386" x1="172.147" y1="204.964" x2="175.552" y2="209.971" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint94_linear_234_4386" x1="144.731" y1="198.11" x2="148.136" y2="203.117" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint95_linear_234_4386" x1="136.008" y1="192.503" x2="139.413" y2="197.509" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint96_linear_234_4386" x1="127.417" y1="194.995" x2="131.2" y2="200.558" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint97_linear_234_4386" x1="129.915" y1="213.687" x2="133.698" y2="219.25" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint98_linear_234_4386" x1="123.06" y1="231.134" x2="126.843" y2="236.697" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint99_linear_234_4386" x1="129.782" y1="223.034" x2="133.186" y2="228.04" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint100_linear_234_4386" x1="178.153" y1="197.487" x2="182.693" y2="204.163" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint101_linear_234_4386" x1="164.201" y1="157.592" x2="56.1838" y2="99.6451" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F3F1F1"/>
        <stop offset="1" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint102_linear_234_4386" x1="57.0277" y1="99.3635" x2="108.505" y2="195.004" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
        <stop offset="1" stop-color="#FBFBFB" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint103_linear_234_4386" x1="107.937" y1="129.181" x2="160.522" y2="162.423" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0.7"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "LogoForBanner"
}
</script>

<style scoped>

</style>