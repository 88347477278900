import * as API from '@/api';
import router from "@/router";

export default {
  namespaced: true,
  state: {
    orders: [],
    operations: [],
    thanksPage: false,
  },
  actions: {
    getListOrders({ commit }, filter = { status: '', q: '' }) {
      API.getListOrders(filter).then(response => {
        commit('SET_ORDERS', response.data.length ? response.data : null)
      })
    },
    makeNewOrder({ commit }, order) {
      return API.makeNewOrder(order).then(response => {
        commit('SET_THANKS_PAGE', true)
        return response
        // router.push('/success-payment')
      })
    },
    getListOperations({ commit }) {

    }
  },
  mutations: {
    SET_ORDERS(state, orders) {
      state.orders = orders
    },
    SET_THANKS_PAGE(state, boolean) {
      state.thanksPage = boolean
    }
  }
}