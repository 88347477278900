<template>
  <main>
    <div class="operations">
      <div class="operations__header">
        <div class="operations__buttons">
          <Button2
              name="Все операции"
              :class="{ 'active': active === '' }"
              @click="selectStatusOperations('')"
          />
          <Button2
              name="Пополнение"
              :class="{ 'active': active === 'income' }"
              @click="selectStatusOperations('income')"
          />
          <Button2
              name="Вывод"
              :class="{ 'active': active === 'expense' }"
              @click="selectStatusOperations('expense')"
          />
        </div>
        <Input
            placeholder="Искать транзакцию..."
            icon="svg/search.svg"
            padding="10px 12px 10px 46px"
            width="270px"
            @custom-input="setSearch"
            @blur-input="searchOperations"
            @enter="searchOperations"
        />
      </div>

      <div class="operations__content operations__content_mt-30">
        <Preloader v-if="operations?.length === 0"/>
        <template v-for="operation in getFormattedOperations" v-if="operations?.length">
<!--          <OperationWithOrder-->
<!--              :order="operation"-->
<!--              v-if="operation.type === 'order'"-->
<!--          />-->
          <OperationWithBalance :card="operation"/>
        </template>
        <NoData
            class="operations__icon"
            name="У вас все еще нет ни одной операции"
            v-if="!operations"
        />
      </div>
    </div>
  </main>
</template>

<script>
import Button2 from "@/components/Buttons/Button2.vue";
import Input from "@/components/Utils/Input.vue";
import OperationWithOrder from "@/components/Operations/OperationWithOrder.vue";
import OperationWithBalance from "@/components/Operations/OperationWithBalance.vue";
import NoData from "@/components/Operations/NoData.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import Preloader from "@/components/Utils/Preloader.vue";

export default {
  name: "Operations",
  components: {
    Preloader,
    NoData,
    OperationWithBalance,
    OperationWithOrder,
    Input,
    Button2
  },
  data() {
    return {
      active: '',
      translationMethods: {
        cash: 'Наличные',
        card: 'Карта',
        crypto: 'Крипта'
      },
      search: '',
    }
  },
  methods: {
    ...mapActions('auth', ['getOperations']),
    ...mapMutations('auth', ['SET_OPERATIONS']),

    selectStatusOperations(status) {
      this.SET_OPERATIONS([])
      this.active = status
      this.getOperations({ limit: -1, q: this.search, status })
    },
    setSearch(search) {
      this.search = search
    },
    searchOperations() {
      this.SET_OPERATIONS([])
      this.getOperations({ limit: -1, q: this.search, status: this.active })
    }
  },
  computed: {
    ...mapState('auth', ['operations']),

    getFormattedOperations() {
      return this.operations.map(operation => new Object({
        id: operation.id,
        name: 'Пополнение баланса',
        methodName: 'Сумма пополнения',
        status: operation.status,
        price: this.getPrice(operation.invoice.price),
        method: this.translationMethods[operation.invoice.payment_type],
        date: this.getFormattedDate(operation.invoice.closed_at),

        // operations: {
        //   confirmations: '50/1',
        //   coin: 'USDT',
        //   network: 'BNB Smart Chain',
        //   address: 'TA23142155'
        // }
      }))
    }
  },
  mounted() {
    this.getOperations()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/Views/operations/operations";
</style>