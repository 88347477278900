<template>
  <div class="two-factor-authentication">
    <CardWithSettings title="Двухфакторная аутентификация">
      <template #right>
        <div class="two-factor-authentication__right" :class="{ 'active': user.is_2fa }">
          {{ getText.text }}
        </div>
      </template>
      <template #dropdown>
        <Button
            type="submit"
            :name="getText.button"
            @click="toggle2fa"
        />
      </template>
    </CardWithSettings>
  </div>
  <EnableTwoFactorAuthenticationModal v-if="showModal" @close="showModal = false"/>
</template>

<script>
import CardWithSettings from "@/components/Views/Settings/CardWithSettings.vue";
import Button from "@/components/Buttons/Button.vue";
import EnableTwoFactorAuthenticationModal from "@/components/Modals/EnableTwoFactorAuthenticationModal.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "TwoFactorAuthentication",
  components: {EnableTwoFactorAuthenticationModal, Button, CardWithSettings},
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    ...mapActions('twoStepAuthentication', ['switchTwoStepAuthentication']),

    toggle2fa() {
      !this.user.is_2fa ? this.showModal = true : this.switchTwoStepAuthentication(false)
    }
  },
  computed: {
    ...mapState('auth', ['user']),

    getText() {
      return {
        text: this.user.is_2fa ? 'Включено' : 'Отключено',
        button: this.user.is_2fa ? 'Отключить' : 'Включить',
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/Views/settings/_twoFactorAuthentication.scss";
</style>