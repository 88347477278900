<template>
  <header class="header-mobile" :class="{ 'auth': token }">
    <div class="container">
      <div class="header-mobile__content">
        <IconButton bg="white" @click="showBurgerMenu = true">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
            <path d="M0.511719 10.9769H15.5807V9.30262H0.511719V10.9769ZM0.511719 6.79112H15.5807V5.11679H0.511719V6.79112ZM0.511719 0.930969V2.6053H15.5807V0.930969H0.511719Z" fill="#FFA765"/>
          </svg>
        </IconButton>
        <HeaderMobileLogo/>
        <IconButton bg="white" @click="showOrderModal = true">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.40499 18C4.90954 18 4.48554 17.8239 4.13302 17.4717C3.77989 17.1189 3.60333 16.695 3.60333 16.2C3.60333 15.705 3.77989 15.2811 4.13302 14.9283C4.48554 14.5761 4.90954 14.4 5.40499 14.4C5.90045 14.4 6.32444 14.5761 6.67697 14.9283C7.0301 15.2811 7.20666 15.705 7.20666 16.2C7.20666 16.695 7.0301 17.1189 6.67697 17.4717C6.32444 17.8239 5.90045 18 5.40499 18ZM14.4133 18C13.9179 18 13.4939 17.8239 13.1413 17.4717C12.7882 17.1189 12.6117 16.695 12.6117 16.2C12.6117 15.705 12.7882 15.2811 13.1413 14.9283C13.4939 14.5761 13.9179 14.4 14.4133 14.4C14.9088 14.4 15.3331 14.5761 15.6862 14.9283C16.0387 15.2811 16.215 15.705 16.215 16.2C16.215 16.695 16.0387 17.1189 15.6862 17.4717C15.3331 17.8239 14.9088 18 14.4133 18ZM4.63929 3.6L6.80128 8.1H13.1071L15.5844 3.6H4.63929ZM3.7835 1.8H17.0708C17.4161 1.8 17.6788 1.9536 17.859 2.2608C18.0392 2.5686 18.0467 2.88 17.8815 3.195L14.6836 8.955C14.5184 9.255 14.2968 9.4875 14.0188 9.6525C13.7413 9.8175 13.4374 9.9 13.1071 9.9H6.39591L5.40499 11.7H16.215V13.5H5.40499C4.72937 13.5 4.2189 13.2036 3.87358 12.6108C3.52826 12.0186 3.51325 11.43 3.82854 10.845L5.04466 8.64L1.80166 1.8H0V0H2.92771L3.7835 1.8Z" fill="#FFA765"/>
          </svg>
        </IconButton>
      </div>
    </div>
  </header>
  <BurgerMenu :opened="showBurgerMenu" v-show="showBurgerMenu" @close="showBurgerMenu = false"/>
  <MakeOrderModal v-if="showOrderModal" @close="showOrderModal = false"/>
</template>

<script>
import HeaderMobileLogo from "@/components/HeaderMobile/HeaderMobileLogo.vue";
import IconButton from "@/components/Buttons/IconButton.vue";
import BurgerMenu from "@/components/BurgerMenu.vue";
import MakeOrderModal from "@/components/Modals/MakeOrderModal.vue";
import {mapState} from "vuex";

export default {
  name: "HeaderMobile",
  components: {MakeOrderModal, BurgerMenu, IconButton, HeaderMobileLogo},
  data() {
    return {
      showBurgerMenu: false,
      showOrderModal: false,
    }
  },
  computed: {
    ...mapState('auth', ['token']),
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_headerMobile.scss";
</style>