<template>
  <main>
    <Product :detailProduct="detailProduct.product || detailProduct"/>
    <div class="container">
      <BuyMorePayLess :moreProducts="detailProduct.products || detailProduct" />
    </div>
  </main>
</template>

<script>
import Product from "@/components/Views/Commodity/Product.vue";
import BuyMorePayLess from "@/components/Views/Commodity/BuyMorePayLess.vue";
import {mapState} from "vuex";

export default {
  name: "Commodity",
  components: {BuyMorePayLess, Product},
  computed: {
    ...mapState('products', ['detailProduct']),
  },
  methods: {
    loadProduct() {
      switch (this.$route.meta.type) {
        case 'nanobox':
          this.$store.dispatch('products/getDetailsNanobox', this.$route.params.id)
          break
        case 'product':
          this.$store.dispatch('products/getDetailsProduct', { id: this.$route.params.id })
          break
      }
    }
  },
  watch: {
    '$route.path'() {
      this.loadProduct()
    }
  },
  mounted() {
    this.loadProduct()
  },
}
</script>

<style scoped>

</style>