<template>
  <main>
    <div class="about-us">
      <div class="container">
        <h5>Здоровая клетка - здоровый организм</h5>

        <h1 class="about-us__title about-us__title_mt-16">О компании</h1>

        <div class="about-us__subtitle about-us__subtitle_mt-60">
<!--          <p>Мы компания ООО “ТД Градалис” является российским производителем пищевых добавок и БАДов.</p>-->
<!--          <p>Производство премиальных высококонцентрированных, натуральных минералов в коллоидной форме.</p>-->
<!--          <p>В линейке «Звено Жизни» представлены такие продукты как:</p>-->
<!--          <ul>-->
<!--            <li>Магний коллоидный 500мкг/мл.</li>-->
<!--            <li>Цинк коллоидный 300мкг/мл.</li>-->
<!--            <li>Серебро коллоидное 70мкг/мл.</li>-->
<!--          </ul>-->
<!--          <p>Вся продукция прошла лабораторные испытания.</p>-->
<!--          <p>Особенность коллоидных минералов в том, что все они находятся в самой биодоступной для организма форме.</p>-->
<!--          <p>Данная форма называется - коллоидная форма.</p>-->
<!--          <p>Минералы в коллоидной форме превосходят по показателям биоусвоения и чистоты состава такие известные формы как: хелатная; малатная; цитратная; оксидная; и т.д.</p>-->
<!--          <p>Всё дело в уникальной технологии, которая позволяет превращать металл в подобие «детского питания» для организма.</p>-->
<!--          <p>Кроме наночастиц того или иного металла и воды, в составе нет ничего, значит, в организм не попадают различные химические «хвостики» и иные вредные компоненты.</p>-->
<!--          <p>Организму не приходится тратить силы на борьбу с поступившим веществом.</p>-->
<!--          <p>Благодаря размерам наночастиц (от 1 нанометра), данный металл не нуждается в дополнительной переработке и усваивается организмом на клеточном уровне.</p>-->

          <div>
            <p>Мы компания ООО “ТД Градалис”</p>
            <p>являемся российским производителем</p>
            <p>пищевых добавок и БАДов.</p>
          </div>
          <p>Мы компания ООО “ТД Градалис” является российским производителем пищевых добавок и БАДов.</p>
        </div>

        <div class="about-us__content about-us__content_mt-24">
          <p>Производство премиальных высококонцентрированных, натуральных минералов в коллоидной форме.</p>
          <p>В линейке «Звено Жизни» представлены такие продукты как:</p>
          <ul>
            <li>Магний коллоидный 500мкг/мл.</li>
            <li>Цинк коллоидный 300мкг/мл.</li>
            <li>Серебро коллоидное 70мкг/мл.</li>
          </ul>
          <p>Вся продукция прошла лабораторные испытания.</p>
          <p>Особенность коллоидных минералов в том, что все они находятся в самой биодоступной для организма форме.</p>
          <p>Данная форма называется - коллоидная форма.</p>
          <p>Минералы в коллоидной форме превосходят по показателям биоусвоения и чистоты состава такие известные формы как: хелатная; малатная; цитратная; оксидная; и т.д.</p>
          <p>Всё дело в уникальной технологии, которая позволяет превращать металл в подобие «детского питания» для организма.</p>
          <p>Кроме наночастиц того или иного металла и воды, в составе нет ничего, значит, в организм не попадают различные химические «хвостики» и иные вредные компоненты.</p>
          <p>Организму не приходится тратить силы на борьбу с поступившим веществом.</p>
          <p>Благодаря размерам наночастиц (от 1 нанометра), данный металл не нуждается в дополнительной переработке и усваивается организмом на клеточном уровне.</p>
        </div>

        <ProductCompositionBanner/>
      </div>
    </div>
  </main>
</template>

<script>
import ProductCompositionBanner from "@/components/Views/AboutUs/ProductCompositionBanner.vue";

export default {
  name: "AboutUs",
  components: {ProductCompositionBanner}
}
</script>

<style scoped lang="scss">
main {
  margin: 60px 0 80px;
}

@import "@/assets/scss/aboutUs";
</style>