<template>
  <RouterView/>
  <EnableTwoFactorAuthenticationModal
      button-name="Войти"
      :show-qr-code="false"
      v-if="showTwoFactorAuthModal"
      @close="SET_SHOW_TWO_FACTOR_AUTH_MODAL(false)"
  />
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import EnableTwoFactorAuthenticationModal from "@/components/Modals/EnableTwoFactorAuthenticationModal.vue";

export default {
  name: "App",
  components: { EnableTwoFactorAuthenticationModal },
  methods: {
    ...mapActions('phoneMask', ['getPhoneMasks']),
    ...mapActions('auth', ['getTokenForGoogleAndFacebookAuth']),
    ...mapMutations('products', ['SET_BASKET']),
    ...mapMutations('auth', ['SET_SHOW_TWO_FACTOR_AUTH_MODAL'])
  },
  computed: {
    ...mapState('auth', ['user', 'showTwoFactorAuthModal']),

    getToken() {
      return this.$route.fullPath.split('?token=')[1]
    }
  },
  watch: {
    '$route.path'() {
      document.body.style.overflow = null;
      window.scrollTo(0, 0)
      // if (this.$route.path !== '/success-payment') this.$store.commit('orders/SET_THANKS_PAGE', false)
    },
    'getToken'() {
      if (this.getToken) this.getTokenForGoogleAndFacebookAuth(this.getToken)
    }
  },
  beforeMount() {
    const basket = localStorage.getItem('basket')
    !basket ? localStorage.setItem('basket', '[]') : this.SET_BASKET(JSON.parse(basket))
    this.getPhoneMasks()
  }
}
</script>