import { getPhoneMasks } from "@/api";

export default {
  namespaced: true,
  state: {
    masks: [],
  },
  actions: {
    getPhoneMasks({ commit }) {
      getPhoneMasks().then(response => {
        commit('SET_PHONE_MASKS', response.data)
      })
    }
  },
  mutations: {
    SET_PHONE_MASKS(state, masks) {
      state.masks = masks
    }
  }
}