<template>
  <main>
    <div class="payment">
      <div class="container">
        <div class="payment__body">
          <div class="payment__circle rejected">
            <svg xmlns="http://www.w3.org/2000/svg" width="60%" height="60%" viewBox="0 0 24 24">
              <path d="M4.293,18.293,10.586,12,4.293,5.707A1,1,0,0,1,5.707,4.293L12,10.586l6.293-6.293a1,1,0,1,1,1.414,1.414L13.414,12l6.293,6.293a1,1,0,1,1-1.414,1.414L12,13.414,5.707,19.707a1,1,0,0,1-1.414-1.414Z" fill="#FFFFFF"/>
            </svg>
          </div>
          <h2 class="payment__title payment__title_mt-30">Ошибка заказа, попробуйте снова</h2>
          <Button class="payment__button payment__button_mt-24" name="Вернуться на главную" @click="$router.push('/')"/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";

export default {
  name: "RejectedPayment",
  components: {Button}
}
</script>

<style scoped lang="scss">
main {
  margin: 13.35vh 0;
}

@media screen and (max-width: 1200px) {
  main {
    margin: 100px 0 120px;
  }
}

@import "@/assets/scss/payment";
</style>