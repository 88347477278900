<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="social-media-icon only-fill" @click="windowOpen('https://t.me/colloidal_nanominerals')">
    <path d="M1.83813 8.23015L17.8012 2.07533C18.5421 1.80767 19.1892 2.25607 18.9491 3.37638L18.9505 3.375L16.2325 16.18C16.0311 17.0878 15.4916 17.3085 14.7369 16.8808L10.5978 13.8303L8.60139 15.7536C8.38064 15.9744 8.19438 16.1606 7.76668 16.1606L8.06055 11.9484L15.7317 5.01822C16.0655 4.72434 15.6572 4.55878 15.217 4.85128L5.73715 10.8198L1.65049 9.545C0.763345 9.26354 0.74403 8.65786 1.83813 8.23015Z" fill="#FFA765"/>
  </svg>
</template>

<script>
export default {
  name: "TelegramIcon"
}
</script>

<style scoped>

</style>