<template>
  <div class="referral-earnings-information">
    <div class="referral-earnings-information__content">
      <div class="referral-earnings-information__item">
        <div class="referral-earnings-information__item-title">Доход от реферралов 1 линии</div>
        <div class="referral-earnings-information__item-income blue">₽ {{ getPrice(balance.first_line, 0) }}</div>
      </div>
      <div class="referral-earnings-information__item">
        <div class="referral-earnings-information__item-title">Доход от реферралов 2 линии</div>
        <div class="referral-earnings-information__item-income green">₽ {{ getPrice(balance.second_line, 0) }}</div>
      </div>
      <div class="referral-earnings-information__item">
        <div class="referral-earnings-information__item-title">Доход от реферралов 3 линии</div>
        <div class="referral-earnings-information__item-income orange">₽ {{ getPrice(balance.third_line, 0) }}</div>
      </div>
      <div class="referral-earnings-information__item">
        <div class="referral-earnings-information__item-title fw-700">Общий реферральный доход</div>
        <div class="referral-earnings-information__item-income turquoise">₽ {{ getPrice(balance.total, 0) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ReferralEarningsInformation",
  methods: {
    ...mapActions('balance', ['getBalance']),
  },
  computed: {
    ...mapState('balance', ['balance']),
  },
  mounted() {
    this.getBalance()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/Views/partners/_referralEarningsInformation.scss";
</style>