import {
  getTwoStepAuthenticationData,
  regenerateTwoStepAuthenticationToken,
  switchTwoStepAuthentication,
  twoStepAuthenticationConfirm
} from "@/api";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    twoStepAuthentication: {},
  },
  actions: {
    getTwoStepAuthenticationData({ commit }) {
      getTwoStepAuthenticationData().then(response => {
        commit('SET_TWO_STEP_AUTHENTICATION', response.data)
      })
    },
    switchTwoStepAuthentication({ commit }, activate) {
      return switchTwoStepAuthentication({ activate }).then(response => {
        commit('SET_TWO_STEP_AUTHENTICATION', response.data)
        commit('auth/CHANGE_USER_DATA', response.data.user, { root: true })
      })
    },
    twoStepAuthenticationConfirm({ commit }, code) {
      return twoStepAuthenticationConfirm(code).then(response => {
        console.log('response', response)
        if (response?.success) commit('auth/CHANGE_USER_DATA', { is_2fa: true }, { root: true })
        return response
      })
    },
    regenerateTwoStepAuthenticationToken({ commit }) {
      regenerateTwoStepAuthenticationToken().then(response => commit('SET_TWO_STEP_AUTHENTICATION', response.data))
    }
  },
  mutations: {
    SET_TWO_STEP_AUTHENTICATION(state, data) {
      state.twoStepAuthentication = data
    }
  }
}