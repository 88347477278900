export const chartData = {
    labels: [],
    datasets: [
        {
            label: false,
            backgroundColor: (ctx) => {
                const canvas = ctx.chart.ctx;
                const gradient = canvas.createLinearGradient(0, 0, 0, 180);

                gradient.addColorStop(.5, 'rgba(36, 200, 188, 0.24)');
                gradient.addColorStop(1, 'rgba(36, 200, 188, 0)');

                return gradient;
            },
            fill: true,
            borderColor: '#24C8BC',
            borderWidth: 2,
            data: [],
            pointBackgroundColor: '#262626',
            pointHoverBorderColor: 'rgb(96%, 96%, 96%, 1)',
            pointHoverBorderWidth: 4,
            pointHoverRadius: 8,
        },
    ]
}

export const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    lineTension: .5,

    datasets: {
        line: {
            pointRadius: 1, // disable for all `'line'` datasets
        },
    },
    plugins: {
        tooltip: {
            backgroundColor: '#000000',
            displayColors: false,
            bodyFont: {
                size: 12
            },
            callbacks: {
                label: context => {
                    return `₽${new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0
                    }).format(context.raw)}`
                },
                title: () => {
                    return ''
                },
            },
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            border: {
                display: false
            },
            ticks: {
                font: {
                    size: 10 //this change the font size
                }
            }
        },
        y: {
            display: false,

        },
    },
}
