<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="97" height="53" viewBox="0 0 97 53" fill="none">
    <path d="M59.976 11.2092C60.3162 11.2092 60.5894 11.331 60.7956 11.5744C61.0121 11.8068 61.1203 12.111 61.1203 12.4873V21.7985C61.1203 22.1857 61.0121 22.5011 60.7956 22.7445C60.5894 22.9769 60.3162 23.0931 59.976 23.0931C59.6048 23.0931 59.3059 22.9492 59.079 22.6615L53.8986 15.5412V21.7985C53.8986 22.1857 53.7955 22.5011 53.5893 22.7445C53.3934 22.9769 53.1253 23.0931 52.7851 23.0931C52.4449 23.0931 52.1717 22.9769 51.9655 22.7445C51.7593 22.5011 51.6562 22.1857 51.6562 21.7985V12.4873C51.6562 12.111 51.7593 11.8068 51.9655 11.5744C52.182 11.331 52.4604 11.2092 52.8006 11.2092C53.182 11.2092 53.4759 11.3531 53.6821 11.6408L58.8625 18.7113V12.4873C58.8625 12.111 58.9656 11.8068 59.1718 11.5744C59.378 11.331 59.6461 11.2092 59.976 11.2092Z" fill="#24C8BC"/>
    <path d="M73.4445 21.4167C73.527 21.6159 73.5682 21.7929 73.5682 21.9478C73.5682 22.2577 73.4497 22.5232 73.2126 22.7445C72.9858 22.9547 72.7229 23.0599 72.4239 23.0599C72.228 23.0599 72.0424 23.0045 71.8672 22.8939C71.6919 22.7722 71.5579 22.5951 71.4651 22.3628L70.6455 20.4043H65.4032L64.5836 22.3628C64.4908 22.5951 64.3568 22.7722 64.1815 22.8939C64.0165 23.0045 63.831 23.0599 63.6248 23.0599C63.3258 23.0599 63.0578 22.9547 62.8206 22.7445C62.5835 22.5232 62.465 22.2577 62.465 21.9478C62.465 21.7929 62.5062 21.6159 62.5887 21.4167L66.764 12.0059C66.8774 11.7404 67.0475 11.5412 67.2743 11.4084C67.5011 11.2646 67.7434 11.1926 68.0011 11.1926C68.2692 11.1926 68.5166 11.2646 68.7434 11.4084C68.9702 11.5523 69.1455 11.7514 69.2692 12.0059L73.4445 21.4167ZM66.2228 18.4458H69.8259L68.0321 14.1304L66.2228 18.4458Z" fill="#24C8BC"/>
    <path d="M83.2175 11.2092C83.5577 11.2092 83.8309 11.331 84.0371 11.5744C84.2536 11.8068 84.3619 12.111 84.3619 12.4873V21.7985C84.3619 22.1857 84.2536 22.5011 84.0371 22.7445C83.8309 22.9769 83.5577 23.0931 83.2175 23.0931C82.8464 23.0931 82.5474 22.9492 82.3206 22.6615L77.1401 15.5412V21.7985C77.1401 22.1857 77.037 22.5011 76.8308 22.7445C76.6349 22.9769 76.3669 23.0931 76.0267 23.0931C75.6865 23.0931 75.4133 22.9769 75.2071 22.7445C75.0009 22.5011 74.8978 22.1857 74.8978 21.7985V12.4873C74.8978 12.111 75.0009 11.8068 75.2071 11.5744C75.4236 11.331 75.7019 11.2092 76.0421 11.2092C76.4236 11.2092 76.7174 11.3531 76.9236 11.6408L82.1041 18.7113V12.4873C82.1041 12.111 82.2072 11.8068 82.4134 11.5744C82.6196 11.331 82.8876 11.2092 83.2175 11.2092Z" fill="#24C8BC"/>
    <path d="M91.5365 23.1429C90.454 23.1429 89.5107 22.8994 88.7066 22.4126C87.9127 21.9146 87.2993 21.2175 86.8663 20.3213C86.4333 19.4139 86.2168 18.3517 86.2168 17.1346C86.2168 15.9174 86.4333 14.8607 86.8663 13.9644C87.2993 13.0571 87.9127 12.36 88.7066 11.8731C89.5107 11.3863 90.454 11.1429 91.5365 11.1429C92.619 11.1429 93.5572 11.3863 94.351 11.8731C95.1551 12.36 95.7737 13.0571 96.2067 13.9644C96.6397 14.8607 96.8562 15.9174 96.8562 17.1346C96.8562 18.3517 96.6397 19.4139 96.2067 20.3213C95.7737 21.2175 95.1551 21.9146 94.351 22.4126C93.5572 22.8994 92.619 23.1429 91.5365 23.1429ZM91.5365 21.1346C92.4437 21.1346 93.1551 20.7915 93.6706 20.1055C94.1963 19.4195 94.4592 18.4292 94.4592 17.1346C94.4592 15.8399 94.2015 14.8552 93.686 14.1802C93.1706 13.4942 92.454 13.1512 91.5365 13.1512C90.619 13.1512 89.9025 13.4942 89.387 14.1802C88.8818 14.8552 88.6292 15.8399 88.6292 17.1346C88.6292 18.4292 88.887 19.4195 89.4024 20.1055C89.9179 20.7915 90.6293 21.1346 91.5365 21.1346Z" fill="#24C8BC"/>
    <path d="M57.9536 31.9502C58.5189 32.1351 58.9545 32.4588 59.2603 32.9211C59.5754 33.3732 59.7329 33.9332 59.7329 34.601C59.7329 35.5155 59.4271 36.2399 58.8155 36.7741C58.2131 37.3084 57.379 37.5756 56.3132 37.5756H52.671C52.3467 37.5756 52.0965 37.478 51.9204 37.2827C51.7443 37.0875 51.6562 36.8101 51.6562 36.4505V27.8352C51.6562 27.4756 51.7443 27.1982 51.9204 27.0029C52.0965 26.8077 52.3467 26.7101 52.671 26.7101H56.1742C57.1936 26.7101 57.9999 26.9618 58.593 27.4653C59.1954 27.9688 59.4966 28.6572 59.4966 29.5305C59.4966 30.0956 59.3576 30.5939 59.0796 31.0255C58.8108 31.4467 58.4355 31.755 57.9536 31.9502ZM53.7693 31.1334H55.8128C56.3688 31.1334 56.7766 31.0306 57.0361 30.8251C57.3048 30.6093 57.4392 30.2857 57.4392 29.8542C57.4392 29.4021 57.3048 29.0733 57.0361 28.8678C56.7766 28.652 56.3688 28.5441 55.8128 28.5441H53.7693V31.1334ZM56.063 35.7415C56.6376 35.7415 57.0546 35.6337 57.3141 35.4179C57.5736 35.2021 57.7033 34.8528 57.7033 34.3699C57.7033 33.887 57.5736 33.5325 57.3141 33.3064C57.0546 33.0804 56.6376 32.9674 56.063 32.9674H53.7693V35.7415H56.063Z" fill="#24C8BC"/>
    <path d="M65.7446 37.7143C64.7715 37.7143 63.9235 37.4882 63.2007 37.0361C62.4871 36.5738 61.9357 35.9265 61.5464 35.0942C61.1572 34.2517 60.9626 33.2653 60.9626 32.1351C60.9626 31.0049 61.1572 30.0237 61.5464 29.1914C61.9357 28.3489 62.4871 27.7016 63.2007 27.2495C63.9235 26.7975 64.7715 26.5714 65.7446 26.5714C66.7177 26.5714 67.5611 26.7975 68.2747 27.2495C68.9975 27.7016 69.5536 28.3489 69.9428 29.1914C70.3321 30.0237 70.5267 31.0049 70.5267 32.1351C70.5267 33.2653 70.3321 34.2517 69.9428 35.0942C69.5536 35.9265 68.9975 36.5738 68.2747 37.0361C67.5611 37.4882 66.7177 37.7143 65.7446 37.7143ZM65.7446 35.8494C66.5602 35.8494 67.1996 35.5309 67.663 34.8939C68.1357 34.2568 68.372 33.3373 68.372 32.1351C68.372 30.933 68.1403 30.0186 67.6769 29.3918C67.2135 28.7548 66.5694 28.4363 65.7446 28.4363C64.9198 28.4363 64.2757 28.7548 63.8123 29.3918C63.3582 30.0186 63.1312 30.933 63.1312 32.1351C63.1312 33.3373 63.3629 34.2568 63.8262 34.8939C64.2896 35.5309 64.9291 35.8494 65.7446 35.8494Z" fill="#24C8BC"/>
    <path d="M79.6421 35.8494C79.8182 36.0755 79.9062 36.3118 79.9062 36.5584C79.9062 36.8563 79.7996 37.1183 79.5865 37.3444C79.3826 37.5601 79.1463 37.668 78.8775 37.668C78.5995 37.668 78.3632 37.5396 78.1685 37.2827L75.5551 33.738L72.9138 37.2827C72.7099 37.5396 72.4736 37.668 72.2048 37.668C71.9454 37.668 71.709 37.5601 71.4959 37.3444C71.292 37.1183 71.19 36.8563 71.19 36.5584C71.19 36.3015 71.2735 36.0652 71.4403 35.8494L74.2901 32.0889L71.5376 28.4363C71.3615 28.2102 71.2735 27.9739 71.2735 27.7273C71.2735 27.4293 71.3754 27.1725 71.5793 26.9567C71.7924 26.7307 72.0288 26.6176 72.2883 26.6176C72.5663 26.6176 72.8072 26.7512 73.0111 27.0184L75.5551 30.4244L78.0712 27.0184C78.2751 26.7512 78.5161 26.6176 78.7941 26.6176C79.0536 26.6176 79.2853 26.7307 79.4892 26.9567C79.7023 27.1725 79.8089 27.4293 79.8089 27.7273C79.8089 27.9842 79.7255 28.2205 79.5587 28.4363L76.8062 32.0889L79.6421 35.8494Z" fill="#24C8BC"/>
    <path d="M87.2834 34.6352C87.6232 34.724 87.885 34.8904 88.0688 35.1345C88.2582 35.3786 88.3529 35.6838 88.3529 36.0499C88.3529 36.5659 88.183 36.9626 87.8432 37.24C87.5034 37.5118 87.0216 37.6477 86.3978 37.6477H84.2923C84.1865 37.6477 84.1002 37.6172 84.0333 37.5562C83.972 37.4952 83.9414 37.4147 83.9414 37.3148V32.1137C83.9414 32.0139 83.972 31.9334 84.0333 31.8724C84.1002 31.8114 84.1865 31.7809 84.2923 31.7809H86.3059C86.913 31.7809 87.3837 31.914 87.7179 32.1803C88.0521 32.4466 88.2192 32.8239 88.2192 33.3121C88.2192 33.6283 88.1356 33.9001 87.9685 34.1276C87.807 34.3551 87.5786 34.5243 87.2834 34.6352ZM84.6182 34.3856H86.2056C86.6457 34.3856 86.9771 34.2968 87.1999 34.1193C87.4282 33.9418 87.5424 33.6838 87.5424 33.3454C87.5424 32.6796 87.0968 32.3467 86.2056 32.3467H84.6182V34.3856ZM86.3309 37.0818C86.7933 37.0818 87.133 36.9986 87.3503 36.8322C87.5731 36.6602 87.6845 36.3939 87.6845 36.0333C87.6845 35.3121 87.2333 34.9515 86.3309 34.9515H84.6182V37.0818H86.3309Z" fill="black"/>
    <path d="M89.9138 37.6893C89.808 37.6893 89.7244 37.6588 89.6632 37.5978C89.6019 37.5312 89.5713 37.4452 89.5713 37.3398V32.0888C89.5713 31.9834 89.6019 31.9001 89.6632 31.8391C89.7244 31.7725 89.808 31.7393 89.9138 31.7393C90.0196 31.7393 90.1032 31.7725 90.1645 31.8391C90.2257 31.9001 90.2564 31.9834 90.2564 32.0888V37.3398C90.2564 37.4452 90.2257 37.5312 90.1645 37.5978C90.1032 37.6588 90.0196 37.6893 89.9138 37.6893Z" fill="black"/>
    <path d="M94.1737 37.7143C93.6278 37.7143 93.1516 37.5922 92.745 37.3481C92.344 37.104 92.0348 36.7573 91.8176 36.3079C91.6004 35.8585 91.4917 35.3287 91.4917 34.7185C91.4917 34.1026 91.5976 33.5701 91.8092 33.1207C92.0265 32.6658 92.3356 32.319 92.7366 32.0805C93.1433 31.8363 93.6223 31.7143 94.1737 31.7143C94.7251 31.7143 95.2014 31.8363 95.6024 32.0805C96.009 32.319 96.3182 32.6658 96.5298 33.1207C96.7471 33.5701 96.8557 34.0999 96.8557 34.7101C96.8557 35.3204 96.7471 35.853 96.5298 36.3079C96.3126 36.7573 96.0007 37.104 95.5941 37.3481C95.193 37.5922 94.7196 37.7143 94.1737 37.7143ZM94.1737 37.1318C94.7976 37.1318 95.2821 36.9237 95.6275 36.5076C95.9784 36.086 96.1538 35.4868 96.1538 34.7101C96.1538 33.9334 95.9812 33.337 95.6358 32.921C95.2905 32.5049 94.8031 32.2968 94.1737 32.2968C93.5499 32.2968 93.0625 32.5076 92.7116 32.9293C92.3662 33.3454 92.1936 33.939 92.1936 34.7101C92.1936 35.4813 92.3662 36.0777 92.7116 36.4993C93.0625 36.921 93.5499 37.1318 94.1737 37.1318Z" fill="black"/>
    <path d="M0.16961 36.4177V12.1735L20.558 0.186563L41.7516 12.0678V36.1468L20.4493 47.815L0.16961 36.4177Z" fill="white" stroke="#ADE3E3" stroke-width="0.323596"/>
    <circle cx="33.0433" cy="15.9357" r="0.836245" fill="url(#paint0_linear_349_10189)"/>
    <circle cx="29.4612" cy="14.2632" r="0.836245" fill="url(#paint1_linear_349_10189)"/>
    <circle cx="31.1331" cy="9.84302" r="0.836245" fill="url(#paint2_linear_349_10189)"/>
    <circle cx="35.9144" cy="11.7545" r="0.836245" fill="url(#paint3_linear_349_10189)"/>
    <circle cx="42.6019" cy="11.9934" r="0.836245" fill="url(#paint4_linear_349_10189)"/>
    <circle cx="24.9222" cy="12.2323" r="0.836245" fill="url(#paint5_linear_349_10189)"/>
    <circle cx="22.887" cy="10.5598" r="0.836245" fill="url(#paint6_linear_349_10189)"/>
    <circle cx="28.0277" cy="11.3961" r="0.836245" fill="url(#paint7_linear_349_10189)"/>
    <circle cx="36.5081" cy="9.36518" r="0.836245" fill="url(#paint8_linear_349_10189)"/>
    <circle cx="40.3323" cy="14.7411" r="0.836245" fill="url(#paint9_linear_349_10189)"/>
    <circle cx="34.7191" cy="21.9088" r="0.836245" fill="url(#paint10_linear_349_10189)"/>
    <circle cx="38.4222" cy="21.0726" r="0.836245" fill="url(#paint11_linear_349_10189)"/>
    <circle cx="36.0316" cy="30.8686" r="0.836245" fill="url(#paint12_linear_349_10189)"/>
    <circle cx="33.8792" cy="28.121" r="0.836245" fill="url(#paint13_linear_349_10189)"/>
    <circle cx="33.6409" cy="30.8686" r="0.836245" fill="url(#paint14_linear_349_10189)"/>
    <circle cx="30.6566" cy="33.2579" r="0.836245" fill="url(#paint15_linear_349_10189)"/>
    <circle cx="32.0902" cy="31.9438" r="0.836245" fill="url(#paint16_linear_349_10189)"/>
    <circle cx="30.055" cy="38.0365" r="0.836245" fill="url(#paint17_linear_349_10189)"/>
    <circle cx="34.1175" cy="36.4834" r="0.836245" fill="url(#paint18_linear_349_10189)"/>
    <circle cx="29.9378" cy="35.4083" r="0.836245" fill="url(#paint19_linear_349_10189)"/>
    <circle cx="32.0902" cy="35.8861" r="0.836245" fill="url(#paint20_linear_349_10189)"/>
    <circle cx="30.2972" cy="41.8593" r="0.836245" fill="url(#paint21_linear_349_10189)"/>
    <circle cx="26.9534" cy="42.9345" r="0.836245" fill="url(#paint22_linear_349_10189)"/>
    <circle cx="28.1448" cy="36.6029" r="0.836245" fill="url(#paint23_linear_349_10189)"/>
    <circle cx="28.2659" cy="40.7841" r="0.836245" fill="url(#paint24_linear_349_10189)"/>
    <circle cx="26.1175" cy="39.7089" r="0.836245" fill="url(#paint25_linear_349_10189)"/>
    <circle cx="22.5316" cy="37.917" r="0.836245" fill="url(#paint26_linear_349_10189)"/>
    <circle cx="23.7269" cy="39.8284" r="0.836245" fill="url(#paint27_linear_349_10189)"/>
    <circle cx="46.6644" cy="23.3424" r="0.836245" fill="url(#paint28_linear_349_10189)"/>
    <circle cx="29.4629" cy="7.6927" r="0.716782" fill="url(#paint29_linear_349_10189)"/>
    <circle cx="24.3223" cy="7.93163" r="0.716782" fill="url(#paint30_linear_349_10189)"/>
    <circle cx="32.209" cy="12.2323" r="0.716782" fill="url(#paint31_linear_349_10189)"/>
    <circle cx="26.7129" cy="14.0242" r="0.716782" fill="url(#paint32_linear_349_10189)"/>
    <circle cx="36.1504" cy="18.086" r="0.716782" fill="url(#paint33_linear_349_10189)"/>
    <circle cx="25.7558" cy="9.72356" r="0.716782" fill="url(#paint34_linear_349_10189)"/>
    <circle cx="39.8535" cy="19.1612" r="0.716782" fill="url(#paint35_linear_349_10189)"/>
    <circle cx="41.7669" cy="16.4136" r="0.477855" fill="url(#paint36_linear_349_10189)"/>
    <circle cx="44.0946" cy="17.429" r="0.65705" fill="url(#paint37_linear_349_10189)"/>
    <circle cx="46.3641" cy="26.7471" r="0.65705" fill="url(#paint38_linear_349_10189)"/>
    <circle cx="41.1063" cy="21.9686" r="0.65705" fill="url(#paint39_linear_349_10189)"/>
    <circle cx="38.1219" cy="17.1901" r="0.65705" fill="url(#paint40_linear_349_10189)"/>
    <circle cx="49.1709" cy="21.431" r="0.358391" fill="url(#paint41_linear_349_10189)"/>
    <circle cx="49.2881" cy="22.8646" r="0.358391" fill="url(#paint42_linear_349_10189)"/>
    <circle cx="50.0107" cy="25.4928" r="0.358391" fill="url(#paint43_linear_349_10189)"/>
    <circle cx="44.3935" cy="23.4619" r="0.358391" fill="url(#paint44_linear_349_10189)"/>
    <circle cx="41.0498" cy="23.4619" r="0.358391" fill="url(#paint45_linear_349_10189)"/>
    <circle cx="40.0928" cy="27.5237" r="0.358391" fill="url(#paint46_linear_349_10189)"/>
    <circle cx="32.206" cy="38.2754" r="0.358391" fill="url(#paint47_linear_349_10189)"/>
    <circle cx="33.1631" cy="41.9788" r="0.358391" fill="url(#paint48_linear_349_10189)"/>
    <circle cx="33.1631" cy="44.8459" r="0.358391" fill="url(#paint49_linear_349_10189)"/>
    <circle cx="22.292" cy="41.8593" r="0.358391" fill="url(#paint50_linear_349_10189)"/>
    <circle cx="21.456" cy="40.0673" r="0.358391" fill="url(#paint51_linear_349_10189)"/>
    <circle cx="34.835" cy="43.8902" r="0.358391" fill="url(#paint52_linear_349_10189)"/>
    <circle cx="36.6279" cy="41.7398" r="0.358391" fill="url(#paint53_linear_349_10189)"/>
    <circle cx="23.7256" cy="42.4566" r="0.358391" fill="url(#paint54_linear_349_10189)"/>
    <circle cx="42.6006" cy="22.8646" r="0.358391" fill="url(#paint55_linear_349_10189)"/>
    <circle cx="43.0771" cy="21.5505" r="0.358391" fill="url(#paint56_linear_349_10189)"/>
    <circle cx="49.0537" cy="19.2806" r="0.358391" fill="url(#paint57_linear_349_10189)"/>
    <circle cx="49.0537" cy="14.1437" r="0.358391" fill="url(#paint58_linear_349_10189)"/>
    <circle cx="50.6045" cy="15.2189" r="0.358391" fill="url(#paint59_linear_349_10189)"/>
    <circle cx="45.8271" cy="14.0242" r="0.358391" fill="url(#paint60_linear_349_10189)"/>
    <circle cx="44.5107" cy="14.5021" r="0.358391" fill="url(#paint61_linear_349_10189)"/>
    <circle cx="38.1787" cy="13.6658" r="0.358391" fill="url(#paint62_linear_349_10189)"/>
    <circle cx="33.7607" cy="9.96249" r="0.358391" fill="url(#paint63_linear_349_10189)"/>
    <circle cx="33.5225" cy="7.93163" r="0.358391" fill="url(#paint64_linear_349_10189)"/>
    <circle cx="34.1201" cy="6.25912" r="0.358391" fill="url(#paint65_linear_349_10189)"/>
    <circle cx="36.0303" cy="6.97588" r="0.358391" fill="url(#paint66_linear_349_10189)"/>
    <circle cx="37.8232" cy="6.73696" r="0.358391" fill="url(#paint67_linear_349_10189)"/>
    <circle cx="39.0185" cy="7.69271" r="0.358391" fill="url(#paint68_linear_349_10189)"/>
    <circle cx="38.181" cy="8.4095" r="0.477855" fill="url(#paint69_linear_349_10189)"/>
    <circle cx="39.9142" cy="12.531" r="0.418123" fill="url(#paint70_linear_349_10189)"/>
    <circle cx="46.5443" cy="17.4887" r="0.477855" fill="url(#paint71_linear_349_10189)"/>
    <circle cx="36.388" cy="15.6967" r="0.477855" fill="url(#paint72_linear_349_10189)"/>
    <circle cx="32.8043" cy="18.086" r="0.597318" fill="url(#paint73_linear_349_10189)"/>
    <circle cx="32.8043" cy="20.3558" r="0.597318" fill="url(#paint74_linear_349_10189)"/>
    <circle cx="36.0309" cy="20.3558" r="0.597318" fill="url(#paint75_linear_349_10189)"/>
    <circle cx="37.3434" cy="24.4176" r="0.597318" fill="url(#paint76_linear_349_10189)"/>
    <circle cx="33.9997" cy="23.9397" r="0.597318" fill="url(#paint77_linear_349_10189)"/>
    <circle cx="32.5661" cy="23.9397" r="0.597318" fill="url(#paint78_linear_349_10189)"/>
    <circle cx="32.445" cy="21.9088" r="0.597318" fill="url(#paint79_linear_349_10189)"/>
    <circle cx="31.1325" cy="17.1303" r="0.597318" fill="url(#paint80_linear_349_10189)"/>
    <circle cx="35.7926" cy="23.8203" r="0.597318" fill="url(#paint81_linear_349_10189)"/>
    <circle cx="34.595" cy="17.0109" r="0.477855" fill="url(#paint82_linear_349_10189)"/>
    <circle cx="42.959" cy="19.4002" r="0.716782" fill="url(#paint83_linear_349_10189)"/>
    <circle cx="41.7676" cy="26.5679" r="0.716782" fill="url(#paint84_linear_349_10189)"/>
    <circle cx="33.2832" cy="26.0901" r="0.716782" fill="url(#paint85_linear_349_10189)"/>
    <circle cx="39.6152" cy="24.0592" r="0.716782" fill="url(#paint86_linear_349_10189)"/>
    <circle cx="35.6738" cy="27.0458" r="0.716782" fill="url(#paint87_linear_349_10189)"/>
    <circle cx="38.0605" cy="32.1828" r="0.716782" fill="url(#paint88_linear_349_10189)"/>
    <circle cx="36.748" cy="34.3331" r="0.716782" fill="url(#paint89_linear_349_10189)"/>
    <circle cx="38.0605" cy="27.7626" r="0.716782" fill="url(#paint90_linear_349_10189)"/>
    <circle cx="33.2832" cy="34.0941" r="0.716782" fill="url(#paint91_linear_349_10189)"/>
    <circle cx="37.1074" cy="38.8727" r="0.716782" fill="url(#paint92_linear_349_10189)"/>
    <circle cx="33.2251" cy="39.7687" r="0.537586" fill="url(#paint93_linear_349_10189)"/>
    <circle cx="27.9673" cy="38.4546" r="0.537586" fill="url(#paint94_linear_349_10189)"/>
    <circle cx="26.2915" cy="37.3794" r="0.537586" fill="url(#paint95_linear_349_10189)"/>
    <circle cx="24.6833" cy="37.917" r="0.597318" fill="url(#paint96_linear_349_10189)"/>
    <circle cx="25.1598" cy="41.5009" r="0.597318" fill="url(#paint97_linear_349_10189)"/>
    <circle cx="23.8434" cy="44.8459" r="0.597318" fill="url(#paint98_linear_349_10189)"/>
    <circle cx="25.1001" cy="43.2331" r="0.537586" fill="url(#paint99_linear_349_10189)"/>
    <circle cx="34.4785" cy="38.5143" r="0.716782" fill="url(#paint100_linear_349_10189)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.31456 21.4855C5.36358 21.4583 5.42325 21.4583 5.47222 21.4857L10.488 24.2902C10.5389 24.3187 10.5705 24.3724 10.5708 24.4308C10.571 24.4891 10.5398 24.5431 10.4891 24.572L5.47337 27.4304C5.42541 27.4577 5.36682 27.4587 5.31796 27.433L0.086502 24.6825C0.033924 24.6548 0.000722073 24.6006 1.16263e-05 24.5412C-0.000698821 24.4818 0.0311964 24.4268 0.0830982 24.3979L5.31456 21.4855ZM0.50174 24.5352L5.39013 27.1054L10.0799 24.4328L5.39309 21.8122L0.50174 24.5352Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33758 27.1281C5.42694 27.1281 5.49938 27.2005 5.49938 27.2898V33.2882L26.1791 44.568C26.2576 44.6108 26.2865 44.7091 26.2437 44.7876C26.2009 44.866 26.1026 44.8949 26.0242 44.8521L5.2601 33.5263C5.20812 33.4979 5.17578 33.4434 5.17578 33.3842V27.2898C5.17578 27.2005 5.24822 27.1281 5.33758 27.1281Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.8462 31.5176C33.8958 31.4896 33.9565 31.4896 34.006 31.5178L41.8801 35.9942C41.9578 36.0383 41.985 36.1371 41.9408 36.2148C41.8967 36.2925 41.7979 36.3196 41.7202 36.2755L33.9259 31.8445L12.9721 43.7183C12.8943 43.7623 12.7956 43.735 12.7515 43.6573C12.7075 43.5795 12.7348 43.4808 12.8125 43.4367L33.8462 31.5176Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9235 7.49661C34.0129 7.49661 34.0853 7.56905 34.0853 7.65841V31.6584C34.0853 31.7478 34.0129 31.8202 33.9235 31.8202C33.8342 31.8202 33.7617 31.7478 33.7617 31.6584V7.65841C33.7617 7.56905 33.8342 7.49661 33.9235 7.49661Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.24999 7.85104C7.29252 7.77245 7.3907 7.74321 7.46929 7.78573L18.6333 13.8262C18.7119 13.8687 18.7412 13.9669 18.6986 14.0455C18.6561 14.1241 18.5579 14.1533 18.4794 14.1108L7.3153 8.07034C7.23671 8.02781 7.20747 7.92963 7.24999 7.85104Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0202306 12.1104C0.0634672 12.0322 0.161913 12.0039 0.240114 12.0471L11.4581 18.2494C11.5363 18.2926 11.5646 18.391 11.5214 18.4692C11.4782 18.5475 11.3797 18.5758 11.3015 18.5326L0.0835408 12.3303C0.00533892 12.2871 -0.023006 12.1886 0.0202306 12.1104Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6382 7.63312C18.682 7.71104 18.6543 7.80967 18.5763 7.85341L5.49938 15.1958V21.6269C5.49938 21.7163 5.42694 21.7887 5.33758 21.7887C5.24822 21.7887 5.17578 21.7163 5.17578 21.6269V15.1011C5.17578 15.0426 5.20736 14.9887 5.25837 14.96L18.4179 7.57125C18.4958 7.5275 18.5945 7.5552 18.6382 7.63312Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.2385 4.34067C28.2837 4.41778 28.2578 4.51689 28.1807 4.56206L20.6301 8.98453C20.5807 9.01348 20.5196 9.01414 20.4696 8.98626L12.919 4.77952C12.841 4.73602 12.8129 4.63749 12.8564 4.55943C12.8999 4.48137 12.9985 4.45334 13.0765 4.49683L20.5463 8.65858L28.0171 4.28283C28.0942 4.23767 28.1933 4.26356 28.2385 4.34067Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0006 4.47635C13.0899 4.4766 13.1622 4.54924 13.1619 4.63859L13.0541 43.5779C13.0538 43.6673 12.9812 43.7395 12.8918 43.7393C12.8025 43.739 12.7302 43.6664 12.7305 43.577L12.8383 4.6377C12.8386 4.54834 12.9112 4.4761 13.0006 4.47635Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5479 0.0538952C20.6373 0.0535654 20.71 0.125737 20.7103 0.215095L20.7642 14.8308C20.7646 14.9202 20.6924 14.9929 20.603 14.9932C20.5137 14.9936 20.441 14.9214 20.4407 14.832L20.3867 0.216289C20.3864 0.126931 20.4586 0.0542249 20.5479 0.0538952Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0987 4.3685C28.1881 4.36817 28.2608 4.44034 28.2611 4.5297L28.315 19.1454C28.3154 19.2348 28.2432 19.3075 28.1538 19.3078C28.0645 19.3082 27.9918 19.236 27.9914 19.1466L27.9375 4.53089C27.9372 4.44154 28.0093 4.36883 28.0987 4.3685Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8802 25.2687C19.9242 25.3465 19.8967 25.4452 19.8189 25.4892L0.241389 36.5454C0.163581 36.5893 0.0648834 36.5618 0.0209422 36.484C-0.022999 36.4062 0.0044555 36.3075 0.0822636 36.2636L19.6598 25.2074C19.7376 25.1635 19.8363 25.1909 19.8802 25.2687Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9974 29.2597C27.0414 29.3375 27.0139 29.4362 26.9361 29.4802L7.35858 40.5364C7.28077 40.5803 7.18207 40.5528 7.13813 40.475C7.09419 40.3972 7.12164 40.2985 7.19945 40.2546L26.777 29.1984C26.8548 29.1545 26.9535 29.1819 26.9974 29.2597Z" fill="#ADE3E3"/>
    <g filter="url(#filter0_f_349_10189)">
      <path d="M23.9307 12.7281L11.7656 20.1744V34.4556L23.8668 41.4202L36.5746 33.6518V20.6249L23.9307 12.7281Z" fill="#24C8BC"/>
    </g>
    <g filter="url(#filter1_dd_349_10189)">
      <path d="M20.7297 12.782L10.4688 19.0247V30.9972L20.6758 36.836L31.3946 30.3233V19.4023L20.7297 12.782Z" fill="url(#paint101_linear_349_10189)"/>
    </g>
    <path d="M20.7159 24.5932V36.8359L10.4688 31.1191V19.0382L20.7159 24.5932Z" fill="url(#paint102_linear_349_10189)"/>
    <path d="M31.342 19.254L31.4498 30.3641L20.6094 36.7821V24.6472L31.342 19.254Z" fill="url(#paint103_linear_349_10189)"/>
    <defs>
      <filter id="filter0_f_349_10189" x="0.547646" y="1.51008" width="47.2446" height="51.1281" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="5.60899" result="effect1_foregroundBlur_349_10189"/>
      </filter>
      <filter id="filter1_dd_349_10189" x="6.80133" y="11.3798" width="29.2314" height="31.3888" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1.61798" dy="2.1573"/>
        <feGaussianBlur stdDeviation="1.51011"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.631373 0 0 0 0 0.631373 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_349_10189"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2.26517"/>
        <feGaussianBlur stdDeviation="1.83371"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_349_10189" result="effect2_dropShadow_349_10189"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_349_10189" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_349_10189" x1="32.5654" y1="15.0994" x2="33.5809" y2="16.5927" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint1_linear_349_10189" x1="28.9834" y1="13.427" x2="29.9988" y2="14.9203" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint2_linear_349_10189" x1="30.6553" y1="9.00677" x2="31.6707" y2="10.5001" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint3_linear_349_10189" x1="35.4365" y1="10.9182" x2="36.452" y2="12.4115" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint4_linear_349_10189" x1="42.124" y1="11.1571" x2="43.1395" y2="12.6504" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint5_linear_349_10189" x1="24.4443" y1="11.3961" x2="25.4598" y2="12.8894" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint6_linear_349_10189" x1="22.4092" y1="9.72354" x2="23.4246" y2="11.2168" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint7_linear_349_10189" x1="27.5498" y1="10.5598" x2="28.5652" y2="12.0531" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint8_linear_349_10189" x1="36.0303" y1="8.52893" x2="37.0457" y2="10.0222" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint9_linear_349_10189" x1="39.8545" y1="13.9048" x2="40.8699" y2="15.3981" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint10_linear_349_10189" x1="34.2412" y1="21.0726" x2="35.2566" y2="22.5659" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint11_linear_349_10189" x1="37.9443" y1="20.2364" x2="38.9598" y2="21.7297" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint12_linear_349_10189" x1="35.5537" y1="30.0324" x2="36.5691" y2="31.5257" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint13_linear_349_10189" x1="33.4014" y1="27.2848" x2="34.4168" y2="28.7781" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint14_linear_349_10189" x1="33.1631" y1="30.0324" x2="34.1785" y2="31.5257" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint15_linear_349_10189" x1="30.1787" y1="32.4217" x2="31.1941" y2="33.915" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint16_linear_349_10189" x1="31.6123" y1="31.1075" x2="32.6277" y2="32.6008" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint17_linear_349_10189" x1="29.5771" y1="37.2002" x2="30.5926" y2="38.6935" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint18_linear_349_10189" x1="33.6396" y1="35.6472" x2="34.6551" y2="37.1405" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint19_linear_349_10189" x1="29.46" y1="34.572" x2="30.4754" y2="36.0653" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint20_linear_349_10189" x1="31.6123" y1="35.0499" x2="32.6277" y2="36.5432" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint21_linear_349_10189" x1="29.8193" y1="41.023" x2="30.8348" y2="42.5163" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint22_linear_349_10189" x1="26.4756" y1="42.0982" x2="27.491" y2="43.5915" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint23_linear_349_10189" x1="27.667" y1="35.7666" x2="28.6824" y2="37.2599" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint24_linear_349_10189" x1="27.7881" y1="39.9479" x2="28.8035" y2="41.4412" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint25_linear_349_10189" x1="25.6396" y1="38.8727" x2="26.6551" y2="40.366" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint26_linear_349_10189" x1="22.0537" y1="37.0807" x2="23.0691" y2="38.574" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint27_linear_349_10189" x1="23.249" y1="38.9922" x2="24.2645" y2="40.4855" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint28_linear_349_10189" x1="46.1865" y1="22.5062" x2="47.202" y2="23.9995" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint29_linear_349_10189" x1="29.0533" y1="6.97592" x2="29.9237" y2="8.25589" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint30_linear_349_10189" x1="23.9127" y1="7.21484" x2="24.783" y2="8.49481" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint31_linear_349_10189" x1="31.7994" y1="11.5155" x2="32.6698" y2="12.7955" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint32_linear_349_10189" x1="26.3033" y1="13.3074" x2="27.1737" y2="14.5874" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint33_linear_349_10189" x1="35.7408" y1="17.3693" x2="36.6112" y2="18.6492" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint34_linear_349_10189" x1="25.3463" y1="9.00677" x2="26.2166" y2="10.2867" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint35_linear_349_10189" x1="39.4439" y1="18.4444" x2="40.3143" y2="19.7244" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint36_linear_349_10189" x1="41.4939" y1="15.9357" x2="42.0741" y2="16.789" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint37_linear_349_10189" x1="43.7191" y1="16.7719" x2="44.5169" y2="17.9452" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint38_linear_349_10189" x1="45.9886" y1="26.0901" x2="46.7865" y2="27.2634" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint39_linear_349_10189" x1="40.7308" y1="21.3115" x2="41.5287" y2="22.4848" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint40_linear_349_10189" x1="37.7464" y1="16.533" x2="38.5443" y2="17.7063" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint41_linear_349_10189" x1="48.9661" y1="21.0726" x2="49.4013" y2="21.7126" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint42_linear_349_10189" x1="49.0833" y1="22.5062" x2="49.5185" y2="23.1462" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint43_linear_349_10189" x1="49.8059" y1="25.1344" x2="50.2411" y2="25.7744" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint44_linear_349_10189" x1="44.1888" y1="23.1035" x2="44.6239" y2="23.7435" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint45_linear_349_10189" x1="40.845" y1="23.1035" x2="41.2802" y2="23.7435" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint46_linear_349_10189" x1="39.888" y1="27.1653" x2="40.3232" y2="27.8053" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint47_linear_349_10189" x1="32.0013" y1="37.917" x2="32.4364" y2="38.557" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint48_linear_349_10189" x1="32.9583" y1="41.6204" x2="33.3935" y2="42.2604" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint49_linear_349_10189" x1="32.9583" y1="44.4875" x2="33.3935" y2="45.1275" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint50_linear_349_10189" x1="22.0872" y1="41.5009" x2="22.5224" y2="42.1409" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint51_linear_349_10189" x1="21.2513" y1="39.709" x2="21.6864" y2="40.3489" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint52_linear_349_10189" x1="34.6302" y1="43.5318" x2="35.0653" y2="44.1718" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint53_linear_349_10189" x1="36.4231" y1="41.3814" x2="36.8583" y2="42.0214" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint54_linear_349_10189" x1="23.5208" y1="42.0982" x2="23.956" y2="42.7382" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint55_linear_349_10189" x1="42.3958" y1="22.5062" x2="42.831" y2="23.1462" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint56_linear_349_10189" x1="42.8723" y1="21.1921" x2="43.3075" y2="21.8321" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint57_linear_349_10189" x1="48.8489" y1="18.9222" x2="49.2841" y2="19.5622" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint58_linear_349_10189" x1="48.8489" y1="13.7853" x2="49.2841" y2="14.4253" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint59_linear_349_10189" x1="50.3997" y1="14.8605" x2="50.8349" y2="15.5005" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint60_linear_349_10189" x1="45.6223" y1="13.6658" x2="46.0575" y2="14.3058" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint61_linear_349_10189" x1="44.3059" y1="14.1437" x2="44.7411" y2="14.7837" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint62_linear_349_10189" x1="37.9739" y1="13.3074" x2="38.4091" y2="13.9474" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint63_linear_349_10189" x1="33.5559" y1="9.6041" x2="33.9911" y2="10.2441" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint64_linear_349_10189" x1="33.3177" y1="7.57324" x2="33.7528" y2="8.21323" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint65_linear_349_10189" x1="33.9153" y1="5.90073" x2="34.3505" y2="6.54071" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint66_linear_349_10189" x1="35.8255" y1="6.61749" x2="36.2607" y2="7.25748" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint67_linear_349_10189" x1="37.6184" y1="6.37857" x2="38.0536" y2="7.01855" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint68_linear_349_10189" x1="38.8138" y1="7.33432" x2="39.2489" y2="7.9743" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint69_linear_349_10189" x1="37.9079" y1="7.93164" x2="38.4882" y2="8.78495" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint70_linear_349_10189" x1="39.6753" y1="12.1129" x2="40.183" y2="12.8595" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint71_linear_349_10189" x1="46.2712" y1="17.0109" x2="46.8515" y2="17.8642" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint72_linear_349_10189" x1="36.115" y1="15.2189" x2="36.6952" y2="16.0722" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint73_linear_349_10189" x1="32.463" y1="17.4886" x2="33.1883" y2="18.5553" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint74_linear_349_10189" x1="32.463" y1="19.7585" x2="33.1883" y2="20.8252" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint75_linear_349_10189" x1="35.6896" y1="19.7585" x2="36.4149" y2="20.8252" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint76_linear_349_10189" x1="37.0021" y1="23.8203" x2="37.7274" y2="24.8869" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint77_linear_349_10189" x1="33.6583" y1="23.3424" x2="34.3837" y2="24.409" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint78_linear_349_10189" x1="32.2247" y1="23.3424" x2="32.9501" y2="24.409" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint79_linear_349_10189" x1="32.1036" y1="21.3115" x2="32.829" y2="22.3782" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint80_linear_349_10189" x1="30.7911" y1="16.533" x2="31.5165" y2="17.5997" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint81_linear_349_10189" x1="35.4513" y1="23.223" x2="36.1766" y2="24.2896" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint82_linear_349_10189" x1="34.322" y1="16.533" x2="34.9022" y2="17.3863" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint83_linear_349_10189" x1="42.5494" y1="18.6834" x2="43.4198" y2="19.9633" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint84_linear_349_10189" x1="41.358" y1="25.8512" x2="42.2284" y2="27.1311" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint85_linear_349_10189" x1="32.8736" y1="25.3733" x2="33.744" y2="26.6533" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint86_linear_349_10189" x1="39.2056" y1="23.3424" x2="40.076" y2="24.6224" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint87_linear_349_10189" x1="35.2642" y1="26.329" x2="36.1346" y2="27.609" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint88_linear_349_10189" x1="37.6509" y1="31.466" x2="38.5213" y2="32.7459" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint89_linear_349_10189" x1="36.3384" y1="33.6163" x2="37.2088" y2="34.8962" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint90_linear_349_10189" x1="37.6509" y1="27.0458" x2="38.5213" y2="28.3258" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint91_linear_349_10189" x1="32.8736" y1="33.3773" x2="33.744" y2="34.6573" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint92_linear_349_10189" x1="36.6978" y1="38.1559" x2="37.5682" y2="39.4359" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint93_linear_349_10189" x1="32.9179" y1="39.2311" x2="33.5707" y2="40.1911" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint94_linear_349_10189" x1="27.6601" y1="37.917" x2="28.3129" y2="38.877" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint95_linear_349_10189" x1="25.9843" y1="36.8418" x2="26.6371" y2="37.8018" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint96_linear_349_10189" x1="24.3419" y1="37.3196" x2="25.0672" y2="38.3863" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint97_linear_349_10189" x1="24.8185" y1="40.9035" x2="25.5438" y2="41.9702" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint98_linear_349_10189" x1="23.5021" y1="44.2486" x2="24.2274" y2="45.3152" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint99_linear_349_10189" x1="24.7929" y1="42.6955" x2="25.4457" y2="43.6555" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint100_linear_349_10189" x1="34.0689" y1="37.7975" x2="34.9393" y2="39.0775" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint101_linear_349_10189" x1="31.3946" y1="30.1483" x2="10.6845" y2="19.0382" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F3F1F1"/>
        <stop offset="1" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint102_linear_349_10189" x1="10.8463" y1="18.9842" x2="20.7159" y2="37.3213" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
        <stop offset="1" stop-color="#FBFBFB" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint103_linear_349_10189" x1="20.6094" y1="24.7012" x2="30.6914" y2="31.0746" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0.7"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "BurgerMenuLogo"
}
</script>

<style scoped>

</style>