<template>
  <main>
    <div class="my-orders">
      <div class="my-orders__header">
        <div class="my-orders__buttons">
          <Button2
              name="Все заказы"
              :class="{ 'active': active === '' }"
              @click="selectStatusOrders('')"
          />
          <Button2
              name="Выполняются"
              :class="{ 'active': active === 'new' }"
              @click="selectStatusOrders('new')"
          />
          <Button2
              name="Отмененные"
              :class="{ 'active': active === 'canceled' }"
              @click="selectStatusOrders('canceled')"
          />
        </div>
        <Input
            type="search"
            placeholder="Искать заказ..."
            icon="svg/search.svg"
            padding="10px 12px 10px 46px"
            width="270px"
            @custom-input="setSearch"
            @blur-input="searchOrders"
            @enter="searchOrders"
        />
      </div>

      <div class="my-orders__content my-orders__content_mt-30">
        <Preloader v-if="orders?.length === 0"/>
        <template v-if="orders?.length">
          <OperationWithOrder
              v-for="order in orders"
              :key="order.id"
              :order="order"
          />
<!--          v-if="operation.type === 'order'"-->
<!--          <OperationWithBalance-->
<!--              :card="operation"-->
<!--              v-if="operation.type === 'card'"-->
<!--          />-->
        </template>
        <NoData
            class="my-orders__icon"
            name="У вас все еще нет ни одного заказа"
            v-if="orders === null"
        />
      </div>
    </div>
  </main>
</template>

<script>
import Button2 from "@/components/Buttons/Button2.vue";
import Input from "@/components/Utils/Input.vue";
import OperationWithOrder from "@/components/Operations/OperationWithOrder.vue";
import OperationWithBalance from "@/components/Operations/OperationWithBalance.vue";
import NoData from "@/components/Operations/NoData.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import Preloader from "@/components/Utils/Preloader.vue";

export default {
  name: "MyOrders",
  components: {
    Preloader,
    NoData,
    OperationWithBalance,
    OperationWithOrder,
    Input,
    Button2
  },
  data() {
    return {
      active: '',
      //'', 'delivered', 'canceled'
      search: '',
    }
  },
  methods: {
    ...mapActions('orders', ['getListOrders']),
    ...mapMutations('orders', ['SET_ORDERS']),

    setSearch(data) {
      this.search = data
    },
    searchOrders() {
      this.SET_ORDERS([])
      this.getListOrders({ status: this.active, q: this.search })
    },
    selectStatusOrders(status) {
      this.SET_ORDERS([])
      this.active = status
      this.getListOrders({ status, q: this.search })
    }
  },
  computed: {
    ...mapState('orders', ['orders']),
  },
  mounted() {
    this.getListOrders()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/myOrders";
</style>