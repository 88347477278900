<template>
  <main>
    <div class="make-order">
      <div class="container">
        <h1>Оформление заказа</h1>

        <div class="make-order__body make-order__body_mt-42">
          <div class="make-order__content">
            <div class="make-order__title make-order__title_mb-26">
              Заказанные товары
              <span>
                {{ getCountOfProducts }}
                {{ getProductQuantityEnding }}
                на сумму
                <span>₽{{ getPrice(getProductPrice) }}</span>
              </span>
            </div>
            <MakeOrderTable :edited="false" :products="getProductsForMakeOrder"/>
            <div class="make-order__title make-order__title_mt-25 make-order__title_mb-24">Оплата</div>
            <div class="make-order__payment border-bottom">
              <Radio
                  v-for="(radio, idx) in radioButtons"
                  :key="radio.id"
                  :idx="idx"
                  :title="radio.title"
                  :active="radio.id === idActiveRadio"
                  :class="`make-order__payment-method-${idx + 1}`"
                  @click="setActiveRadio(radio.id)"
              />

              <div class="make-order__payment-method text" v-if="idActiveRadio === 1">
                С вами свяжется консультант
              </div>
<!--              <PaymentByCard-->
<!--                  class="make-order__payment-method card"-->
<!--                  v-show="idxActiveRadio === 1"-->
<!--              />-->
              <PaymentByCryptocurrency
                  class="make-order__payment-method cryptocurrency"
                  v-show="idActiveRadio === 3"
              />
            </div>
            <div class="make-order__title make-order__title_mt-25 make-order__title_mb-24">Доставка</div>
            <div class="make-order__delivery border-bottom">
              <Select
                  title="Адрес доставки"
                  button-text="Ввести другой адрес"
                  max-width="100%"
                  min-height="41.45px"
                  :dropdown="getAddressesForSelect"
                  :default-value="getAddressesForSelect[0]"
                  :update-default-value="true"
                  @select-button="showEnterShippingAddressModal = true"
                  @custom-select="selectAddress"
              />
            </div>
            <div class="make-order__title make-order__title_mt-25 make-order__title_mb-24">
              Контактные данные получателя
            </div>
            <div class="make-order__recipient-data">
              <Input
                  :error="errors.first_name"
                  placeholder="Введите свое имя"
                  title="Имя"
                  width="100%"
                  @custom-input="setFirstName"
              />
              <Input
                  :error="errors.last_name"
                  placeholder="Введите свою фамилию"
                  title="Фамилия"
                  width="100%"
                  @custom-input="setLastName"
              />
              <Input
                  :error="errors.email"
                  type="email"
                  placeholder="Введите свой email"
                  title="Email"
                  width="100%"
                  @custom-input="setEmail"
              />
              <InputTel
                  :error="errors.phone"
                  @set-code="setPhoneCode"
                  @custom-input="setPhone"
                  v-if="masks?.length"
              />
<!--              <Input mask="+375 (##) ###-##-##" placeholder="000 000 000" title="Телефон" width="100%"/>-->
            </div>
          </div>
          <MakeOrderSidebar
              :button-disabled="orderCreationInProgress"
              :count="getCountOfProducts"
              :ending="getProductQuantityEnding"
              :price="getProductPrice"
              @make-order="makeOrder"
          />
        </div>

      </div>
    </div>
  </main>
  <EnterShippingAddressModal
      v-if="showEnterShippingAddressModal"
      @close="showEnterShippingAddressModal = false"
  />
</template>

<script>
import MakeOrderTable from "@/components/Views/MakeOrder/MakeOrderTable.vue";
import Radio from "@/components/Utils/Radio.vue";
import Select from "@/components/Utils/Select.vue";
import Input from "@/components/Utils/Input.vue";
import MakeOrderSidebar from "@/components/Views/MakeOrder/MakeOrderSidebar.vue";
import PaymentByCard from "@/components/Views/MakeOrder/PaymentByCard.vue";
import PaymentByCryptocurrency from "@/components/Views/MakeOrder/PaymentByCryptocurrency.vue";
import InputTel from "@/components/Utils/InputTel.vue";
import EnterShippingAddressModal from "@/components/Modals/EnterShippingAddressModal.vue";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "MakeOrder",
  components: {
    EnterShippingAddressModal,
    InputTel,
    PaymentByCryptocurrency,
    PaymentByCard,
    MakeOrderSidebar,
    Input,
    Select,
    Radio,
    MakeOrderTable
  },
  data() {
    return {
      radioButtons: [
        { id: 1, title: 'Оплата при получении товара', value: 'cash' },
        { id: 2, title: 'Оплата при помощи карты', value: 'card' },
        // { id: 3, title: 'Оплата криптовалютой', value: 'crypto' }
      ],
      idActiveRadio: 1,
      showEnterShippingAddressModal: false,
      contact_data: {
        last_name: '',
        first_name: '',
        email: '',
        phone: '',
      },
      phoneCode: '',
      selectedAddress: null,
      orderCreationInProgress: false,
      errors: {
        last_name: true,
        first_name: true,
        email: true,
        phone: true,
      }
    }
  },
  methods: {
    ...mapActions('orders', ['makeNewOrder']),
    ...mapActions('addresses', ['getAddresses']),
    ...mapActions('invoice', ['pay']),

    setActiveRadio(id) {
      this.idActiveRadio = id
    },
    setFirstName(firstName) {
      this.contact_data.first_name = firstName
    },
    setLastName(lastName) {
      this.contact_data.last_name = lastName
    },
    setEmail(email) {
      const regExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      this.errors.email = regExp.test(String(email).toLowerCase())

      this.contact_data.email = email
    },
    setPhone(phone, error) {
      this.errors.phone = error
      this.contact_data.phone = phone
    },
    setPhoneCode(phoneCode) {
      this.phoneCode = phoneCode
    },
    selectAddress(address) {
      this.selectedAddress = address
    },
    async makeOrder() {
      this.orderCreationInProgress = true
      const newOrder = await this.makeNewOrder(this.getOrderForAdd)
      for (const key in this.errors) {
        this.errors[key] = !!this.contact_data[key]
      }

      if (!Object.values(this.errors).includes(false)) {
        if (newOrder?.success === true && this.contact_data.phone) {
          switch (this.idActiveRadio) {
            case 1:
              this.$router.push('/success-payment')
              break;
            case 2:
              const response = await this.pay({orderId: newOrder.data.id, data: { payment_type: 'card' }});

              if (response?.success) {
                const link = response?.is_free ? 'https://nanobox.bio/success-payment' : response.result
                this.windowOpen(link, '_self')
              } else {
                this.$router.push('/rejected-payment')
              }
              break;
          }
        } else {
          this.$router.push('/rejected-payment')
        }
      }

      this.orderCreationInProgress = false
    }
  },
  computed: {
    ...mapState('auth', ['token']),
    ...mapState('products', ['productForFastBuy', 'basket']),
    ...mapState('addresses', ['addresses']),
    ...mapState('phoneMask', ['masks']),
    ...mapGetters('products', ['getProductForFastBuy']),

    getAddressesForSelect() {
      try {
        const addresses = this.addresses?.map(address => new Object({
          id: address.id,
          value: `${address.country.name}, г. ${address.city.name}, ул. ${address.street}, д. ${address.house}${address.flat ? `, кв. ${address.flat}` : ''}`
        }))

        return this.addresses?.length ? addresses : []
      } catch (e) {
        return []
      }
    },
    getContactData() {
      return {
        ...this.contact_data,
        phone: `${this.phoneCode} ${this.contact_data.phone}`
      }
    },
    getProductsForMakeOrder() {
      return this.isFastBuy ? [this.getProductForFastBuy] : this.getBasket
    },
    getBasket() {
      return this.basket.map(item => new Object({
        product: {
          id: item.id,
          name: item.name,
          price: item.price,
          images: item.images,
        },
        price: item.price * item.amount,
        amount: item.amount,
        type: item.type,
      }))
    },
    isFastBuy() {
      return !!this.productForFastBuy
    },
    getCountOfProducts() {
      return this.isFastBuy ? 1 : this.getBasket.length
    },
    getProductQuantityEnding() {
      const endings = {
        1: 'товар',
        2: 'товара',
        3: 'товара',
        4: 'товара',
        5: 'товаров',
        6: 'товаров',
        7: 'товаров',
        8: 'товаров',
        9: 'товаров',
        0: 'товаров'
      };

      const lastDigit = this.getCountOfProducts % 10;
      return endings[lastDigit];
    },
    getProductPrice() {
      return this.getProductsForMakeOrder.map(item => item.price).reduce((item1, item2) => item1 + item2, 0)
    },
    getOrderItems() {
      const getItemsFromBasket = array => {
        return array.map(item => new Object({
          id: item.product.id,
          amount: item.amount,
          price: item.price,
          type: item.type
        }))
      }

      return this.isFastBuy ? [{
        id: this.productForFastBuy.id,
        amount: 1,
        price: this.productForFastBuy.price,
        type: 'product'
      }] : getItemsFromBasket(this.getBasket)
    },
    getOrderForAdd() {
      return {
        delivery_price: 1000,
        address_id: this.selectedAddress.id,
        payment_type: this.radioButtons.find(radio => radio.id === this.idActiveRadio).value,
        contact_data: this.getContactData,
        items: this.getOrderItems,
      }
    }
  },
  mounted() {
    if (!this.token) this.$router.push('?login')
    if (this.productForFastBuy === null && !this.getBasket.length) this.$router.push('/catalog')
    this.getAddresses()
  },
}
</script>

<style scoped lang="scss">
main {
  margin: 20px 0 46px;
}

@import "@/assets/scss/Views/make-order/makeOrder";
</style>