<template>
  <div class="chart-select-period">
    <div class="chart-select-period__value" @click="showDropdown = !showDropdown">
      За {{ getLabel(selectedPeriod)[1] ?? getLabel(selectedPeriod)[0] }}
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.235034 0.204742C0.536173 -0.0796667 1.01085 -0.0661045 1.29526 0.235034L5 4.1577L8.70474 0.235034C8.98915 -0.0661045 9.46383 -0.0796667 9.76497 0.204742C10.0661 0.489151 10.0797 0.963831 9.79526 1.26497L5.54526 5.76497C5.40358 5.91499 5.20635 6 5 6C4.79366 6 4.59642 5.91499 4.45474 5.76497L0.204742 1.26497C-0.0796667 0.963831 -0.0661045 0.489151 0.235034 0.204742Z" fill="#FF8830"/>
      </svg>
    </div>
    <div
        class="chart-select-period__dropdown"
        :tabindex="1"
        @blur="showDropdown = false"
        v-if="showDropdown"
    >
      <div
          class="chart-select-period__dropdown-item"
          v-for="period in periods"
          :key="period"
          @click="selectPeriod(period)"
      >{{ getLabel(period)[0] }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartSelectPeriod",
  emits: ['select-period'],
  data() {
    return {
      periods: ['week', 'month', 'year'],
      selectedPeriod: 'week',
      showDropdown: false,
    }
  },
  methods: {
    selectPeriod(period) {
      this.selectedPeriod = period
      this.$emit('select-period', period)
      this.showDropdown = false
    },
    getLabel(periodValue) {
      return {
        week: ['неделя', 'неделю'],
        month: ['месяц'],
        year: ['год']
      }[periodValue]
    }
  },
  mounted() {
    this.$emit('select-period', this.selectedPeriod)
  }
}
</script>

<style scoped lang="scss">
.chart-select-period {
  position: relative;

  &__value {
    display: flex;
    align-items: center;
    column-gap: 6px;

    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FF8830;
    cursor: pointer;
  }

  &__dropdown {
    padding: 4px;
    border-radius: 6px;
    box-shadow: 0 16px 24px 0 rgba(20, 23, 37, .16);
    background: #FFFFFF;
    position: absolute;
    top: 20px;
    left: 0;

    &-item {
      padding: 4px 6px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0;
      color: #262626;
      text-transform: capitalize;
      cursor: pointer;
      transition: .25s all ease;

      &:hover {
        background: #F5F5F5;
        transition: .25s all ease;
      }
    }
  }
}
</style>