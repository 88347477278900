import {getBalance, getBalanceHistory, getStatistics, getGraph, getPartnerCount, getCabinetBalance} from "@/api";

export default {
  namespaced: true,
  state: {
    balance: {},
    cabinetBalance: 0,
    balanceHistory: [],
    partnerCount: 0,
    graph: {},
    statistics: [],
    selectedPeriod: ''
  },
  actions: {
    getBalance({ commit }) {
      getBalance().then(response => commit('SET_BALANCE', response.data))
    },
    getCabinetBalance({ commit }) {
      getCabinetBalance().then(response => commit('SET_CABINET_BALANCE', response?.balance))
    },
    getBalanceHistory({ commit }) {
      getBalanceHistory().then(response => {
        commit('SET_BALANCE_HISTORY', response.data?.length ? response.data : null)
      })
    },
    getPartnerCount({ commit }) {
      getPartnerCount().then(response => commit('SET_PARTNER_COUNT', response.data))
    },
    getGraph({ state, commit }, period) {
      if (state.selectedPeriod !== period) commit('SET_GRAPH', {})
      state.selectedPeriod = period
      getGraph(period).then(response => commit('SET_GRAPH', response.data))
    },
    getStatistics({ commit }) {
      getStatistics().then(response => commit('SET_STATISTICS', response.data))
    }
  },
  mutations: {
    SET_BALANCE(state, balance) {
      state.balance = balance
    },
    SET_CABINET_BALANCE(state, balance) {
      state.cabinetBalance = balance
    },
    SET_BALANCE_HISTORY(state, history) {
      state.balanceHistory = history
    },
    SET_PARTNER_COUNT(state, count) {
      state.partnerCount = count
    },
    SET_GRAPH(state, graph) {
      state.graph = graph
    },
    SET_STATISTICS(state, statistics) {
      state.statistics = statistics
    }
  }
}