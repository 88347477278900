<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="331" height="307" viewBox="0 0 331 307" fill="none">
    <path d="M-115.202 206.393V-6.85822L64.1505 -112.304L250.569 -7.79716V204.034L63.2058 306.66L-115.202 206.393Z" fill="white" stroke="#ADE3E3" stroke-width="0.595384"/>
    <circle cx="173.354" cy="26.6721" r="7.31101" fill="url(#paint0_linear_256_5528)"/>
    <circle cx="142.038" cy="12.0503" r="7.31101" fill="url(#paint1_linear_256_5528)"/>
    <circle cx="102.338" cy="-5.70515" r="7.31101" fill="url(#paint2_linear_256_5528)"/>
    <circle cx="237.065" cy="16.228" r="7.31101" fill="url(#paint3_linear_256_5528)"/>
    <circle cx="187.991" cy="78.8938" r="7.31101" fill="url(#paint4_linear_256_5528)"/>
    <circle cx="220.37" cy="71.5826" r="7.31101" fill="url(#paint5_linear_256_5528)"/>
    <circle cx="199.483" cy="157.226" r="7.31101" fill="url(#paint6_linear_256_5528)"/>
    <circle cx="180.67" cy="133.204" r="7.31101" fill="url(#paint7_linear_256_5528)"/>
    <circle cx="178.581" cy="157.226" r="7.31101" fill="url(#paint8_linear_256_5528)"/>
    <circle cx="152.471" cy="178.115" r="7.31101" fill="url(#paint9_linear_256_5528)"/>
    <circle cx="165.014" cy="166.625" r="7.31101" fill="url(#paint10_linear_256_5528)"/>
    <circle cx="147.241" cy="219.892" r="7.31101" fill="url(#paint11_linear_256_5528)"/>
    <circle cx="182.764" cy="206.314" r="7.31101" fill="url(#paint12_linear_256_5528)"/>
    <circle cx="146.206" cy="196.914" r="7.31101" fill="url(#paint13_linear_256_5528)"/>
    <circle cx="165.014" cy="201.092" r="7.31101" fill="url(#paint14_linear_256_5528)"/>
    <circle cx="149.338" cy="253.313" r="7.31101" fill="url(#paint15_linear_256_5528)"/>
    <circle cx="120.096" cy="262.713" r="7.31101" fill="url(#paint16_linear_256_5528)"/>
    <circle cx="130.545" cy="207.359" r="7.31101" fill="url(#paint17_linear_256_5528)"/>
    <circle cx="131.584" cy="243.914" r="7.31101" fill="url(#paint18_linear_256_5528)"/>
    <circle cx="112.795" cy="234.514" r="7.31101" fill="url(#paint19_linear_256_5528)"/>
    <circle cx="81.4555" cy="218.847" r="7.31101" fill="url(#paint20_linear_256_5528)"/>
    <circle cx="91.8891" cy="235.558" r="7.31101" fill="url(#paint21_linear_256_5528)"/>
    <circle cx="292.436" cy="91.427" r="7.31101" fill="url(#paint22_linear_256_5528)"/>
    <circle cx="166.048" cy="-5.70514" r="6.26658" fill="url(#paint23_linear_256_5528)"/>
    <circle cx="118.009" cy="9.96104" r="6.26658" fill="url(#paint24_linear_256_5528)"/>
    <circle cx="200.513" cy="45.472" r="6.26658" fill="url(#paint25_linear_256_5528)"/>
    <circle cx="232.892" cy="54.8716" r="6.26658" fill="url(#paint26_linear_256_5528)"/>
    <circle cx="249.607" cy="30.8499" r="4.17772" fill="url(#paint27_linear_256_5528)"/>
    <circle cx="269.971" cy="39.7274" r="5.74436" fill="url(#paint28_linear_256_5528)"/>
    <circle cx="289.815" cy="121.193" r="5.74436" fill="url(#paint29_linear_256_5528)"/>
    <circle cx="243.858" cy="79.4161" r="5.74436" fill="url(#paint30_linear_256_5528)"/>
    <circle cx="217.744" cy="37.6386" r="5.74436" fill="url(#paint31_linear_256_5528)"/>
    <circle cx="314.352" cy="74.7161" r="3.13329" fill="url(#paint32_linear_256_5528)"/>
    <circle cx="315.407" cy="87.2493" r="3.13329" fill="url(#paint33_linear_256_5528)"/>
    <circle cx="321.668" cy="110.227" r="3.13329" fill="url(#paint34_linear_256_5528)"/>
    <circle cx="272.583" cy="92.4712" r="3.13329" fill="url(#paint35_linear_256_5528)"/>
    <circle cx="243.336" cy="92.4712" r="3.13329" fill="url(#paint36_linear_256_5528)"/>
    <circle cx="234.977" cy="127.982" r="3.13329" fill="url(#paint37_linear_256_5528)"/>
    <circle cx="166.04" cy="221.981" r="3.13329" fill="url(#paint38_linear_256_5528)"/>
    <circle cx="174.403" cy="254.358" r="3.13329" fill="url(#paint39_linear_256_5528)"/>
    <circle cx="174.403" cy="279.424" r="3.13329" fill="url(#paint40_linear_256_5528)"/>
    <circle cx="79.3559" cy="253.314" r="3.13329" fill="url(#paint41_linear_256_5528)"/>
    <circle cx="72.0513" cy="237.647" r="3.13329" fill="url(#paint42_linear_256_5528)"/>
    <circle cx="189.024" cy="271.069" r="3.13329" fill="url(#paint43_linear_256_5528)"/>
    <circle cx="204.704" cy="252.269" r="3.13329" fill="url(#paint44_linear_256_5528)"/>
    <circle cx="91.8989" cy="258.535" r="3.13329" fill="url(#paint45_linear_256_5528)"/>
    <circle cx="256.922" cy="87.2493" r="3.13329" fill="url(#paint46_linear_256_5528)"/>
    <circle cx="261.094" cy="75.7603" r="3.13329" fill="url(#paint47_linear_256_5528)"/>
    <circle cx="313.313" cy="55.9161" r="3.13329" fill="url(#paint48_linear_256_5528)"/>
    <circle cx="313.313" cy="11.0059" r="3.13329" fill="url(#paint49_linear_256_5528)"/>
    <circle cx="326.899" cy="20.4055" r="3.13329" fill="url(#paint50_linear_256_5528)"/>
    <circle cx="285.11" cy="9.96102" r="3.13329" fill="url(#paint51_linear_256_5528)"/>
    <circle cx="273.633" cy="14.1392" r="3.13329" fill="url(#paint52_linear_256_5528)"/>
    <circle cx="218.262" cy="6.82775" r="3.13329" fill="url(#paint53_linear_256_5528)"/>
    <circle cx="233.409" cy="-3.09403" r="3.6555" fill="url(#paint54_linear_256_5528)"/>
    <circle cx="291.377" cy="40.2501" r="4.17772" fill="url(#paint55_linear_256_5528)"/>
    <circle cx="202.611" cy="24.5832" r="4.17772" fill="url(#paint56_linear_256_5528)"/>
    <circle cx="171.265" cy="45.4717" r="5.22215" fill="url(#paint57_linear_256_5528)"/>
    <circle cx="171.265" cy="65.316" r="5.22215" fill="url(#paint58_linear_256_5528)"/>
    <circle cx="199.468" cy="65.316" r="5.22215" fill="url(#paint59_linear_256_5528)"/>
    <circle cx="210.96" cy="100.827" r="5.22215" fill="url(#paint60_linear_256_5528)"/>
    <circle cx="181.714" cy="96.6489" r="5.22215" fill="url(#paint61_linear_256_5528)"/>
    <circle cx="169.191" cy="96.6489" r="5.22215" fill="url(#paint62_linear_256_5528)"/>
    <circle cx="168.128" cy="78.8939" r="5.22215" fill="url(#paint63_linear_256_5528)"/>
    <circle cx="156.644" cy="37.1163" r="5.22215" fill="url(#paint64_linear_256_5528)"/>
    <circle cx="197.394" cy="95.6042" r="5.22215" fill="url(#paint65_linear_256_5528)"/>
    <circle cx="186.936" cy="36.0719" r="4.17772" fill="url(#paint66_linear_256_5528)"/>
    <circle cx="260.056" cy="56.9609" r="6.26658" fill="url(#paint67_linear_256_5528)"/>
    <circle cx="249.603" cy="119.626" r="6.26658" fill="url(#paint68_linear_256_5528)"/>
    <circle cx="175.458" cy="115.449" r="6.26658" fill="url(#paint69_linear_256_5528)"/>
    <circle cx="230.813" cy="97.6933" r="6.26658" fill="url(#paint70_linear_256_5528)"/>
    <circle cx="196.345" cy="123.804" r="6.26658" fill="url(#paint71_linear_256_5528)"/>
    <circle cx="217.224" cy="168.715" r="6.26658" fill="url(#paint72_linear_256_5528)"/>
    <circle cx="205.735" cy="187.514" r="6.26658" fill="url(#paint73_linear_256_5528)"/>
    <circle cx="217.224" cy="130.071" r="6.26658" fill="url(#paint74_linear_256_5528)"/>
    <circle cx="175.458" cy="185.426" r="6.26658" fill="url(#paint75_linear_256_5528)"/>
    <circle cx="208.868" cy="227.203" r="6.26658" fill="url(#paint76_linear_256_5528)"/>
    <circle cx="174.926" cy="235.036" r="4.69993" fill="url(#paint77_linear_256_5528)"/>
    <circle cx="128.973" cy="223.547" r="4.69993" fill="url(#paint78_linear_256_5528)"/>
    <circle cx="114.352" cy="214.147" r="4.69993" fill="url(#paint79_linear_256_5528)"/>
    <circle cx="100.249" cy="218.847" r="5.22215" fill="url(#paint80_linear_256_5528)"/>
    <circle cx="104.437" cy="250.18" r="5.22215" fill="url(#paint81_linear_256_5528)"/>
    <circle cx="92.9448" cy="279.424" r="5.22215" fill="url(#paint82_linear_256_5528)"/>
    <circle cx="103.915" cy="265.324" r="4.69993" fill="url(#paint83_linear_256_5528)"/>
    <circle cx="185.892" cy="224.069" r="6.26658" fill="url(#paint84_linear_256_5528)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M-68.8745 124.524C-68.0933 124.524 -67.46 125.157 -67.46 125.938V178.38L113.336 276.996C114.022 277.37 114.274 278.229 113.9 278.915C113.526 279.601 112.667 279.853 111.981 279.479L-69.5519 180.461C-70.0063 180.213 -70.2891 179.737 -70.2891 179.219V125.938C-70.2891 125.157 -69.6558 124.524 -68.8745 124.524Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M180.327 162.9C180.76 162.655 181.29 162.655 181.723 162.901L250.564 202.037C251.243 202.423 251.481 203.286 251.095 203.966C250.709 204.645 249.845 204.882 249.166 204.496L181.023 165.757L-2.16907 269.566C-2.84876 269.951 -3.71199 269.713 -4.09714 269.033C-4.4823 268.353 -4.24354 267.49 -3.56385 267.105L180.327 162.9Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M181.024 -47.1076C181.805 -47.1076 182.438 -46.4743 182.438 -45.6931V164.131C182.438 164.912 181.805 165.545 181.024 165.545C180.243 165.545 179.609 164.912 179.609 164.131V-45.6931C179.609 -46.4743 180.243 -47.1076 181.024 -47.1076Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M-52.2004 -44.0088C-51.8286 -44.6959 -50.9702 -44.9515 -50.2831 -44.5798L47.3203 8.22984C48.0074 8.6016 48.263 9.45999 47.8913 10.1471C47.5195 10.8342 46.6611 11.0898 45.974 10.7181L-51.6294 -42.0915C-52.3165 -42.4633 -52.5722 -43.3217 -52.2004 -44.0088Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M47.4084 -45.914C47.7909 -45.2328 47.5487 -44.3705 46.8675 -43.988L-67.46 20.2041V76.4293C-67.46 77.2105 -68.0933 77.8438 -68.8745 77.8438C-69.6558 77.8438 -70.2891 77.2105 -70.2891 76.4293V19.376C-70.2891 18.8646 -70.013 18.393 -69.5671 18.1426L45.4824 -46.4548C46.1636 -46.8373 47.0259 -46.5952 47.4084 -45.914Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M64.0968 -112.177C64.878 -112.18 65.5137 -111.549 65.5166 -110.767L65.9881 17.013C65.991 17.7942 65.36 18.4299 64.5788 18.4328C63.7976 18.4356 63.1619 17.8047 63.159 17.0235L62.6875 -110.757C62.6846 -111.538 63.3156 -112.174 64.0968 -112.177Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M130.105 -74.4554C130.886 -74.4583 131.522 -73.8273 131.524 -73.0461L131.996 54.7342C131.999 55.5155 131.368 56.1511 130.587 56.154C129.805 56.1569 129.17 55.5259 129.167 54.7447L128.695 -73.0357C128.692 -73.8169 129.323 -74.4526 130.105 -74.4554Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M58.2515 108.268C58.6356 108.948 58.3956 109.811 57.7153 110.195L-113.444 206.856C-114.125 207.24 -114.987 207 -115.372 206.32C-115.756 205.64 -115.516 204.777 -114.835 204.392L56.3242 107.732C57.0044 107.348 57.8673 107.588 58.2515 108.268Z" fill="#ADE3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.486 143.16C120.87 143.84 120.63 144.703 119.95 145.087L-51.2099 241.748C-51.8902 242.132 -52.7531 241.892 -53.1372 241.211C-53.5214 240.531 -53.2814 239.668 -52.6011 239.284L118.559 142.624C119.239 142.24 120.102 142.48 120.486 143.16Z" fill="#ADE3E3"/>
    <g filter="url(#filter0_f_256_5528)">
      <path d="M93.6438 -1.37074L-12.7109 63.7301V188.585L93.0855 249.475L204.186 181.558V67.6684L93.6438 -1.37074Z" fill="#24C8BC"/>
    </g>
    <g filter="url(#filter1_dd_256_5528)">
      <path d="M65.6844 -0.898674L-24.0234 53.6783V158.35L65.2135 209.397L158.924 152.459V56.98L65.6844 -0.898674Z" fill="url(#paint85_linear_256_5528)"/>
    </g>
    <path d="M65.5643 102.362V209.396L-24.0234 159.416V53.7963L65.5643 102.362Z" fill="url(#paint86_linear_256_5528)"/>
    <path d="M158.445 55.683L159.388 152.815L64.6133 208.925V102.834L158.445 55.683Z" fill="url(#paint87_linear_256_5528)"/>
    <defs>
      <filter id="filter0_f_256_5528" x="-33.3509" y="-22.0107" width="258.178" height="292.126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="10.32" result="effect1_foregroundBlur_256_5528"/>
      </filter>
      <filter id="filter1_dd_256_5528" x="-30.7711" y="-3.47867" width="198.231" height="223.791" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="2.97692" dy="3.96923"/>
        <feGaussianBlur stdDeviation="2.77846"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.631373 0 0 0 0 0.631373 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_256_5528"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4.16769"/>
        <feGaussianBlur stdDeviation="3.37384"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_256_5528" result="effect2_dropShadow_256_5528"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_256_5528" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_256_5528" x1="169.176" y1="19.3611" x2="178.054" y2="32.4164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint1_linear_256_5528" x1="137.86" y1="4.73929" x2="146.738" y2="17.7947" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint2_linear_256_5528" x1="98.1606" y1="-13.0162" x2="107.038" y2="0.0392121" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint3_linear_256_5528" x1="232.887" y1="8.91702" x2="241.765" y2="21.9724" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint4_linear_256_5528" x1="183.813" y1="71.5828" x2="192.691" y2="84.6382" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint5_linear_256_5528" x1="216.192" y1="64.2716" x2="225.07" y2="77.3269" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint6_linear_256_5528" x1="195.305" y1="149.915" x2="204.183" y2="162.97" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint7_linear_256_5528" x1="176.493" y1="125.893" x2="185.37" y2="138.948" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint8_linear_256_5528" x1="174.403" y1="149.915" x2="183.28" y2="162.97" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint9_linear_256_5528" x1="148.293" y1="170.804" x2="157.171" y2="183.859" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint10_linear_256_5528" x1="160.836" y1="159.314" x2="169.714" y2="172.37" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint11_linear_256_5528" x1="143.063" y1="212.581" x2="151.941" y2="225.637" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint12_linear_256_5528" x1="178.586" y1="199.003" x2="187.464" y2="212.059" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint13_linear_256_5528" x1="142.028" y1="189.603" x2="150.905" y2="202.659" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint14_linear_256_5528" x1="160.836" y1="193.781" x2="169.714" y2="206.836" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint15_linear_256_5528" x1="145.161" y1="246.002" x2="154.038" y2="259.058" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint16_linear_256_5528" x1="115.918" y1="255.402" x2="124.796" y2="268.457" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint17_linear_256_5528" x1="126.368" y1="200.048" x2="135.245" y2="213.103" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint18_linear_256_5528" x1="127.407" y1="236.603" x2="136.284" y2="249.658" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint19_linear_256_5528" x1="108.618" y1="227.203" x2="117.495" y2="240.258" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint20_linear_256_5528" x1="77.2778" y1="211.536" x2="86.1555" y2="224.592" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint21_linear_256_5528" x1="87.7114" y1="228.247" x2="96.5891" y2="241.303" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint22_linear_256_5528" x1="288.258" y1="84.116" x2="297.136" y2="97.1714" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint23_linear_256_5528" x1="162.467" y1="-11.9717" x2="170.076" y2="-0.781396" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint24_linear_256_5528" x1="114.428" y1="3.69447" x2="122.037" y2="14.8848" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint25_linear_256_5528" x1="196.932" y1="39.2054" x2="204.541" y2="50.3957" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint26_linear_256_5528" x1="229.311" y1="48.605" x2="236.92" y2="59.7954" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint27_linear_256_5528" x1="247.22" y1="26.6722" x2="252.293" y2="34.1324" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint28_linear_256_5528" x1="266.688" y1="33.9831" x2="273.664" y2="44.2409" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint29_linear_256_5528" x1="286.532" y1="115.449" x2="293.507" y2="125.707" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint30_linear_256_5528" x1="240.575" y1="73.6717" x2="247.55" y2="83.9295" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint31_linear_256_5528" x1="214.462" y1="31.8942" x2="221.437" y2="42.152" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint32_linear_256_5528" x1="312.562" y1="71.5828" x2="316.366" y2="77.178" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint33_linear_256_5528" x1="313.616" y1="84.116" x2="317.421" y2="89.7112" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint34_linear_256_5528" x1="319.878" y1="107.093" x2="323.683" y2="112.689" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint35_linear_256_5528" x1="270.792" y1="89.3379" x2="274.597" y2="94.933" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint36_linear_256_5528" x1="241.546" y1="89.3379" x2="245.351" y2="94.933" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint37_linear_256_5528" x1="233.187" y1="124.849" x2="236.991" y2="130.444" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint38_linear_256_5528" x1="164.249" y1="218.847" x2="168.054" y2="224.442" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint39_linear_256_5528" x1="172.612" y1="251.225" x2="176.417" y2="256.82" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint40_linear_256_5528" x1="172.612" y1="276.291" x2="176.417" y2="281.886" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint41_linear_256_5528" x1="77.5655" y1="250.18" x2="81.3702" y2="255.776" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint42_linear_256_5528" x1="70.2608" y1="234.514" x2="74.0655" y2="240.109" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint43_linear_256_5528" x1="187.233" y1="267.935" x2="191.038" y2="273.531" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint44_linear_256_5528" x1="202.913" y1="249.135" x2="206.718" y2="254.731" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint45_linear_256_5528" x1="90.1085" y1="255.402" x2="93.9132" y2="260.997" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint46_linear_256_5528" x1="255.132" y1="84.116" x2="258.937" y2="89.7112" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint47_linear_256_5528" x1="259.304" y1="72.6271" x2="263.108" y2="78.2222" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint48_linear_256_5528" x1="311.523" y1="52.7828" x2="315.327" y2="58.3779" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint49_linear_256_5528" x1="311.523" y1="7.8726" x2="315.327" y2="13.4678" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint50_linear_256_5528" x1="325.108" y1="17.2722" x2="328.913" y2="22.8674" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint51_linear_256_5528" x1="283.319" y1="6.82773" x2="287.124" y2="12.4229" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint52_linear_256_5528" x1="271.843" y1="11.0059" x2="275.648" y2="16.601" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint53_linear_256_5528" x1="216.472" y1="3.69447" x2="220.276" y2="9.28962" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint54_linear_256_5528" x1="231.321" y1="-6.74953" x2="235.759" y2="-0.221849" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint55_linear_256_5528" x1="288.99" y1="36.0724" x2="294.063" y2="43.5326" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint56_linear_256_5528" x1="200.224" y1="20.4055" x2="205.297" y2="27.8657" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint57_linear_256_5528" x1="168.281" y1="40.2496" x2="174.622" y2="49.5748" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint58_linear_256_5528" x1="168.281" y1="60.0939" x2="174.622" y2="69.4191" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint59_linear_256_5528" x1="196.484" y1="60.0939" x2="202.825" y2="69.4191" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint60_linear_256_5528" x1="207.976" y1="95.6045" x2="214.318" y2="104.93" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint61_linear_256_5528" x1="178.73" y1="91.4267" x2="185.071" y2="100.752" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint62_linear_256_5528" x1="166.207" y1="91.4267" x2="172.548" y2="100.752" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint63_linear_256_5528" x1="165.144" y1="73.6717" x2="171.485" y2="82.997" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint64_linear_256_5528" x1="153.66" y1="31.8942" x2="160.001" y2="41.2194" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint65_linear_256_5528" x1="194.41" y1="90.3821" x2="200.751" y2="99.7073" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint66_linear_256_5528" x1="184.548" y1="31.8942" x2="189.621" y2="39.3544" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint67_linear_256_5528" x1="256.475" y1="50.6944" x2="264.084" y2="61.8847" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint68_linear_256_5528" x1="246.022" y1="113.36" x2="253.631" y2="124.55" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint69_linear_256_5528" x1="171.877" y1="109.182" x2="179.486" y2="120.373" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint70_linear_256_5528" x1="227.233" y1="91.4267" x2="234.842" y2="102.617" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint71_linear_256_5528" x1="192.764" y1="117.538" x2="200.373" y2="128.728" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint72_linear_256_5528" x1="213.643" y1="162.448" x2="221.252" y2="173.639" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint73_linear_256_5528" x1="202.154" y1="181.248" x2="209.764" y2="192.438" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint74_linear_256_5528" x1="213.643" y1="123.804" x2="221.252" y2="134.994" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint75_linear_256_5528" x1="171.877" y1="179.159" x2="179.486" y2="190.349" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint76_linear_256_5528" x1="205.287" y1="220.936" x2="212.897" y2="232.126" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint77_linear_256_5528" x1="172.241" y1="230.336" x2="177.948" y2="238.729" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint78_linear_256_5528" x1="126.288" y1="218.847" x2="131.995" y2="227.24" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint79_linear_256_5528" x1="111.667" y1="209.447" x2="117.374" y2="217.84" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint80_linear_256_5528" x1="97.2654" y1="213.625" x2="103.607" y2="222.95" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint81_linear_256_5528" x1="101.453" y1="244.958" x2="107.794" y2="254.283" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint82_linear_256_5528" x1="89.9607" y1="274.202" x2="96.3019" y2="283.527" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint83_linear_256_5528" x1="101.229" y1="260.624" x2="106.936" y2="269.017" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint84_linear_256_5528" x1="182.311" y1="217.803" x2="189.92" y2="228.993" gradientUnits="userSpaceOnUse">
        <stop stop-color="#73CECF"/>
        <stop offset="1" stop-color="#3FA9AA"/>
      </linearGradient>
      <linearGradient id="paint85_linear_256_5528" x1="158.924" y1="150.929" x2="-22.1374" y2="53.797" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F3F1F1"/>
        <stop offset="1" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint86_linear_256_5528" x1="-20.7228" y1="53.3248" x2="65.5643" y2="213.64" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
        <stop offset="1" stop-color="#FBFBFB" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint87_linear_256_5528" x1="64.6133" y1="103.306" x2="152.757" y2="159.026" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0.7"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AffiliateProgramLogo"
}
</script>

<style scoped>
svg {
  flex-shrink: 0;
}
</style>